import AssessmentApi from '../services/AssessmentApi'
import { SET_IS_LOADING } from './mutations';
import { StatusType } from '@/store/enums';

function groupBy(xs) {
    return xs.reduce((r, { subjectName: name, ...object }) => {
        var temp = r.find(o => o.name === name);
        if (!temp) r.push(temp = { name, children: [] });
        temp.children.push(object);
        return r;
    }, []);
};

export default {
    state: {
        storedAssessmentData:[],
        storedAssessmentAllDay:{},
        storedAssessmentNow:[],
        storedAssessmentAttachment:[],
        storedAssessmentMedia:[],
        storedAssessmentMark:[],
    },

    mutations: {
        setAssessment(state, payload){
            state.storedAssessmentData= []
            state.storedAssessmentAllDay= []
            state.storedAssessmentNow= [],
            state.storedAssessmentData = payload
        },

        setAssessmentAllDay(state, payload){
            state.storedAssessmentAllDay= {}
            state.storedAssessmentAllDay = payload
        },

        setAssessmentNow(state, payload){
            state.storedAssessmentNow= [],
            state.storedAssessmentNow = payload
        },

        setAssessmentAttachment(state, payload){
            state.storedAssessmentAttachment= [],
            state.storedAssessmentAttachment = payload
        },

        setAssessmentMedia(state, payload){
            state.storedAssessmentMedia.push(payload)
        },

        setAssessmentMark(state, payload){
            state.storedAssessmentMark= [],
            state.storedAssessmentMark = payload
        },

    },

    actions: {
        showMarkData ({ commit, getters }, param){
            let studentId = getters.getSelectedStudentId;
            AssessmentApi.getStudentMarksByLecturerClassAssessmentForParent(param.workplaceId, param.teacherId, param.schoolClassId, param.assessmentId, studentId)
            .then(response => response.data)
            .then(storedAssessmentMark => {
                commit('setAssessmentMark', storedAssessmentMark)
            })
            .catch(err => {
                console.log('setAssessmentMark '+err);
            })
        },

        clearstoredAssessmentMedia({ state }) {
            state.storedAssessmentMedia = []
        },
        
        getAssessment({ commit, getters }) {
            let studentId = getters.getSelectedStudentId;
            let { schoolId } = getters.getStudentSelected;
            if (!studentId || !schoolId) return
            AssessmentApi.getDataAssessmentByParent(studentId, schoolId)
            .then(response => response.data)
            .then(storedAssessmentData => {
                commit('setAssessment', storedAssessmentData)
            })
            .catch(err => {
                console.log('setAssessment '+err);
            })
        },

        selectAssessment({commit, getters, dispatch}) {
            let studentId = getters.getSelectedStudentId;
            let { schoolId } = getters.getStudentSelected;

            AssessmentApi.getDataAssessmentByParentAllDaysAfterNow(studentId, schoolId)
            .then(response => response.data)
            .then(storedAssessmentAllDay => {
                commit('setAssessmentAllDay', groupBy(storedAssessmentAllDay))
            })
            .catch(err => {
                console.log(err);
            })

            dispatch('getDetailNow', { studentId, schoolId })
        },

        getDetailNow({commit}, { studentId, schoolId }) {
            AssessmentApi.getDataAssessmentByParentDetailNow(studentId, schoolId)
            .then(response => response.data)
            .then(storedAssessmentNow => {
                commit('setAssessmentNow', storedAssessmentNow)
            })
            .catch(err => {
                console.log(err);
            })
        },

        getAssessmentUpload({commit, state, getters, dispatch}, { assessmentId }) {
            let studentId = getters.getSelectedStudentId;
            let { schoolId } = getters.getStudentSelected;

            AssessmentApi.getDataAssessmentUploadByAssessmentId(studentId, schoolId, assessmentId)
            .then(response => {
                dispatch('clearstoredAssessmentMedia')
                let itemsData = []
                itemsData = response.data

                for ( var i = 0; i < itemsData.length; ++i ){
                    let fileName = itemsData[i].fileName
                    let fileId = itemsData[i].fileId
                    let assessmentId = itemsData[i].assessmentId
                    dispatch('getAssessmentMedia', {fileId, fileName, assessmentId})
                }
            })
            .then(storedAssessmentAttachment => {
                commit('setAssessmentAttachment', storedAssessmentAttachment)
            })
            .catch(err => {
                console.log(err);
            })
        },

        getAssessmentMedia({commit},  param ) {
            AssessmentApi.downloadMedia(param.fileId)
            .then(response => {
                let mediaObject= {}
                let reader = new FileReader();

                if (response.data.type === "application/pdf"){
                    let blob = new Blob([response.data], { type: response.data.type });

                    var fileURL = window.URL.createObjectURL(blob);
                    var fileLink = document.createElement('a');

                    fileLink.href = fileURL;
                    fileLink.setAttribute('download', param.fileName);
                    document.body.appendChild(fileLink);

                    fileLink.click();
                } else {
                    reader.readAsDataURL(response.data); 
                    reader.onload = () => {
                        mediaObject = {
                            srcImg: reader.result,
                            fileName: param.fileName,
                            fileId: param.fileId,
                            assessmentId: param.assessmentId 
                        }

                        commit('setAssessmentMedia', mediaObject)
                    }

                }
                
            })
            .catch(err => {
                console.log(err);
            })
        },

        deleteAttachment({commit, getters},  param ) {
            commit(SET_IS_LOADING, true)
            let studentId = getters.getSelectedStudentId;
            AssessmentApi.deleteAssessmentStudent(studentId, param.assessmentId, param.fileId)
            .then(response => {
                jng.snackSuccess("delete Success")
                router.go(-1);
            })
            .catch(err => {
                jng.snackError("delete failed")
            })
            .finally(()=> {
                commit(SET_IS_LOADING, false)
            })
        },

        uploadAttachment({commit},  param ) {
            return AssessmentApi.uploadMedia(param)
            .then(response => {
                return response.data;
            })
            .catch(err => {
                console.log(err);
                return StatusType.FAILURE;
            })
        }
 
    },

    getters: {
        getAssessmentData: state => state.storedAssessmentData,
        getAssessmentNow: state => state.storedAssessmentNow,
        getAssessmentAllDay: state => state.storedAssessmentAllDay,

        getAssessmentAttachment: state => state.storedAssessmentAttachment,
        getAssessmentMedia: state => state.storedAssessmentMedia,

        getAssessmentMark: state => state.storedAssessmentMark,

    }
}