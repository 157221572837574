<script>
  import { Pie } from 'vue-chartjs'
  import { mapGetters } from 'vuex';

  export default {
    extends: Pie,
    
    computed:{
      ...mapGetters({ items: "getSelectAttendance" }),
    },

    watch:{
      items: {
        handler: function (newValue, oldValue) {

          this.chartData.datasets[0].data = []

          let obj = newValue
          
          if (newValue){
            this.chartData.datasets[0].data.push(obj.alpha)
            this.chartData.datasets[0].data.push(obj.sick)
            this.chartData.datasets[0].data.push(obj.permission)
            this.chartData.datasets[0].data.push(obj.present)
          } else {
            this.chartData.datasets[0].data.push(0)
            this.chartData.datasets[0].data.push(0)
            this.chartData.datasets[0].data.push(0)
            this.chartData.datasets[0].data.push(0)
          }

          this.renderChart(this.chartData, this.options)

        },deep: true
        }
    },

    data () {
      return {
        chartData: {
          setting: '123',
          labels: ["Alpha", "Sick", "Permission", "Present",],
          datasets: [{
              borderWidth: 1,
              borderColor: [
              'rgba(237,28,36,1)',
              'rgba(141, 198, 63, 1)',
              'rgba(0, 0, 0, 0)',
              'rgba(28, 117, 118, 1)'            
              ],
              backgroundColor: [
              'rgba(237,28,36,1)',
              'rgba(141, 198, 63, 1)',
              'rgba(0, 0, 0, 0. 0)',
              'rgba(28, 117, 118, 1)'               
              ],
              data: [0,	0,	0,	0]
            }]
        },
        options: {
          legend: {
            display: true
          },
          responsive: true,
          maintainAspectRatio: false
        }
      }
    },
    mounted () {
      this.renderChart(this.chartData, this.options)
    },
  }
</script>