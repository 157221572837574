<template>
<v-card class="pa-6">
    <app-bar :label="label.label" />
    <v-sheet id="scrolling-techniques-7" class="overflow-y-auto" height="100%" >
        <v-container>
            <v-list dense>
                <v-list-group  v-for="item in itemsAllDays" :key="item.name"  no-action>
                    <template v-slot:activator>
                        <v-list-item-content>
                            <v-list-item-title v-text="_dayName(item.name)"></v-list-item-title>
                        </v-list-item-content>
                    </template>

                    <v-list-item v-for="child in item.children" :key="child.id">
                        <v-list-item-content>
                            <v-text-field style="width: 100%;"
                                :value="child.items[0].className+' - '+child.items[0].absentStatus"
                                :label="'Shift: '+child.items[0].shiftNo"
                                readonly></v-text-field>
                        </v-list-item-content>
                    </v-list-item>
                    
                </v-list-group>

            </v-list>
        </v-container>
    </v-sheet>
</v-card>
</template>

<script>
import { mapGetters } from 'vuex';
import AppBar from '@/components/base/AppBar.vue'

export default {
    components: { AppBar },

    data: () => ({
        label: {
            label: "Attendance Detail"
        },
    }),

    computed:{
      ...mapGetters({ objItm: "getSelectAttendance", itemsAllDays: "getMonthlyAttendance" }),
    },

    created(){
        
        let data = this.objItm
        
        let dateShow = new Date(data.year+'-'+('0'+data.month).slice(-2)+'-'+'01'+" 00:00:00").getTime()

        this.$store.dispatch('setMonthlyAttendance', dateShow)
    },

    methods:{

        _dayName(dtm){
            var days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
            var d = new Date(dtm);
            return days[d.getDay()]+' :  '+Date.toDateString(new Date(dtm), "dd-mm-yyyy")
        },

    },

}
</script>