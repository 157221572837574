<template>
  <div class="address-tab">
    <v-row>
      <v-col v-for="address in addressList" :key="address.field" :cols="address.col">
        <v-row class="mt-2 align-center">
          <!-- <v-col :cols="address.col1" sm="1">
              <v-icon>{{address.icon}}</v-icon>
          </v-col> -->
          <v-col cols="12" sm="5">
            <div class="label-small">
              {{label[address.field]}}
            </div>
            <div v-if="address.field == 'rtRw'">
              {{ items.rt+' / '+items.rw }}
            </div>
            <div v-else>
              {{items[address.field] ? items[address.field] : " - " }}
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <!-- <v-row>
        <v-col cols="12" sm="6">
          <v-text-field readonly :label="label.address"
            :value="items.address1"/>
        </v-col>
    </v-row>
    <v-row>
        <v-col cols="12" sm="6">
          <v-text-field readonly label="RT / RW"
            :value="items.rt+' / '+items.rw"/>
        </v-col>
    </v-row>
    <v-row>
        <v-col cols="12" sm="6">
          <v-text-field readonly :label="label.province"
            :value="items.province"/>
        </v-col>
    </v-row>
    <v-row>
        <v-col cols="12" sm="6">
          <v-text-field readonly :label="label.city"
            :value="items.city"/>
        </v-col>
    </v-row>
    <v-row>
        <v-col cols="12" sm="6">
          <v-text-field readonly :label="label.district"
            :value="items.district"/>
        </v-col> 
    </v-row>
    <v-row>
        <v-col cols="12" sm="6">
          <v-text-field readonly :label="label.zipCode"
            :value="items.zipCode"/>
        </v-col>
    </v-row> -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    name: 'Address',

    data:() => ({
      label: {
        address1: "Address",
        rtRw: "RT / RW",
        rt: "RT",
        rw: "RW",
        province: "Province",
        city: "City",
        district: "District",
        zipCode: "ZipCode",
      },
      addressList: [
        { icon: "mdi-account-outline", field: "address1", col: '12' },
        { icon: "mdi-alpha-n-box-outline", field: "rtRw", col: '6' },
        { icon: "mdi-airballoon", field: "zipCode", col: '6' },
        { icon: "mdi-cake-layered", field: "province", col: '12' },
        { icon: "mdi-gender-male-female", field: "city", col: '12' },
        { icon: "mdi-airballoon", field: "district", col: '6' },
      ]
    }),

    methods: {
      _initLabel(){
        this.label.address1 = naf.lang.get("", "STUDENT_DATA.ADDRESS")
        this.label.province = naf.lang.get("", "STUDENT_DATA.PROVINCE")
        this.label.city = naf.lang.get("", "STUDENT_DATA.CITY")
        this.label.district = naf.lang.get("", "STUDENT_DATA.DISTRICT")
        this.label.zipCode = naf.lang.get("", "STUDENT_DATA.ZIP_CODE")
      }
    },

    mounted(){
      naf.lang.onChangeLanguage(() => {
        this._initLabel()
      })
      this._initLabel()
    },

    computed:{
    ...mapGetters({ items: "itemsAddress"}),
    },

}
</script>

<style lang="scss" scoped>
  .label-small{
    font-size: 12px;
    color: rgba(0,0,0,0.6);
  }
</style>