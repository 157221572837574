<template>
<div class="mark pa-6">
    <v-dialog
        v-model="dialog"
        persistent
        max-width="600px">
            <v-card>
                <v-card-title>
                    <span class="text-h5">School Code</span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col cols="12">
                                <v-text-field
                                        v-model="codeSchl"
                                        :counter="30"
                                        label="School Code"
                                        color="teal accent-3"/>        
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                        <v-btn
                            color="blue darken-1"
                            text
                            @click="dialog = false"
                        >
                            Close
                        </v-btn>
                        <v-btn
                            color="blue darken-1"
                            text
                            @click="loginSetup"
                        >
                            Save
                        </v-btn>

                </v-card-actions>
            </v-card>
        </v-dialog>

</div>
</template>

<script>

import { mapState, mapGetters } from "vuex";
import { StatusType } from '@/store/enums';

export default {
    data:() => ({
        dialog: false,
        codeSchl:'',
    }),

    computed: {
        ...mapGetters({ urlEndPoint: "getUrlScholl"}),
        ...mapState(['isLoading']),
    }, 

    watch:{
        urlEndPoint(newValue, oldValue){
            if (newValue !== '' && newValue != null){
                localStorage.setItem('urlSchool', newValue)
            } else {
                if (process.env.NODE_ENV === 'production') {
                    localStorage.setItem('urlSchool', process.env.VUE_APP_SERVICE_URL + process.env.VUE_APP_CODE_SETUP) 
                }
            }
        }
    },

    methods: {
        openSchDialog() {
            this.dialog = true;
            if (process.env.NODE_ENV === 'production') {
                localStorage.setItem('urlSchool', process.env.VUE_APP_SERVICE_URL + process.env.VUE_APP_CODE_SETUP) 
            } 
        },

        loginSetup(){
            if (this.isLoading) return
            this.$store.dispatch('setSchoolURL', this.codeSchl.toLowerCase().trim()).then(res => {
                    if (res === StatusType.SUCCESS){
                        jng.snackSuccess("School Code Success");
                        this.dialog = false;
                    } else {
                        jng.snackError("Error School Code");
                        this.$store.dispatch('setupSch');
                    }
                });
        },
    },
}

</script>