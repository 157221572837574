<template>
  <div>
    <v-navigation-drawer 
    v-model="drawer"
      app>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="title">
            Edconnect
          </v-list-item-title>
          <v-list-item-subtitle>
            {{ userdata.name }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <student-selector />

      <v-divider></v-divider>

      <v-list
        dense
        nav
      >
        <v-list-item
          v-for="item in items"
          :key="item.title"
          @click="menuActionClick(item.action)"
          link
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

      </v-list>
    </v-navigation-drawer>

    <v-app-bar
      app
      color="primary"
      dense
      dark
    >
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>

      <v-toolbar-title>{{ $route.name }}</v-toolbar-title>

      <v-spacer></v-spacer>

      <!-- <language-selector /> -->
      <v-btn icon large :to="{ path: '/announcement'}">
        <v-icon size="1.3rem">mdi-bell</v-icon>
      </v-btn>

      <v-menu
        left
        bottom
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>

        <v-list width="10.5rem">
          <v-list-item class="justify-center">
            <language-selector />
          </v-list-item>
          <v-divider />
          <v-list-item
            v-for="option in options"
            :key="option.title"
            @click="menuActionClick(option.action)"
          >
            <v-list-item-title class="text-center">{{ option.title }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
  </div>
</template>

<script>

import EventBus from '../event-bus';
import { mapGetters } from 'vuex';
import LanguageSelector from './LanguageSelector.vue';
import StudentSelector from '../common/StudentSelector.vue';
import { StatusType } from '@/store/enums';
import { AppsRightType } from '@/store/enums';

  export default {
    name: "TopToolbar",
    components: { LanguageSelector, StudentSelector },
    data: () => ({
        drawer: null,
        dialog: false,
        attendance: false,
        assessment: false,
        items: [
          { title: 'Attendance', icon: 'mdi-clock-outline', action: "attendance"},
          { title: 'Assessment', icon: 'mdi-information-outline ', action: "assessment"},
        ],
        options: [
          { title: 'Change Password', action: "changePassword"},
          { title: 'Logout', action: "logout"},
        ],
        // defaultSelected: [],
    }),

    computed:{
    ...mapGetters({ userdata: "getUserData", itemsStudent: "itemListStudent"}),
    },

    methods:{
      _updateView() {
        this.assessment = this.$root.$refs.main_component.appsRightFunction(AppsRightType.ASSESSMENT);
        this.absent = this.$root.$refs.main_component.appsRightFunction( AppsRightType.ABSENT);
      },
      
      menuActionClick(action) {
        if (action === "attendance" && this.absent) {
          this.$router.push({ name: 'Attendance' }).catch(()=>{});
        } else if (action === "assessment" && this.assessment) {
          this.$router.push({ name: 'AssessmentDetail' }).catch(()=>{});
        } else if (action === "logout") {
          this.confirmLogout();
        } else if (action === "changePassword") {
          console.log(`changePassword`, jng.user)
          this.$router.push({name: "ChangePassword"})
        }
      },

      async confirmLogout() {
        if (
            await this.$root.$confirm(
              'Edconnect Logout',
              'Are you sure you want to Logout?',
              { color: 'red' })) {
                this.$root.$refs.main_component.logoutFunction();
              }
      },

    },

    created() {
      if(this.$store.getters.getStudentData.length === 0){
        this.$store.dispatch('getAppsRightList').then(res => {
          if (res === StatusType.SUCCESS){
              this.$store.dispatch('loadStudentByParent').then(res => {
                if (res === StatusType.SUCCESS){
                  this.$root.$refs.top_navi_component = this;
                  naf.TopToolbar = this

                  this._updateView();
                }
              });
          } else {
            this.$root.$refs.main_component.logoutFunction();
          }
        });
      }
    }
  }
</script>