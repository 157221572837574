import { Api } from "./RestResource"

export default class MessageApi {

    static getInboxImprovementList(messageType, schoolId, countSwipe) {
        return Api.get('message/inbox/improvement/PARENT/'+messageType+'/'+schoolId+'/'+countSwipe);
    }

    static getInboxImprovementListOnlyOneStudent(messageType, countSwipe, studentId,) {
        return Api.get('message/inbox/improvement/onlyonestudent/PARENT/'+messageType+'/'+countSwipe+'/'+studentId);
    }

    static deleteByRecipientAndWorkplace(messageId, schoolId) {
        return Api.get('message/deleteInboxWithSchoolIdAndWithoutUserId/'+messageId+'/'+schoolId);
    }

    static getOutboxList(messageId, schoolId) {
        return Api.get('message/outbox/PARENT/'+messageId+'/'+schoolId);
    }

    static getOutboxListOnlyOneStudent(messageId, studentId) {
        return Api.get('message/outbox/onlyoneStudent/PARENT/'+messageId+'/'+studentId);
    }

    static getDraftListAll(messageType) {
        return Api.get('message/draft/allstudent/PARENT/'+messageType);
    }

    static getDraftList (messageType, schoolId, studentId){
        return Api.get('message/draft/PARENT/'+messageType+'/'+schoolId+'/'+studentId);
    }

    static saveMessage(params) {
        return Api.post('message', params);
    }

    static publishMessage(params) {
        return Api.post('message/publishOutbox/', params);
    }
}