import { Api } from "./RestResource"

export default class AssessmentApi {
    static getDataAssessmentByParent(studentId, schoolId) {
        return Api.get('assessment/getDataAssessmentByParent/mobile/oneStudent/'+schoolId+'/'+studentId);
    }

    static getDataAssessmentByParentAllDaysAfterNow(studentId, schoolId) {
        return Api.get('assessment/getDataAssessmentByParent/mobile/oneStudent/allDaysAfterNow/'+schoolId+'/'+studentId);
    }

    static getDataAssessmentByParentDetailNow(studentId, schoolId) {
        return Api.get('assessment/getDataAssessmentByParentDetailNow/mobile/oneStudent/'+schoolId+'/'+studentId);
    }

    static getDataAssessmentUploadByAssessmentId(studentId, schoolId, assessmentId){
        return Api.get('studentMark/getStudentAssessmentUploadForApps/'+schoolId+'/'+studentId+'/'+btoa(assessmentId));
    }
    
    static uploadMedia(params){
        return Api.post('media/file/upload', params, {
            headers: {
                'Content-Type': 'multipart/form-data'
                }
            })
    }
    
    static downloadMedia(id){
        return Api.get('media/file/download/'+btoa(id), { responseType: 'blob' })
    }

    static deleteAssessmentStudent(studentId, assessmentId, fileId){
        return Api.post('studentMark/deleteAssessmentStudent/'+btoa(studentId)+'/'+btoa(assessmentId)+'/'+fileId)
    }
    
    static getStudentMarksByLecturerClassAssessmentForParent(schoolId, lecturerId, classId, assessmentId, studentId) {
        return Api.get('studentMark/by-assessment-parent/'+schoolId+'/'+lecturerId+'/'+classId+'/'+assessmentId+'/'+studentId);
    }
}