<template>
  <v-card class="overflow-hidden" style="height: 100%;">
    <v-app-bar app color="primary" dense dark elevate-on-scroll scroll-target="#scrolling-techniques-7">
        <v-btn icon>
            <v-icon @click.native="goBack">mdi-arrow-left</v-icon>
        </v-btn>

      <v-toolbar-title>{{label.historyStatusPayment}}</v-toolbar-title>

      <v-spacer></v-spacer>

    </v-app-bar>
    <v-sheet id="scrolling-techniques-7" class="overflow-y-auto" height="100%" >
      <v-container>
        <div v-for="(payment, i) in allPendingPayment" :key="i">
            <div class="pa-1 d-flex justify-space-between align-center" @click="toDetail(payment)" >
                <div>
                    <label-text :label="_formatDateId(payment)" :text="_formatCurrency(payment)"/>
                    <div class="mantap">Status: {{status}}</div>
                </div>
                <div style="font-weight: bold;" class="d-flex align-baseline">
                  <v-btn @click="_checkStatus(payment)" icon color="blue"><v-icon>mdi-cached</v-icon></v-btn>
                </div>
            </div>
            <v-divider />
        </div>
        <!-- {{allPendingPayment}} -->
      </v-container>
    </v-sheet>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import PaymentApi from "@/services/PaymentApi.js"
import LabelText from '@/components/base/LabelText.vue'
import { SET_IS_LOADING } from '@/config/const.vue'

export default {
    name: 'PendingPayment',

    components: { LabelText },

    props: {
    },

    data: () => ({
      label: {
        title: "Payment Detail",
        historyStatusPayment: "History Status Payment",
      },
      status: "PENDING"
    }),

    methods:{
        goBack(){
          this.$router.go(-1)
        },
        toDetail({ id }){
          this.$router.push(`/payment/${btoa(id)}`)
        },
        _formatCurrency({ paymentSum }){
          return `Rp ${jng.setToCurrency(paymentSum)}`
        },
        _formatDateId({ paymentDate, id }){
          return `${paymentDate.toDateString()} - ${id}`
        },
        _initLabel(){
          this.label.title = naf.lang.get("", "PAYMENT")
        },
        async _checkStatus({ id }){
          event.stopPropagation()
          this.$store.commit(SET_IS_LOADING, true)
          try {
            let { data } = await PaymentApi.checkPaymentStatus(id)
            this.status = data
          } catch (err) {
            console.log(err.response)
          } finally {
            this.$store.commit(SET_IS_LOADING, false)
          }
        }
    },

    computed:{
      ...mapGetters({ allPendingPayment: 'allPendingPayment' }),
    },

    destroyed(){
      this.$store.commit('setAllPendingPayment', [])
    },

    mounted(){
      this.$store.dispatch('getAllPendingPayment')
      naf.lang.onChangeLanguage(() => {
        this._initLabel()
      })
      this._initLabel()
    },
}

</script>

<style lang="scss" scoped>
  $edconnect: #1776CA;
  .history-box{
    background-color: rgb(226, 226, 147);
    border-radius: 1rem;
    padding: 1rem;
    margin-top: 0.7rem;
    margin-bottom: 0.1rem;
  }
  .header-payment{
    // max-width: 12rem;
    // white-space: nowrap;
    // overflow: hidden;
    // text-overflow: ellipsis;
    padding-left: 1rem;
    background-color: ghostwhite;
    border-radius: 1rem;
  }
</style>