import axios from 'axios'

axios.defaults.withCredentials = true
const request = axios.create({
    baseURL: process.env.VUE_APP_SERVICE_URL,
    headers: {
        'Content-Type': 'application/json'
    },
})

request.interceptors.request.use(
    request => {
        if (process.env.NODE_ENV === 'production') {
            request.baseURL = localStorage.getItem('urlSchool')
        } else {
            request.baseURL = request.baseURL + process.env.VUE_APP_CODE_SETUP
        }

        if (localStorage.hasOwnProperty('token') ){
            request.headers['Authorization']  = "Bearer "+localStorage.getItem('token');
        }

        return request
    },
    error => {
        return Promise.reject(error)
    }
)

export default request