import { Api } from "./RestResource"

export class AbsentApi {
    static getDailyAttendance(schoolId, studentId) {
        return Api.get(`absent/parent/daily/${schoolId}/${studentId}`);
    }

    static summarizedCurrentStudentAbsentPerMonth(schoolId, studentId) {
        return Api.get(`absent/parent/monthly/${schoolId}/${studentId}`);
    }

    static summarizedCurrentHomeroomStudentAbsentPerMonth(schoolId, studentId) {
        return Api.get(`absent/parent/monthly/homeroom/${schoolId}/${studentId}`);
    }

    static getMonthlyAttendance(schoolId, studentId, datenow, absentUsingShift) {
        return Api.get(`absent/parent/monthlydata/${schoolId}/${studentId}/${datenow}/${absentUsingShift}`);
    }
}

export default AbsentApi;