import { Api } from "./RestResource";

class TableDataApi {
    static selectByTenant(configUri){
        return Api.get('table/' + configUri);
    }
}

export default class ConfigApi {
    static get ObjectUri() {
        return {
            PAYMENT_SCHEME: "edc.bo.pmt.PaymentScheme",
            STUDENT: "edc.bo.sch.Student",
            TEACHER:"edc.bo.sch.Lecturer",
            EMPLOYEE:"edc.hr.Employee",
            SCHOOL:"edc.bo.gen.School",
            SALES: "edc.bo.pmt.InvoiceSales",
            REGISTRATION: "edc.bo.adm.Registration",
            ASSESSMENT: "edc.bo.cur.Assessment",
            MANUAL_INVOICE: "edc.bo.pmt.ManualInvoice",
            SUBJECT_GROUP: 'edc.bo.sbj.SubjectGroup'
        }
    }
    
    static get ConfigUri() {
        return {
            ACHIEVEMENT_POINT_REDEEM_TYPE:"edc.bo.gen.AchievementPointRedeemType",
            ACHIEVEMENT_POINT_ADDITION_TYPE:"edc.bo.gen.AchievementPointAdditionType",
            TEXT_CHARACTER_HIGHEST_ACHIEVEMENT:"edc.bo.gen.TextCharacterHighestAchievement",
            GRADE_TYPE: "edc.bo.gen.GradeType",
            GROUP_GRADE_TYPE: "edc.bo.gen.GroupGradeType",
            GRADE_PERIOD_NAME: "edc.bo.gen.GradePeriodName",
            SUBJECT_ACTIVITY: "edc.bo.gen.SubjectActivity",
            SUBJECT_CATEGORY_1: "edc.bo.sbj.SubjectCategory1",
            SUBJECT_CATEGORY_2: "edc.bo.sbj.SubjectCategory2",
            SUBJECT_CATEGORY_3: "edc.bo.sbj.SubjectCategory3",
            SUBJECT_CATEGORY_4: "edc.bo.sbj.SubjectCategory4",
            SUBJECT_CATEGORY_5: "edc.bo.sbj.SubjectCategory5",
            GENDER: "jef.bo.gen.Gender",
            MARITAL_STATUS: "jef.bo.gen.MaritalStatus",
            EDUCATION_LEVEL: "jef.bo.gen.EducationLevel",
            LAST_EDUCATION_LEVEL: "edc.bo.gen.LastEducationLevel",
            LIFE_STATUS: "jef.bo.gen.LifeStatus",
            RELIGION: "jef.bo.gen.Religion",
            NATIONALITY: "jef.bo.gen.Nationality",
            WORKING_POSITION: "jef.bo.gen.WorkingPosition",
            WORKING_STATUS: "jef.bo.gen.WorkingStatus",
            DEPARTMENT: "edc.bo.gen.Department",
            MARKETING_CHANNEL: "edc.bo.gen.MarketingChannel",
            RECORD_STUDENT_TYPE: "edc.bo.sce.RecordStudentType",
            INTEREST: "ids.bo.gen.Interest",
            GUEST_BOOK_STATUS_MASTER: "ids.bo.gen.GuestBookStatus",
            PROGRAM: "ids.bo.gen.Program",
            RANGE_INCOME: "edc.bo.gen.IncomeRange",
            OLD_SCHOOL: "edc.bo.gen.OldSchool",
            SCHEDULE_EXCEPTION_REASON_TYPE: "edc.bo.gen.ScheduleExceptionReasonType",
            INVOICE_TYPE: "edc.bo.gen.InvoiceType",
            PAYMENT_TYPE: "edc.bo.gen.PaymentType",
            SCHEDULE_BOOKING_TYPE: "edc.bo.gen.ScheduleBookingType",
            TEST_ENTRY: "edc.bo.gen.TestEntry",
            SCHOOL_CATEGORY: "edc.bo.gen.SchoolCategory",
            SPECIAL_NEED: "edc.bo.gen.SpecialNeed",
            RANGE_FROM_SCHOOL_CATEGORY: "edc.bo.gen.RangeFromSchoolCategory",
            STAY_WITH: "edc.bo.gen.StayWith",
            DORM_SELECTION: "edc.bo.gen.DormitorySelection",
            SCHOLARSHIP_TYPE: "edc.bo.gen.ScholarshipType",
            SELECTION_PROGRAM: "edc.bo.gen.SelectionProgram",
            STUDENT_STATUS: "edc.bo.gen.StudentStatus",
            TRANSPORTATION_TYPE: "edc.bo.gen.TransportationType",
            ACHIEVEMENT_TYPE: "edc.bo.gen.AchievementType",
            ACHIEVEMENT_REGION: "edc.bo.gen.AchievementRegion",
            PTK_TYPE: "edc.bo.sch.PTKType",
            TEACHER_EMPLOYMENT_STATUS: "edc.bo.sch.LecturerEmploymentStatus",
            STUDENT_NUMBER_TYPE: "edc.bo.gen.StudentNumberType",
            COMPETENCY_CATEGORY: "edc.bo.cur.CompetencyCategory",
            PRODUCT_CATEGORY_1: "edc.inv.ProductCategory1",
            PRODUCT_CATEGORY_2: "edc.inv.ProductCategory2",
            PRODUCT_CATEGORY_3: "edc.inv.ProductCategory3",
            PRODUCT_CATEGORY_4: "edc.inv.ProductCategory4",
            PRODUCT_CATEGORY_5: "edc.inv.ProductCategory5",
            LEARNING_ACTIVITY: "edc.bo.cur.LearningActivity",
            COMPETENCY_LEVEL: "edc.bo.cur.CompetenceLevel",
        
            SCHOOL_HEALTH_UNIT: "edc.bo.gen.SchoolHealthUnit",
            SICK_CATEGORY: "edc.bo.gen.SickCategory",
            SCHOOL_HEALTH_UNIT_CATEGORY1: "edc.bo.gen.SchoolHealthUnitCategory1",
            SCHOOL_HEALTH_UNIT_CATEGORY2: "edc.bo.gen.SchoolHealthUnitCategory2",
            SCHOOL_HEALTH_UNIT_CATEGORY3: "edc.bo.gen.SchoolHealthUnitCategory3",
            
            LEARNING_ACTIVITY: "edc.bo.cur.LearningActivity",
            ACTIVITY_TEMPLATE: "edc.bo.cur.ActivityTemplate",
            STUDENT_AGE_LIMIT: "edc.bo.sch.StudentAgeLimit",
            KD: "edc.bo.cur.Kd",

            COUNSELING_CORRECTION_CATEGORY_1 : "edc.counseling.CorrectionCategory1",
            COUNSELING_CORRECTION_CATEGORY_2 : "edc.counseling.CorrectionCategory2",
            COUNSELING_CATEGORY_1 : "edc.counseling.CounselingCategory1",
            COUNSELING_CATEGORY_2 : "edc.counseling.CounselingCategory2",
            COUNSELING_CATEGORY_3 : "edc.counseling.CounselingCategory3",
            TEACHER_SUBJECT_SCHEDULE: "edc.bo.sce.TeacherSubjectSchedule",

            PAGES: "edc.bo.le.LearningUnit",
            ASSESSMENT: "edc.bo.cur.Assessment",
            QUIZ:"edc.bo.cur.formQuestionnaire",

            STOCK_TYPE: "edc.inv.StockType",
            IMMUNIZATION_TYPE: "edc.bo.sch.ImmunizationType",

            MARK_GROUP: "edc.bo.cur.MarkGroup",
        }
    }

    static selectNationality() {
        return TableDataApi.selectByTenant(this.ConfigUri.NATIONALITY);
    };
    static selectRangeIncome() {
        return TableDataApi.selectByTenant(this.ConfigUri.RANGE_INCOME);
    };
    
    static selectTransportationType() {
        return TableDataApi.selectByTenant(this.ConfigUri.TRANSPORTATION_TYPE);
    };
    
    static selectAchievementType() {
        return TableDataApi.selectByTenant(this.ConfigUri.ACHIEVEMENT_TYPE);
    };
    
    static selectAchievementRegion() {
        return TableDataApi.selectByTenant(this.ConfigUri.ACHIEVEMENT_REGION);
    };

    static selectCorrectionCategory1() {
        return TableDataApi.selectByTenant(this.ConfigUri.COUNSELING_CORRECTION_CATEGORY_1);
    };

    static selectCorrectionCategory2() {
        return TableDataApi.selectByTenant(this.ConfigUri.COUNSELING_CORRECTION_CATEGORY_2);
    };

    static selectCounselingCategory1() {
        return TableDataApi.selectByTenant(this.ConfigUri.COUNSELING_CATEGORY_1);
    };

    static selectCounselingCategory2() {
        return TableDataApi.selectByTenant(this.ConfigUri.COUNSELING_CATEGORY_2);
    };

    static selectCounselingCategory3() {
        return TableDataApi.selectByTenant(this.ConfigUri.COUNSELING_CATEGORY_3);
    };
    
    //    selectHolidayType
    static selectSchoolHealthUnit() {
        return TableDataApi.selectByTenant(this.ConfigUri.SCHOOL_HEALTH_UNIT);
    };
    static selectSchoolHealthUnitCategory1() {
        return TableDataApi.selectByTenant(this.ConfigUri.SCHOOL_HEALTH_UNIT_CATEGORY1);
    };
    static selectSchoolHealthUnitCategory2() {
        return TableDataApi.selectByTenant(this.ConfigUri.SCHOOL_HEALTH_UNIT_CATEGORY2);
    };
    static selectSchoolHealthUnitCategory3() {
        return TableDataApi.selectByTenant(this.ConfigUri.SCHOOL_HEALTH_UNIT_CATEGORY3);
    };
    static selectSickCategory() {
        return TableDataApi.selectByTenant(this.ConfigUri.SICK_CATEGORY);
    };
    
    static selectStudentAgeLimit() {
        return TableDataApi.selectByTenant(this.ConfigUri.STUDENT_AGE_LIMIT);
    };
    
    static selectScheduleExceptionType() {
        return TableDataApi.selectByTenant(this.ConfigUri.SCHEDULE_EXCEPTION_REASON_TYPE);
    };
    
    static selectGradePeriodName() {
        return TableDataApi.selectByTenant(this.ConfigUri.GRADE_PERIOD_NAME);
    };
    
    static selectGroupGradeType() {
        return TableDataApi.selectByTenant(this.ConfigUri.GROUP_GRADE_TYPE);
    };
    
    static selectOldSchool() {
        return TableDataApi.selectByTenant(this.ConfigUri.OLD_SCHOOL);
    };
    
    static selectSpecialNeed() {
        return TableDataApi.selectByTenant(this.ConfigUri.SPECIAL_NEED);
    };
    
    static selectRangeFromSchoolCategory() {
        return TableDataApi.selectByTenant(this.ConfigUri.RANGE_FROM_SCHOOL_CATEGORY);
    };
    
    static selectStayWith() {
        return TableDataApi.selectByTenant(this.ConfigUri.STAY_WITH);
    };
    
    static selectDormitorySelection() {
        return TableDataApi.selectByTenant(this.ConfigUri.DORM_SELECTION);
    };
    
    static selectScholarshipType() {
        return TableDataApi.selectByTenant(this.ConfigUri.SCHOLARSHIP_TYPE);
    };
    
    static selectSelectionProgram() {
        return TableDataApi.selectByTenant(this.ConfigUri.SELECTION_PROGRAM);
    };
    
    static selectStudentStatus() {
        return TableDataApi.selectByTenant(this.ConfigUri.STUDENT_STATUS);
    };
    
    static selectGradeType() {
        return TableDataApi.selectByTenant(this.ConfigUri.GRADE_TYPE);
    };

    static selectImmunizationType() {
        return TableDataApi.selectByTenant(this.ConfigUri.IMMUNIZATION_TYPE);
    };

    static selectTestEntry() {
        return TableDataApi.selectByTenant(this.ConfigUri.TEST_ENTRY);
    };

    static selectConfigValueByKey(schoolId, key, onSuccess){
        let config = jng.userContext.configs.findAll(schoolId, 'workplaceId');
        let result = config.findFirst(key, 'id');
        if(jng.isDefined(result)){
            onSuccess(result.name);
        }else{
            onSuccess(undefined)
        }
    }

    static selectStudentCommentPerHomeroomConfig(schoolId, onSuccess){
        this.selectConfigValueByKey(schoolId, this.ConfigKey.IS_PER_HOME_ROOM_STUDENT_COMMENT, result=>{
            if(jng.isDefined(result)){
                onSuccess(result.split(','));
            }else{
                onSuccess([])
            }
        })
    }

    static selectSchoolGradeAssesmentAutoLO(schoolId, onSuccess){
        let config = jng.userContext.configs.findAll(schoolId, 'workplaceId');
        let result = config.findFirst(this.ConfigKey.ASSESSMENT_GRADE_WITH_AUTO_LO, 'id');
        if(jng.isDefined(result)){
            onSuccess(result.name.split(","));
        }else{
            onSuccess([])
        }
    };
    
    //    static selectInvoiceType(BaseApi, onSuccess) {
    //        return TableDataApi.selectByTenant(BaseApi, this.ConfigUri.INVOICE_TYPE, onSucces);
    //    };
    
    static selectStudentNumberType() {
        return TableDataApi.selectByTenant(this.ConfigUri.STUDENT_NUMBER_TYPE);
    };
    
    static selectPaymentType() {
        return TableDataApi.selectByTenant(this.ConfigUri.PAYMENT_TYPE);
    };
    
    static selectEducationLevel() {
        return TableDataApi.selectByTenant(this.ConfigUri.EDUCATION_LEVEL);
    };
    
    static selectLastEducationLevel() {
        return TableDataApi.selectByTenant(this.ConfigUri.LAST_EDUCATION_LEVEL);
    };
    
    static selectGender() {
        return TableDataApi.selectByTenant(this.ConfigUri.GENDER);
    };
    
    static selectSchoolType() {
        return TableDataApi.selectByTenant(this.ConfigUri.SCHOOL_TYPE);
    };
    
    static selectSubjectActivity() {
        return TableDataApi.selectByTenant(this.ConfigUri.SUBJECT_ACTIVITY);
    };
    
    static selectSubjectCategory1() {
        return TableDataApi.selectByTenant(this.ConfigUri.SUBJECT_CATEGORY_1);
    };
    
    static selectSubjectCategory2() {
        return TableDataApi.selectByTenant(this.ConfigUri.SUBJECT_CATEGORY_2);
    };
    
    static selectSubjectCategory3() {
        return TableDataApi.selectByTenant(this.ConfigUri.SUBJECT_CATEGORY_3);
    };
    
    static selectSubjectCategory4() {
        return TableDataApi.selectByTenant(this.ConfigUri.SUBJECT_CATEGORY_4);
    };
    
    static selectSubjectCategory5() {
        return TableDataApi.selectByTenant(this.ConfigUri.SUBJECT_CATEGORY_5);
    };
    
    
    static selectMaritalStatus() {
        return TableDataApi.selectByTenant(this.ConfigUri.MARITAL_STATUS);
    };
    
    static selectLifeStatus() {
        return TableDataApi.selectByTenant(this.ConfigUri.LIFE_STATUS);
    };
    
    static selectReligion() {
        return TableDataApi.selectByTenant(this.ConfigUri.RELIGION);
    };
    
    static selectWorkingStatus() {
        return TableDataApi.selectByTenant(this.ConfigUri.WORKING_STATUS);
    };
    
    static selectDepartment() {
        return TableDataApi.selectByTenant(this.ConfigUri.DEPARTMENT);
    };
    
    static selectWorkingRelationship() {
        return TableDataApi.selectByTenant(this.ConfigUri.WORK_RELATIONSHIP);
    };
    static selectStructuralPosition() {
        return TableDataApi.selectByTenant(this.ConfigUri.STRUCTURAL_POSITION);
    };
    static selectAcademicPosition() {
        return TableDataApi.selectByTenant(this.ConfigUri.ACADEMIC_POSITION);
    };
    static selectMarketingChannel() {
        return TableDataApi.selectByTenant(this.ConfigUri.MARKETING_CHANNEL);
    };
    static selectRecordStudentType() {
        return TableDataApi.selectByTenant(this.ConfigUri.RECORD_STUDENT_TYPE);
    };
    static selectInterest() {
        return TableDataApi.selectByTenant(this.ConfigUri.INTEREST);
    };
    static selectCompetencyCategory() {
        return TableDataApi.selectByTenant(this.ConfigUri.COMPETENCY_CATEGORY);
    };
    static selectCompetencyLevel() {
        return TableDataApi.selectByTenant(this.ConfigUri.COMPETENCY_LEVEL);
    };
    static selectGuestBookStatus() {
        return TableDataApi.selectByTenant(this.ConfigUri.GUEST_BOOK_STATUS_MASTER);
    };
    static selectProgram() {
        return TableDataApi.selectByTenant(this.ConfigUri.PROGRAM);
    };
    static selectWorkingPosition() {
        return TableDataApi.selectByTenant(this.ConfigUri.WORKING_POSITION);
    };
    static selectScheduleBookingType() {
        return TableDataApi.selectByTenant(this.ConfigUri.SCHEDULE_BOOKING_TYPE);
    };
    static selectPaymentSchemeFor() {
        if (jng.isDefined(onSuccess)) {
    
            onSuccess({data: [{id: 'STUDENT', value: 'STUDENT'}
                , {id: 'REGISTRATION', value: 'REGISTRATION'}]});
        }
        return jng.emptyPromise()
    };
    static selectPaymentSchemeUsage() {
        if (jng.isDefined(BaseApi, onSuccess)) {
            onSuccess({data: [{id: 'SCHEMA', value: 'Skema'}
                , {id: 'OPTIONAL', value: 'Pilihan'}]});
        }
        return jng.emptyPromise()
    };
    static selectExtracurricularType(BaseApi, onSuccess) {
        if (jng.isDefined(BaseApi, onSuccess)) {
            onSuccess({data: [{id: 'OPTIONAL', value: 'Pilihan'}
                , {id: 'MANDATORY', value: 'Wajib'}]});
        }
        return jng.emptyPromise()
    };
    
    static selectInvoiceRuleForRegistration(BaseApi, onSuccess) {
        if (jng.isDefined(BaseApi, onSuccess)) {
            onSuccess(
                {data: [{id: 'FIXED', value: 'Fixed Date'}, {id: 'AFTER_TEST', value: 'After Test'}]}
            );
        }
        return jng.emptyPromise()
    };
    static selectInvoiceRuleForStudent(BaseApi, onSuccess) {
        if (jng.isDefined(BaseApi, onSuccess)) {
            onSuccess(
                {data: [{id: 'FIXED', value: 'Fixed Date'}]}
            );
        }
        return [];
        // return jng.emptyPromise()
    };
    static selectProductCategory1(BaseApi, onSuccess) {
        return TableDataApi.selectByTenant(BaseApi, this.ConfigUri.PRODUCT_CATEGORY_1, onSuccess);
    };
    static selectProductCategory2(BaseApi, onSuccess) {
        return TableDataApi.selectByTenant(BaseApi, this.ConfigUri.PRODUCT_CATEGORY_2, onSuccess);
    };
    static selectProductCategory3(BaseApi, onSuccess) {
        return TableDataApi.selectByTenant(BaseApi, this.ConfigUri.PRODUCT_CATEGORY_3, onSuccess);
    };
    static selectProductCategory4(BaseApi, onSuccess) {
        return TableDataApi.selectByTenant(BaseApi, this.ConfigUri.PRODUCT_CATEGORY_4, onSuccess);
    };
    static selectProductCategory5(BaseApi, onSuccess) {
        return TableDataApi.selectByTenant(BaseApi, this.ConfigUri.PRODUCT_CATEGORY_5, onSuccess);
    };

    static selectStockType(BaseApi, onSuccess) {
        return TableDataApi.selectByTenant(BaseApi, this.ConfigUri.STOCK_TYPE, onSuccess);
    };
    static selectMarkGroup(BaseApi, onSuccess) {
        return TableDataApi.selectByTenant(BaseApi, this.ConfigUri.MARK_GROUP, onSuccess);
    };

    static extractKiMapping() {
        var kiMapping = undefined
        if(jng.isDefined(window.jng.userContext.configs.findFirst("KI_MAPPING",'id'))){
            kiMapping = window.jng.userContext.configs.findFirst("KI_MAPPING",'id').name
        }
        if (jng.coalesce(kiMapping, '') == '') return [];
        let kiList = kiMapping.split(',');
        let result = [];
        kiList.traverse(function(str) {
            let idValueParts = str.split('::');
            let valueList = idValueParts[1].split(';')
            valueList.traverse(function(val) {
                let valueParts = val.split('|')
                result.add({groupGradeTypeId: valueParts[0], gradePeriodId: valueParts[1], kiNo: idValueParts[0]})
            })
        })
        return result;
    }
    
    static getSubjectCommentDualLang(schoolId){
        let opt = jng.userContext.configs.find(e=>{return e.id.toUpperCase() == this.ConfigKey.SBJ_NOTE_DUAL_LANG && e.workplaceId == schoolId});
        if(jng.isDefined(opt)){
            return opt.name.split(",");
        }
        return [];
    }

    static getStudentNoteHaveGenerateProcess(schoolId){
        let opt = jng.userContext.configs.find(e=>{return e.id.toUpperCase() == this.ConfigKey.IS_STU_NOTE_GENERATE_PROCESS && e.workplaceId == schoolId});
        let result = [];
        if(jng.isDefined(opt)){
            let firstSplit = opt.name.split(",");
            for(let text of firstSplit){
                if(!text.contains("::"))continue;
                let secondSplit = text.split("::");
                result.push({educationLevelId: secondSplit[0], noteTypeId: secondSplit[1]});
            }
            return result;
        }
        return result;
    }

    static isStudentNoteSelection(schoolId, eduLevelId){
        let opt = jng.userContext.configs.find(e=>{return e.id == this.ConfigKey.STUDENT_ATTD_SELECTION_NOTE && e.workplaceId == schoolId});
        if(jng.isDefined(opt)){
            return jng.isDefined(opt.name.split(",").find(eduId => {return eduId == eduLevelId}));
        }else return false;
    }

    static isAutoGenerateForSelection(schoolId, gradePeriodId){
        let opt = jng.userContext.configs.find(e=>{return e.id == this.ConfigKey.SELECTION_AUTO_GEN && e.workplaceId == schoolId});
        if(jng.isDefined(opt)){
            let isGenerated = jng.isDefined(opt.name.split(",").find(itemId => {return itemId == gradePeriodId}));
            console.log(isGenerated);
            return isGenerated;
        }else return false;
    }
}
