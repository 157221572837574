import Vue from "vue"
import { LANGUAGE_CHANGE } from "../config/const"
import JeLang from "./JeLang"

window.naf = {}
let naf = window.naf

naf.lang = {
    bus: new Vue(),
    currentLangId: 'en',
    changeLanguage: function(langId){
      this.currentLangId = langId
      this.bus.$emit(LANGUAGE_CHANGE, langId)
    },
    onChangeLanguage: function(func){
      this.bus.$on(LANGUAGE_CHANGE, (langId) => {
        func(langId)
      })
    },
    get: function(clientId, id) {
      return JeLang.getLang(this.currentLangId, clientId, id);
    },
  }
naf.getApiError = (err) => {
  return err.response.data.message
}