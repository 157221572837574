import { Api } from "./RestResource"

export default class PaymentApi {
    static getPaymentSummary(studentId, schoolId) {
        return Api.get('financial/parent/'+studentId+'/'+schoolId);
    }

    static getPaymentHistory(studentId, schoolId, trxDate) {
        return Api.get(`financial/history-v2/${schoolId}/${studentId}/${trxDate}`)
    }

    static getPaymentDetail(paymentId) {
        return Api.get(`payment/getPayment/${btoa(paymentId)}`)
    }

    // static getInvoiceDetail(invoiceId, schoolId) {
    //     return Api.get(`payment/getInvoice/${btoa(invoiceId)}/${(schoolId)}`)
    // }

    static getInvoiceDetail(schoolId, params) {
        return Api.post(`payment/getInvoiceAll/${(schoolId)}`, params)
    }

    static getInvoiceDueDate(invoiceId){
        return Api.get(`invoice/getDueDate/${btoa(invoiceId)}`)
    }

    static getCountPendingPayment(){
        return Api.get('doku/countPendingPayment')
    }

    static getAllPendingPayment(){
        return Api.get('doku/allPendingPayment')
    }

    static checkPaymentStatus(paymentId){
        return Api.get(`doku/checkStatus/${btoa(paymentId)}`)
    }
}