
import Confirm from '../components/base/ConfirmDialog';
import SnackBar from '../components/base/SnackBar';
import IndentityTab from "../components/studentTab/IdentityTab"
import AddressTab from "../components/studentTab/AddressTab"
import ParentTab from "../components/studentTab/ParentTab"
import SiblingSchoolTab from "../components/studentTab/SiblingSchoolTab"

import InboxTab from "../components/messageTab/InboxTab"
import DraftTab from "../components/messageTab/DraftTab"
import SentTab from "../components/messageTab/SentTab"

import MarkDetailDialog from '../components/report/MarkDetailDialog.vue';
import AbsentSchedule from '../components/absent/AbsentSchedule.vue';
import AssessmentMarkDialog from '../components/assessment/AssessmentMarkDialog.vue';

import SetupSchCodeDialog from '../components/login/SetupSchCodeDialog.vue';

function setupComponents(Vue){
    Vue.component('school-code-dialog', SetupSchCodeDialog);

    Vue.component('confirm', Confirm);
    Vue.component('SnackBar', SnackBar);

    Vue.component('indentity-tab', IndentityTab);
    Vue.component('address-tab', AddressTab);
    Vue.component('parent-tab', ParentTab);
    Vue.component('siblingschool-tab', SiblingSchoolTab);

    Vue.component('inbox-tab', InboxTab);
    Vue.component('draft-tab', DraftTab);
    Vue.component('sent-tab', SentTab);

    Vue.component('mark-detail-dialog', MarkDetailDialog);
    Vue.component('absent-schedule', AbsentSchedule);

    Vue.component('assessment-mark-dialog', AssessmentMarkDialog);
}

export {
    setupComponents
  }