import { Api } from "./RestResource"

export default class StudentApi {
    static getStudentProfile(studentId) {
        return Api.get('student/parent/student-profile/'+studentId);
    }

    static getYearProgram(schoolId,studentId) {
        return Api.get('yearProgram/parent/'+schoolId+'/'+studentId);
    }

    static getClassMarkSumByStudentId(schoolId,studentId) {
        return Api.get('studentMark/class/summaryWithStudentId/'+studentId+'/'+schoolId);
    }

    static getJsonStudentMark(schoolId,studentId) {
        return Api.get('studentMark/parentMark/'+studentId+'/'+schoolId);
    }

    // static getJsonStudentMark(schoolId,studentId) {
    //     return Api.get('studentMark/parentMark-v2/'+studentId+'/'+schoolId);
    // }

    static checkStudentMarkSummary(studentId) {
        return Api.get(`studentMark/studentMarkConfig/${btoa(studentId)}`)
    }

    static getDataStudentMarkFormulationData(schoolId,studentId) {
        return Api.get('studentMark/parent/subjectFormulation/'+studentId+'/'+schoolId)
    }

    static getStudentAssessmentMark(studentId,gradePeriodId,
        groupGradeTypeId,gradeTypeId,
        subjectId,classId,
        schoolId,subjectActivityId ){
        return Api.get('studentMark/parent/'+studentId+'/'+gradePeriodId+'/'+groupGradeTypeId+'/'+gradeTypeId+'/'+subjectId+'/'+classId+'/'+schoolId+'/'+subjectActivityId)
    }

}