<template>
  <div class="text-center">
    <v-snackbar
      v-model="snackbar"
      :color="color"
    >
      {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="close"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
  export default {
    name: "SnackBar",
    data: () => ({
      multiLine: true,
      color: "", /*error, success*/
      snackbar: false,
      text: "",
    }),
    methods: {
        close(){
            this.snackbar = false
            this.text = ""
        },
        show(text, color){
            this.color = color
            this.snackbar = true
            this.text = text
            setTimeout(() => {
                this.close()
            }, 5000);
        },
    },
    // computed: {
    //     snackbar(){
    //         return this.$store.state.SnackStore.showSnack
    //     },
    //     text(){
    //         return this.$store.state.SnackStore.textSnack
    //     },
    // },
    mounted() {
        jng.snackError = (text) => this.show(text, "error")
        jng.snackSuccess = (text) => this.show(text, "success")
        naf.snackError = (text) => this.show(text, "error")
        naf.snackSuccess = (text) => this.show(text, "success")
    }
  }
</script>

<style lang="scss" scoped>
    .background-theme{
        background: rgba(25, 118, 210, 0.7);
    }
</style>