<template>
<div class="announcement pa-6">
    <v-subheader>
        <v-switch
        inset
        :input-value="switchStudent"
        :label="`Show For All Student`"
        @change="changedStudent"/>
    </v-subheader>
    <v-list>
      <v-list-item-group>
        <template v-for="(item, index) in items">
          <v-list-item 
          :key="item.id"
          @click="messageClick(item.id)">
            <template>
              <v-list-item-content>
                <v-list-item-title v-text="item.name"></v-list-item-title>
                <v-list-item-subtitle v-text="item.subject"></v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-action>
                <v-list-item-action-text>
                    {{_millisToStringDate(item.dateMessage)}}
                </v-list-item-action-text>

                <v-icon v-if="item.isRead">
                  mdi-email-open-outline 
                </v-icon>

                <v-icon v-else>
                  mdi-email
                </v-icon>
              </v-list-item-action>
            </template>
          </v-list-item>

          <v-divider
            v-if="index < items.length - 1"
            :key="index"
          ></v-divider>
        </template>
      </v-list-item-group>
    </v-list>
</div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    name: "Annoucement",

    data: () => ({
        messageType: 'ANNOUNCEMENT',
        countSwipe: '1',
    }),

    computed:{
        ...mapGetters({ items: "itemListAnnoucement", selectStudent: "getStudentSelected", switchStudent: "isAllStudent" }),
    },

    watch:{
    switch1(newValue) {
      this.loadData(newValue)
    }},

    created() { 
        this.loadData(this.switch1)
    },

    methods: {
        changedStudent(switchStudent) {
            this.$store.dispatch('setSwitchAllStudent', switchStudent)
        },
        
        _millisToStringDate(millisDate){
            return Date.toDateString(new Date(millisDate), "dd-mm-yyyy")
        },

        loadData (allStudent){
            if (allStudent){
                 this.$store.dispatch('loadListAnnoucementData', 
                {'messageType':this.messageType,
                'schoolId':this.selectStudent.schoolId,
                'countSwipe':this.countSwipe,
                })
            } else {
                 this.$store.dispatch('loadStudentListAnnoucementData', 
                {'messageType':this.messageType,
                'studentId':this.selectStudent.nim,
                'countSwipe':this.countSwipe,
                })
            }
        },

        messageClick(id) {
            this.$router.push({name: 'ReadInbox', params: { id: id } });
        }
    }

}
</script>