<template>
<v-card class="pa-6">
    <v-app-bar
      app
      color="primary"
      dense
      dark
      elevate-on-scroll
      scroll-target="#scrolling-techniques-7">
      <v-btn icon>
        <v-icon @click.native="backHome">mdi-arrow-left</v-icon>
      </v-btn>
      <v-toolbar-title>{{label.label}}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon>
        <v-icon @click.native="sendFile">mdi-file-upload-outline</v-icon>
      </v-btn>
    </v-app-bar> 
    <v-sheet id="scrolling-techniques-7" class="overflow-y-auto" height="100%" >
    <v-container>
        <v-row dense>
            <v-col v-for="item in items" :key="item.fileId" cols="12">
              <v-card>
                <v-img :src="item.srcImg"
                class="grey darken-4"
                height="200px"
                contain>
                <v-card-title v-text="item.fileName"></v-card-title>
                </v-img>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn icon  @click="confirmClick(item)">
                    <v-icon>mdi-trash-can-outline </v-icon>
                    </v-btn>
                </v-card-actions>  
              </v-card>
            </v-col>
          </v-row >
    </v-container>
    </v-sheet>
</v-card>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    data: () => ({
        label: {
            label: "Assessment Attachment"
        },
    }),

    computed:{
        ...mapGetters({ items: "getAssessmentMedia" }),
    },
    created() {
      this.$store.dispatch('getAssessmentUpload', {'assessmentId':this.$route.params.id})
    },

    methods:{

      sendFile(){
        
      },

      backHome(){
        this.$router.go(-1)
      },

      async confirmClick(params) {
        if (
          await this.$root.$confirm(
            'Edconnect Delete',
            'Are you sure you want to delete this file?',
            { color: 'red' })) {
                this.$store.dispatch('deleteAttachment', params)
            }
      }
    }
}
</script>