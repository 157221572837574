<template>
<v-card class="pa-6">
    <v-app-bar
      app
      color="primary"
      dense
      dark
      elevate-on-scroll
      scroll-target="#scrolling-techniques-7">
      <v-btn icon>
        <v-icon @click.native="backHome">mdi-arrow-left</v-icon>
      </v-btn>
      <v-toolbar-title>{{label.label}}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon>
        <v-icon v-if="isEditing" @click.native="sendPublish">mdi-send-outline </v-icon>
      </v-btn>
    </v-app-bar>  

    <v-sheet
      id="scrolling-techniques-7"
      class="overflow-y-auto">
      <v-container>
          <v-col>
            <v-form
            ref="form"
            v-model="valid"
            lazy-validation>
              <v-autocomplete v-if="isEditing"
                :items="items"
                item-text="subjectName"
                item-value="to"
                :rules="lecturerRules"
                dense
                label="To"
                multiple
                required
                v-model="postData.recipients"
                @change="selectRecipients"
                return-object/>
              <v-autocomplete v-else
                :disabled="!isEditing" 
                :items="items"
                item-text="toName"
                item-value="to"
                v-model="postData.recipients"
                dense
                label="To"
                multiple/>
              <v-text-field
                :disabled="!isEditing"
                v-model="postData.subject"
                label="Subject" 
                :rules="subjectRules" required/>
              <v-textarea
                :disabled="!isEditing"
                v-model="postData.bodyMessage"
                :rules="messageRules" 
                solo
                filled
                name="input-message"
                label="Message"
                auto-grow
                required/>
              <v-file-input 
              v-model="chosenFile"
              :disabled="!isEditing"
              accept="image/png, image/jpeg, image/bmp" 
              label="Attachment"/>  
            </v-form>
          </v-col>
          <v-col v-for="(item, i) in postData.attachments" :key="i" :cols="6">
            <v-card>
                <v-card-title v-text="item.fileName"></v-card-title>
              <v-card-actions>
                <v-btn icon v-if="isEditing">
                  <v-icon @click.native="_removeAttach(i)">mdi-delete</v-icon>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
      </v-container>    
    </v-sheet>
</v-card>
</template>

<script>
import { mapGetters } from "vuex";
import { StatusType } from '@/store/enums';

export default {

  data:() => ({
    valid: true,
    isEditing: true,
    chosenFile: null,
    dataImg: null,
    
    postData: {
      id:"",
      schoolId:"",
      from:"",
      dateMessage:Date.now(),
      status:"DRAFT",
      senderType:"PARENT",
      messageType:"MESSAGE",
      priority:"STANDARD",
      subject:"",
      bodyMessage:"", 
      recipients:[],
      attachments:[]
    },
    label: {
        label: "New Message"
    },
    subjectRules: [ v => !!v || 'Subject is required'],
    messageRules: [ v => !!v || 'Message is required'],
    lecturerRules: [
        v => !!v || 'Receiver is required',
        v => v.length>0 || "Receiver is required in select"],

  }),

  created() {
    if (this.$route.params.id != null){
      if (this.$route.params.status === 'SENT'){
        this.label.label = "Read Message"
        this.isEditing = !this.isEditing
        this.$store.dispatch('selectOutboxData', {'Id':this.$route.params.id})
      } else if (this.$route.params.status === 'DRAFT'){
        this.label.label = "Edit Message"
        this.$store.dispatch('selectDraftData', {'Id':this.$route.params.id})
      }
    } else {
        this._setDraftMessage()
    }
  },

  computed:{
      ...mapGetters({ dataMessage: "getOutboxSelected", 
      items: "itemListLecturerByClass", 
      selectStudent: "getStudentSelected"}),
  },
  
  watch:{
    
    chosenFile: {
      handler: function (newValue, oldValue) {
        // this.importImage()
        this._uploadAttachment()
      },
      deep: true
    },

    dataMessage: {
      handler: function (newValue, oldValue) {
        this.postData = newValue
      }
    },

    itemsUploadAssessment: {
      handler: function (newValue, oldValue) {
        this.postData.attachments = newValue
      }
    }

  },

  methods:{

    _setDraftMessage() {
      this.postData.schoolId = this.selectStudent.schoolId
      this.postData.from = this.selectStudent.nim

      this.$store.dispatch('setSaveMessage', this.postData).then(res => {
        if (res != StatusType.FAILURE){
          this.postData.id = res;
        } else {
          if (this.$refs.form.validate()) {
            jng.snackSuccess("Save message as draft success")
          }
        }
      })
    },

    // importImage() {
    //   var reader = new FileReader();
    //   reader.readAsDataURL(this.chosenFile)
    //   reader.onload = () => {
    //     this.dataImg = reader.result;
    //     this._uploadAttachment();
    //   }
    // },

    _uploadAttachment(){
      const fd = new FormData
      fd.append('object-uri','edc.bo.msg.Message'),
      fd.append('tag','')
      fd.append('file-type',this.chosenFile.type)
      fd.append('file', this.chosenFile, this.chosenFile.name)

      this.$store.dispatch('uploadAttachment', fd).then(res => {
        if (res != StatusType.FAILURE){
          this.postData.attachments.push({downloadedImage: this.chosenFile, fileId: res.id, fileName: this.chosenFile.name, itemNo: this.postData.attachments.length});
        } else {
          jng.snackError("upload failed")
        }
      })
    },

    _removeAttach(idx){
      this.postData.attachments.splice(idx, 1);
    },

    selectRecipients(selectedLecture) {
      this.postData.recipients = []
      this.postData.recipients = selectedLecture
    },

    backHome(){
          this.$router.go(-1)
      },
    
    async sendPublish(){

      if (this.$refs.form.validate()) {
        this.postData.schoolId = this.selectStudent.schoolId
        this.postData.from = this.selectStudent.nim

        if (
          await this.$root.$confirm(
            'Edconnect Confirm',
            'Are you want to Save or Publish this message?',
            { color: 'blue', confirmPositive: 'Save', confirmNegative: 'Publish' })) {
              this._setDraftMessage()
            } else {this.$store.dispatch('setPublishMessage', this.postData).then(res => {
              if (res != StatusType.FAILURE){
                jng.snackSuccess("Sending message success")
                this.backHome()
              }
        })}
      }
    }
  }
}

</script>