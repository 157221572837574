import MessageApi from '../services/MessageApi';
import { StatusType } from '@/store/enums';
import { setAnnoucementData, setAnnoucementSelected, setDraftData, setDraftSelected, setIsAllStudent, setOutboxData, setOutboxSelected, SET_IS_LOADING } from './mutations';

export default {
    state: {
        storeAnnoucementData: [],
        itemsAnnoucementHeader: [],

        storeAnnoucementSelected:'',

        storeShowForAllStudent:true,

        storeOutboxData: [],
        itemsOutboxHeader: [],

        storeOutboxSelected:'',

        storeDraftData: [],
        itemsDraftHeader: [],
    },

    mutations: {
        setIsAllStudent(state, payload){
            state.storeShowForAllStudent = payload
        },

        setAnnoucementSelected(state, payload){
            let annoucement = state.storeAnnoucementData;

            for (var i = annoucement.length - 1; i >= 0; i--) {
                if (annoucement[i].messageId === payload){
                    state.storeAnnoucementSelected = annoucement[i]
                }
            };
        },

        setOutboxSelected(state, payload){
            let outbox = state.storeOutboxData;

            for (var i = outbox.length - 1; i >= 0; i--) {
                if (outbox[i].id === payload){
                    state.storeOutboxSelected = outbox[i]
                }
            };
        },

        setDraftSelected(state, payload){
            let draft = state.storeDraftData;

            for (var i = draft.length - 1; i >= 0; i--) {
                if (draft[i].id === payload){
                    state.storeOutboxSelected = draft[i]
                }
            };
        },

        setAnnoucementData(state, payload){
            state.storeAnnoucementData = []

            state.itemsAnnoucementHeader = []

            Object.keys(payload).forEach(key => {
                let itemsHeaderObject = {
                    id: payload[key].messageId,
                    name: payload[key].fromName,
                    subject: payload[key].subject,
                    dateMessage: payload[key].dateMessage,
                    isRead: payload[key].isRead,
                }
                state.itemsAnnoucementHeader.push(itemsHeaderObject); 
            })

            state.storeAnnoucementData = payload
        },

        setOutboxData(state, payload){
            state.storeOutboxData = [],

            state.itemsOutboxHeader = [],

            Object.keys(payload).forEach(key => {

                let loadRecipients = payload[key].recipients
                let toRecipients = []

                Object.keys(loadRecipients).forEach(idx => {
                    toRecipients.push(loadRecipients[idx].toName)
                })

                let itemsHeaderObject = {
                    id: payload[key].id,
                    nameRecipients: toRecipients,
                    subject: payload[key].subject,
                    dateMessage: payload[key].dateMessage,
                }
                state.itemsOutboxHeader.push(itemsHeaderObject); 
            })

            state.storeOutboxData = payload
        },

        setDraftData(state, payload){

            state.storeDraftData = [],

            state.itemsDraftHeader = [],

            Object.keys(payload).forEach(key => {

                let loadRecipients = payload[key].recipients
                let toRecipients = []

                Object.keys(loadRecipients).forEach(idx => {
                    toRecipients.push(loadRecipients[idx].toName)
                })

                let itemsHeaderObject = {
                    id: payload[key].id,
                    nameRecipients: toRecipients,
                    subject: payload[key].subject,
                    dateMessage: payload[key].dateMessage,
                }
                state.itemsDraftHeader.push(itemsHeaderObject); 
            })

            state.storeDraftData = payload
        }
    },

    actions: {

        setPublishMessage({ commit }, param){
            commit(SET_IS_LOADING, true)
            return MessageApi.publishMessage(param)
            .then(response => {
                return response.data;
            })
            .catch(err => {
                console.log(err);
                return StatusType.FAILURE;
            })
            .finally(() => {
                commit(SET_IS_LOADING, false)
            })
        },
        
        setSaveMessage({ commit }, param){
            commit(SET_IS_LOADING, true)
            return MessageApi.saveMessage(param)
            .then(response => {
                return response.data;
            })
            .catch(err => {
                return StatusType.FAILURE;
            })
            .finally(() => {
                commit(SET_IS_LOADING, false)
            })
        },
        
        setSwitchAllStudent({ commit }, param){
            commit(setIsAllStudent, param)
        },

        deleteAnnoucementData({ commit }, param){
            commit(SET_IS_LOADING, true)
            MessageApi.deleteByRecipientAndWorkplace(param.messageId, param.schoolId)
            .then(response => response.data)
            .catch(err => {
                console.log(err);
            })
            .finally(() => {
                commit(SET_IS_LOADING, false)
            })
        },

        selectAnnoucementData ({ commit }, param){
            commit(setAnnoucementSelected, param.Id)
        },

        selectOutboxData ({ commit }, param){
            commit(setOutboxSelected, param.Id)
        },

        selectDraftData ({ commit }, param){
            commit(setDraftSelected, param.Id)
        },

        loadListAnnoucementData ({ commit }, param){
            commit(SET_IS_LOADING, true)
            MessageApi.getInboxImprovementList(param.messageType, param.schoolId, param.countSwipe)
            .then(response => response.data)
            .then(storeAnnoucementData => {
                commit(setAnnoucementData, storeAnnoucementData)
            })
            .catch(err => {
                console.log(err);
            })
            .finally(() => {
                commit(SET_IS_LOADING, false)
            })
        },

        loadStudentListAnnoucementData ({ commit }, param){
            commit(SET_IS_LOADING, true)
            MessageApi.getInboxImprovementListOnlyOneStudent(param.messageType,  param.countSwipe, param.studentId)
            .then(response => response.data)
            .then(storeAnnoucementData => {
                commit(setAnnoucementData, storeAnnoucementData)
            })
            .catch(err => {
                console.log(err);
            })
            .finally(() => {
                commit(SET_IS_LOADING, false)
            })
        },

        loadListOutboxData ({ commit }, param){
            commit(SET_IS_LOADING, true)
            MessageApi.getOutboxList(param.messageType, param.schoolId)
            .then(response => response.data)
            .then(storeOutboxData => {
                commit(setOutboxData, storeOutboxData)
            })
            .catch(err => {
                console.log(err);
            })
            .finally(() => {
                commit(SET_IS_LOADING, false)
            })
        },

        loadStudentListOutboxData ({ commit }, param){
            commit(SET_IS_LOADING, true)
            MessageApi.getOutboxListOnlyOneStudent(param.messageType, param.studentId)
            .then(response => response.data)
            .then(storeOutboxData => {
                commit(setOutboxData, storeOutboxData)
            })
            .catch(err => {
                console.log(err);
            })
            .finally(() => {
                commit(SET_IS_LOADING, false)
            })
        },

        loadListDraftData ({ commit }, param){
            commit(SET_IS_LOADING, true)
            MessageApi.getDraftListAll(param.messageType)
            .then(response => response.data)
            .then(storeDraftData => {
                commit(setDraftData, storeDraftData)
            })
            .catch(err => {
                console.log(err);
            })
            .finally(() => {
                commit(SET_IS_LOADING, false)
            })
        },

        loadStudentListDraftData ({ commit }, param){
            commit(SET_IS_LOADING, true)
            MessageApi.getDraftList(param.messageType, param.schoolId, param.studentId)
            .then(response => response.data)
            .then(storeDraftData => {
                commit(setDraftData, storeDraftData)
            })
            .catch(err => {
                console.log(err);
            })
            .finally(() => {
                commit(SET_IS_LOADING, false)
            })
        }

    },

    getters: {
        itemListAnnoucement: state => state.itemsAnnoucementHeader,

        getAnnoucementSelected: state => state.storeAnnoucementSelected,

        isAllStudent: state => state.storeShowForAllStudent,

        itemListOutbox: state => state.itemsOutboxHeader,

        getOutboxSelected: state => state.storeOutboxSelected,

        itemListDraft: state => state.itemsDraftHeader,

    }
}