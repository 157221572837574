<template>
    <v-container class="login fill-height" fluid :style="{'background-image': 'url(' + background + ')'}">
        <v-row align="center" justify="center">
            <v-col cols="12" sm="8" md="5" lg="4" xl="3">
                <v-card :class="['elevation-12', $vuetify.breakpoint.xs ? 'rounded-md' : 'rounded-lg']">
                    <ClientLogo class="pt-4" is-for-login/>
                    <v-card-text class="my-8">
                        <router-link class="font-smaller" id="changeSchool"  @click.native="_openDialogSchCode" to="">Set SchoolCode</router-link>
                        <v-form
                        ref="form"
                        v-model="valid"
                        lazy-validation>
                            <v-text-field
                            v-model="postData.userName"
                            :counter="30"
                            :rules="nameRules"
                            label="User"
                            required/>

                            <v-text-field
                            :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                            :type="show ? 'text' : 'password'"
                            v-model="postData.password"
                            :rules="passwordRules"
                            label="Password"
                            required
                            @click:append="show = !show"/>
                        </v-form>
                    </v-card-text>
                    <div class="px-4 pb-6 login-action">
                        <v-btn :class="`mb-4 ${isLoading && 'cursor-default'}`" rounded color="primary" dark width="100%" @click="_loginParents">
                            <span v-if="!isLoading">Login</span>
                            <v-progress-circular v-else size="20" indeterminate color="primary" />
                        </v-btn>
                        <router-link id="forgotPassword" class="font-smaller" to="/forgot-password">Forgot Password?</router-link>
                    </div>
                </v-card>
            </v-col>
        </v-row>

        <school-code-dialog ref="school_code" />

    </v-container>
</template>

<script>
import { mapState } from "vuex";
import ClientLogo from '@/components/ClientLogo'
import { StatusType } from '@/store/enums';
export default {

    name: "Login",
    components: {
        ClientLogo
    },

    computed: {
        ...mapState(['isLoading']),
    }, 
    
    data:() => ({
        background: '',
        show: false,
        valid: true,
        dialog: false,
        nameRules: [
            v => !!v || 'User is required',
            v => (v && v.length <= 30) || 'User must be less than 30 characters',
        ],
        
        passwordRules: [
            v => !!v || 'Password is required',
        ],

        postData: {
            userName:"",
            password:"", 
        },
    }),

    methods:{
        _openDialogSchCode(){
            this.$store.dispatch('setupSch');
            this.$refs.school_code.openSchDialog();
        },

        _loginParents(){
            if (this.isLoading) return
            if (localStorage.getItem('urlSchool') !== 'https://sys.edconnect.co.id/api' 
            && localStorage.getItem('urlSchool') !== ''
            && localStorage.getItem('urlSchool') != null){
                this.$store.dispatch('loginUser', this.postData).then(res => {
                    if (res === StatusType.SUCCESS){
                        jng.snackSuccess("Login Success")
                        this._gotoHome();
                    } else {
                        jng.snackError("Login Error")
                        this.$root.$refs.main_component.logoutFunction();
                    }
                });
            } else {
                jng.snackError("Login Error: Set School Code")
                this._openDialogSchCode();
            }
        },

        _gotoHome(){
            this.$router.push({name: 'Home'});
       }
    },
    mounted(){
        this.background = 'img/background2.jpg'
        if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
            if (localStorage.getItem('urlSchool') === window.location.origin +'/api/'){
                this._openDialogSchCode();
            }
        }

        if (!localStorage.hasOwnProperty('urlSchool')){
            this._openDialogSchCode();
        } else if (localStorage.hasOwnProperty('token') ){
            this.$router.push({name: 'Home'});
        }   
    },
}
</script>
<style lang="scss" scoped>
    #changeSchool {
        display: block;
        width: 100%;
        text-align: right;        
    }
    .login-action {
        text-align: center;
    }
    .login {
        background-position: center center;
        background-size: cover;
    }
</style>