<template>
<div class="siblingschool-tab">
  <div v-for="(item, i) in items" :key="i">
    <v-row class="align-center" v-for="data in dataList" :key="data.field">
      <v-col cols="2" sm="1">
          <v-icon>{{data.icon}}</v-icon>
      </v-col>
      <v-col cols="10" sm="5">
        <div class="label-small">
          {{label[data.field]}}
        </div>
        <div>
          {{item[data.field] ? item[data.field] : " - " }}
        </div>
      </v-col>
    </v-row>
  </div>
</div>
</template>

<script>

import { mapGetters } from "vuex";

export default {
    name: 'SiblingSchool',

    data:() => ({
      label: {
        name: "Name",
        grade: "Grade",
        status: "Status",
      },
      dataList: [
        { icon: "mdi-account-outline", field: "name" },
        { icon: "mdi-home", field: "grade" },
        { icon: "mdi-phone", field: "status" },
      ]
    }),

    computed:{
    ...mapGetters({ items: "itemsSiblingsSameSchool" }),
  },
}

</script>

<style lang="scss" scoped>
  .siblingschool-tab {
    border: 1px solid black;
    border-radius: 1rem;
    padding: 0.5rem;
    margin-top: 0.5rem;
  }
</style>