<template>
  <v-card class="overflow-hidden" style="height: 100%;">
    <app-bar :label="paymentId"  />

    <v-sheet id="scrolling-techniques-7" class="overflow-y-auto" height="100%" >
      <v-container >
        <div class="d-flex justify-center py-1 border-radius-1">
          {{paymentDetail.paymentTypeName?paymentDetail.paymentTypeName:paymentDetail.paymentType}} - {{paymentDate}}
        </div>
        <div class="history-box elevation-3" v-for="(invoice, i) in paymentDetail.items" :key="i">
          <label-text
            v-for="invField in invoiceFields" 
            :key="invField.field"
            class="mt-2"
            :showDivider="true"
            :label="label[invField.field]" 
            :text="_formatValue(invoice[invField.field], invField)" 
          />
        </div>
      </v-container>
    </v-sheet>
  </v-card>
</template>

<script>
import AppBar from '@/components/base/AppBar.vue'
import PaymentApi from "@/services/PaymentApi.js"
import LabelText from '@/components/base/LabelText.vue'

export default {
    name: 'PaymentDetail',

    components: { AppBar, LabelText },

    props: {
    },

    data: () => ({
      label: {
        title: "Payment Detail",
        note: "Note",
        invAmount: "Invoice Amount",
        paymentAmount: "Payment Amount",
        invTypeName: "Invoice Type",
        invDate: "Invoice Date",
        
      },
      invoiceFields: [
        { field: "note" },
        { field: "invAmount", isAmount: true },
        { field: "paymentAmount" },
        { field: "invTypeName" },
        { field: "invDate", isDate: true },
      ],
      paymentDetail: {},
    }),

    methods:{
        
        _getPaymentDetail(){
          if (!this.paymentId) return this.router.push({ name: 'Home' })
          PaymentApi.getPaymentDetail(this.paymentId)
              .then(({ data }) => {
                  this.paymentDetail = data
              })
              .catch(err => {
                  jng.snackError(naf.getApiError(err))
              })
        },
        _formatValue(value, invField){
          if (invField.isDate) return value.toDateString()
          if (invField.isAmount) return `Rp ${jng.setToCurrency(value)}`
          return value
        },
        _initLabel(){
          this.label.title = naf.lang.get("", "PAYMENT")
        },
    },

    computed:{
      paymentId(){
        return atob(this.$route.params.paymentId)
      },
      paymentDate(){
        if (!this.paymentDetail.paymentDate) return ""
        return this.paymentDetail.paymentDate.toDateString()
      },
    },

    created() {
      this._getPaymentDetail()
    },

    mounted(){
      naf.lang.onChangeLanguage(() => {
        this._initLabel()
      })
      this._initLabel()
    },
}

</script>

<style lang="scss" scoped>
  $edconnect: #1776CA;
  .history-box{
    background-color: beige;
    border-radius: 1rem;
    padding: 1rem;
    margin-top: 0.7rem;
    margin-bottom: 0.1rem;
  }
  .header-payment{
    padding-left: 1rem;
    background-color: ghostwhite;
    border-radius: 1rem;
  }
  .border-radius-1{
    border-radius: 0.5rem;
  }
</style>