<template>
	<v-dialog
		v-model="dialog"
		:max-width="options.width"
		@keydown.esc="cancel"
		v-bind:style="{ zIndex: options.zIndex }"
	>
		<v-card>
			<v-toolbar dark :color="options.color" dense flat>
				<v-toolbar-title class="white--text">{{ title }}</v-toolbar-title>
			</v-toolbar>
			<v-card-text v-show="!!message">{{ message }}</v-card-text>
			<v-card-actions class="pt-0">
				<v-spacer></v-spacer>
				<v-btn text color="primary" @click.native="agree">{{ options.confirmPositive }}</v-btn>
				<v-btn v-if="options.useOkCancel"  text @click.native="cancel">{{ options.confirmNegative }}</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
export default {
	data: () => ({
		dialog: false,
		resolve: null,
		reject: null,
		message: null,
		title: null,
		options: {
			color: 'primary',
			width: 290,
			zIndex: 200,
			confirmPositive: 'Yes',
			confirmNegative: 'Cancel',
			useOkCancel: true,
		}
	}),
	methods: {
		open(title, message, options) {
			this.dialog = true;
			this.title = title;
			this.message = message;
			this.options = Object.assign(this.options, options);
			return new Promise((resolve, reject) => {
				this.resolve = resolve;
				this.reject = reject;
			});
		},
		agree() {
			this.resolve(true);
			this.dialog = false;
		},
		cancel() {
			this.resolve(false);
			this.dialog = false;
		}
	}
};
</script>