import { Api } from "./RestResource"

export default class LoginApi {

    static getSchoolURL (schoolCode) {
        return Api.get('login/url/'+schoolCode);
    }

    static login(params) {
        return Api.post('login', params);
    }

    static checkIfAlreadyLogin() {
        return Api.get('login');
    }

    static getUserContext(){
        return Api.get("login/context")
    }

    static changeCurrentLanguage(langId) {
        return Api.post('/login/change-current-language'.concatUrl(langId))
    }

    static logout() {
        return Api.delete('login', {withCredentials: true});
    }

    static resetPassword(email) {
        return Api.delete(`password/${btoa(email)}`)
    }

    static changePassword(newPassword){
        return Api.post(`password/${(newPassword)}`)
    }
}