<template>
<v-card class="pa-6">
    <app-bar :label="label.label" :valueBack="label.backPage" />

    <v-sheet id="scrolling-techniques-7" class="overflow-y-auto" height="100%" >
        <v-container >
            <v-row>
                <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                :return-value.sync="dateAttendance"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    v-model="dateAttendance"
                    label="Select month and year"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"></v-text-field>
                </template>
                <v-date-picker
                v-model="dateAttendance"
                type="month"
                no-title
                scrollable>
                <v-spacer></v-spacer>
                <v-btn
                    text
                    color="primary"
                    @click="menu = false"
                >
                    Cancel
                </v-btn>
                <v-btn
                    text
                    color="primary"
                    @click="$refs.menu.save(dateAttendance)"
                >
                    OK
                </v-btn>
                </v-date-picker>

                </v-menu>
            </v-row>
        </v-container>
        <v-container >
            <pie-chart></pie-chart>
        </v-container>
        <v-container >
            <v-btn text
            color="primary"
            @click="_detaiChart()"
            :disabled="disabled">
            detail
        </v-btn>

        </v-container>
    </v-sheet>
</v-card>
</template>

<script>
import PieChart from "@/components/chart/PieChart";
import AppBar from '@/components/base/AppBar.vue'
import { mapGetters } from 'vuex';

export default {
    components: { AppBar, PieChart },

    created(){
        this._setChartAttendance(this.dateAttendance);
    },

    computed:{
      ...mapGetters({ objItm: "getSelectAttendance" }),
    },

    watch:{
        dateAttendance: {
            handler: function (newValue, oldValue) {
                this._setChartAttendance(newValue)
            },deep: true
        },

        objItm: {
            handler: function (newValue, oldValue) {
                this.disabled = (newValue) ? false : true
            },deep: true
        },
    },

    data: () => ({
        dateAttendance: new Date().toISOString().substr(0, 7),
        menu: false,
        modal: false,
        disabled: false,
        label: {
            label: "Attendance",
            backPage: "Home"
        },
        postData: {
            year:'',
            month:'',
        }
    }),

    methods:{
        _detaiChart(){
            this.$router.push({ name: 'AttendanceDetail' }).catch(()=>{});
        },

        _setChartAttendance(d){
            var slcMonthYear =  d.split('-');
            this.postData.year = slcMonthYear[0]
            this.postData.month = slcMonthYear[1]

            this.$store.dispatch('getSummarizedAbsent', this.postData);
        }

    }
}
</script>