<template>
<div class="mark pa-6">
    <v-dialog v-model="dialogMark" scrollable max-width="600px">
        <v-card>
            <v-card-title class="headline">{{ mTitle }}</v-card-title>
            <v-list>
                <v-list-item v-for="item in items"
                :key="item.kdNo"
                no-action>
                    <v-list-item-content>
                        <v-list-item-title v-text="item.groupGradeTypeName"></v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-content>
                        <v-list-item-subtitle v-text="item.mark"></v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-card>
    </v-dialog>
</div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
    data: () => ({
        dialogMark: false,
        mTitle: 'Mark'
    }),
    computed:{
        ...mapGetters({ items: "getAssessmentMark" }),
    },
    methods: {
        openMark(params) {
            this.dialogMark = true;
            this.$store.dispatch('showMarkData', params);
        }
    }
}
</script>