<template>
  <div class="about">
    <h1>{{label.about}}</h1>
  </div>
</template>

<script>
export default {
    name: "About",
    data: () => ({
      label: {
        about: "This is an about page",
      }
    }),
    mounted(){
      naf.lang.onChangeLanguage(() => {
        this.label.about = naf.lang.get("", "DETAIL_PAYMENT")
      })
      this.label.about = naf.lang.get("", "DETAIL_PAYMENT")
    },
}
</script>

<style>

</style>script
