class JeLang {
  
    static _getItem(langId, langComp, id) {
      var langDefaultComp = jng.lang[langId.toLowerCase()].default;
      var idComponents = id.splitLastPart('__');
      if (idComponents.length == 2) {
        let value = JeLang._getItem(langId, langComp, idComponents[0]);
        let funcName = idComponents[1]
        let funcParam = '\'' + value + '\''
  
        let funcList = idComponents[1].split('(')
        if (funcList.length > 1) {
          funcName = funcList[0]
          let params = funcList[1].getPart(0, ')')
          params = params.split(',')
          params.forEach(param => {
            funcParam += ", \'" + param.trim() + "\'"
          });
        }
        
        
        return eval('langDefaultComp.items.__' + funcName + '(' + funcParam + ')');
      } else {
        const val = jng.getValueOfObject(langComp.items, id)
        if (jng.isDefined(val)) {
            return val;
        };
        return jng.getValueOfObject(langDefaultComp.items, id)
      }
    }
    static getLang(langId, clientId, id) {
      let result = ''
      if (jng.coalesce(langId, '') == '') return result  
      if (clientId !== "") {
        let newClientId = jng.langMaps[clientId]
        if (jng.coalesce(newClientId, '') == '') {
          newClientId = clientId
        }
        if (jng.isDefined(jng.lang[langId.toLowerCase()][newClientId])) {
            var langComp = jng.lang[langId.toLowerCase()][newClientId];
            result = JeLang._getItem(langId, langComp, id);
            if (jng.coalesce(result, '') != '') return result
        }
      }
      let tenantId = ''
      if (jng.isDefined(jng.user) && jng.isDefined(jng.user.tenantId)) {
        tenantId = jng.user.tenantId.toLowerCase()
      }
      if (jng.isDefined(jng.lang[langId.toLowerCase()][tenantId])) {
        var langComp = jng.lang[langId.toLowerCase()][tenantId];
        result = JeLang._getItem(langId, langComp, id);
        if (jng.coalesce(result, '') != '') return result
      }
      return JeLang._getItem(langId, jng.lang[langId.toLowerCase()].default, id);
    }
  }
  
  export default JeLang;