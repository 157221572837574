import { Api } from "./RestResource"

export default class ScheduleApi {
    static getDateScheduleByCurrentClassId(meetingDate, schoolId, classId) {
        return Api.get('schedule/parent/current/'+meetingDate+'/'+schoolId+'/'+classId);
    }

    static getAbsentDailySchedule(schoolId, 
        yearProgramId, lecturerId,
        meetingDate, classId,
        shiftTemplateId, shiftId, studentId) {
        return Api.get('absent/parentStudent/'+schoolId+'/'+yearProgramId+'/'+lecturerId+'/'+meetingDate+'/'+classId+'/'+shiftTemplateId+'/'+shiftId+'/'+studentId)
    }

    static selectStudentByClassSubjectAndStudentId(schoolClassId, subjectId, studentId, schoolId) {
        return Api.get('studentClassAllocation/parent/'+schoolClassId+'/'+subjectId+'/'+studentId+'/'+schoolId);
    }

    static savePerShift(params) {
        return Api.post('absent/parent', params);
    }

    static saveAllShift(params) {
        return Api.post('absent/parent/allshift', params);
    }


    // API: Schedule
    static getStudentScheduleList(schoolId, gradeId) {
        return Api.get('caldic/student/'+schoolId+'/'+gradeId);
    }

}