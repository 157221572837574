export function changeStringFormatDate(date, format) {
    
    if (jng.isUndefined(date)) {
        return '';
    }
    
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    if(jng.isUndefined(format)){
        return '';
    } else if(format === 'dd-mm-yyyy' || format === 'dd-MM-yyyy' ){
        return [day, month, year].join('-');
    } else if(format === 'dd/mm/yyyy' || format === 'dd/MM/yyyy' ){
        return [day, month, year].join('/');
    } else if(format === 'yyyy-mm-dd' || format === 'yyyy-MM-dd' ){
        return [year, month, day].join('-');
    } else if(format === 'yyyy/mm/dd' || format === 'yyyy/MM/dd' ){
        return [year, month, day].join('/');
    }
}

export function dateWithoutTime(dateTime) {
    var date = new Date(dateTime.getTime());
    date.setHours(0, 0, 0, 0);
    return date;
}

export function countDateNowToDateEnd(date) {
    let diffDay = 0;
    
    const oneDay = 24 * 60 * 60 * 1000;
    const firstDate = dateWithoutTime(new Date());
    const secondDate = new Date(date);

    diffDay = Math.round(Math.abs((firstDate - secondDate) / oneDay))

    if (diffDay == 0){
        return 'Today';
    } else {
        return diffDay+' Days';
    }
    
}