<template>
  <v-card class="overflow-hidden" style="height: 100%;">
    <app-bar :label="label.title" :valueBack="label.backPage" />

    <v-sheet id="scrolling-techniques-7" class="overflow-y-auto" height="100%" >
      <v-container >
          <div class="home px-6 bg-container">
              <student-selector class="py-4" :isSelectOnly="true" :dense="true" :showLabel="false" isDark/>
              <div class="payment-summary">{{paymentSummaryData.invoiceAmount}}</div>
              <div class="d-flex justify-center" style="color: blanchedalmond;">
                {{label.dueDate}}: {{paymentSummaryData.minimumDueDate}}
              </div>
              <div v-if="countPendingPayment" class="check-payment py-4" @click="checkPaymentStatus">
                {{label.checkPaymentStatus}}
              </div>
          </div>
          <div class="px-3 py-2 mt-2 history-box">
            <v-banner single-line>
              <div class="d-flex justify-space-between align-baseline">
                <div style="font-weight: bold;">
                  {{label.history.toUpperCase()}}
                </div>
                <div>
                    <v-select 
                      style="width: 8rem;" 
                      dense hide-details 
                      :items="historyTypeList" 
                      item-text="name" 
                      item-value="id"
                      v-model="historyType"
                    />
                </div>
              </div>
            </v-banner>
            <payment-history-list v-if="historyType == 'payment'" :dataList="paymentHistoryList" :isInvoice="false"/>
            <payment-history-list v-if="historyType == 'invoice'" :dataList="invoiceHistoryList" :isInvoice="true"/>
            <payment-history-list v-if="historyType == 'outstanding'" :dataList="outstandingList" :isInvoice="true"/>
          </div>
      </v-container>
    </v-sheet>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import AppBar from '@/components/base/AppBar.vue'
import StudentSelector from '@/components/common/StudentSelector.vue'
import PaymentHistoryList from '@/components/payment/PaymentHistoryList.vue'

export default {
    name: 'Payment',

    components: {
      AppBar,
      StudentSelector,
      PaymentHistoryList,
    },

    data: () => ({
      label: {
        title: "Payment",
        history: "History",
        checkPaymentStatus: "Check Status Pembayaran",
        dueDate: "Jatuh Tempo",
        backPage: "Home"
      },

      historyTypeList: [
        {id: "outstanding", name: "Outstanding"},
        {id: "invoice", name: "Invoice"},
        {id: "payment", name: "Payment"},
      ],
      historyType: "invoice",
    }),

    methods:{
        checkPaymentStatus(){
          this.$router.push({name: 'Payment.checkPending'})
        },
        _initLabel(){
          this.label.title = naf.lang.get("", "PAYMENT")
        },
    },

    computed:{
      ...mapGetters({ 
        paymentHistoryData: "paymentHistoryData",
        paymentSummaryList: "paymentSummaryData", countPendingPayment: "countPendingPayment" }),
      paymentSummaryData() {
        let defaultValue = {
          invoiceAmount: "Rp - ,00",
          minimumDueDate: " - "
        }
        return this.paymentSummaryList.length ? this.paymentSummaryList[0] : defaultValue
      },
      paymentHistoryList() {
        return this.paymentHistoryData.filter(data => !data.isInvoice);
      },
      invoiceHistoryList() {
        return this.paymentHistoryData.filter(data => data.isInvoice);
      },
      outstandingList() {
        return this.paymentHistoryData.filter(data => data.invoiceRemainAmount > 0);
      },
    },

    created() {
      this.$store.dispatch('getPaymentHistory');
    },

    mounted(){
      naf.lang.onChangeLanguage(() => {
        this._initLabel()
      })
      this._initLabel()
    },
}

</script>

<style lang="scss" scoped>
  $edconnect: #1776CA;

  .bg-container{
    height: 100%; 
    background-color: $edconnect;
    border-radius: 1rem;
  }
  .payment-summary{
    display: flex;
    justify-content: center;
    font-weight: bold;
    font-size: 2rem;
    color: whitesmoke;
  }
  .history-box{
    background-color: beige;
    border-radius: 1rem;
  }
  .check-payment{
    cursor: pointer;
    display: flex;
    justify-content: center;
    color: whitesmoke;
  }
</style>