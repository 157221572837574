import PaymentApi from '../services/PaymentApi'
import { setPaymentHistory, setPaymentSummary, SET_IS_LOADING } from './mutations'
import { StatusType } from '@/store/enums';

export default {
    state: {
        storedPaymentSummary:'',
        itemPaymentSummary: [],
        paymentHistory: [],
        selectedInvoiceDetail: {},
        countPendingPayment: "",
        allPendingPayment: [], 
    },

    mutations: {
        setPaymentSummary(state, payload){
            state.storedPaymentSummary = payload

            state.itemPaymentSummary = []

            let { invoiceAmount, lastPaymentAmount, lastPaymentDate, minimumDueDate } = payload
            
            let paymentObject = {
                color: '#2bb673',
                invoiceAmount: 'Rp. '+jng.setToCurrency(invoiceAmount),
                lastPaymentAmount: 'Rp. '+jng.setToCurrency(lastPaymentAmount),
                lastPaymentDate: lastPaymentDate.toDateString(), 
                minimumDueDate: minimumDueDate.toDateString(),
                action: 'payment' 
            }
            state.itemPaymentSummary.push(paymentObject); 
        },
        setPaymentHistory(state, payload){
            state.paymentHistory = payload
        },
        setSelectedInvoiceDetail(state, payload){
            state.selectedInvoiceDetail = payload
        },
        setCountPendingPayment(state, payload){
            state.countPendingPayment = payload
        },
        setAllPendingPayment(state, payload){
            state.allPendingPayment = payload
        },
    },

    actions: {
        getPaymentSummary({commit, getters}) {
            let studentId = getters.getSelectedStudentId;
            let { schoolId } = getters.getStudentSelected;
            if (!studentId || !schoolId) return
            PaymentApi.getPaymentSummary(studentId, schoolId)
            .then(response => response.data)
            .then(storedPaymentSummary => {
                // jng.log({ storedPaymentSummary })
                commit(setPaymentSummary, storedPaymentSummary)
            })
            .catch(err => {
                console.log(err);
            })
        },

        getPaymentHistory({commit, getters}) {
            let studentId = getters.getSelectedStudentId;
            let { schoolId } = getters.getStudentSelected;
            if (!studentId || !schoolId) return
            let todayDateInMillis = new Date().getTime()
            commit(SET_IS_LOADING, true)
            PaymentApi.getPaymentHistory(studentId, schoolId, todayDateInMillis)
            .then(response => response.data)
            .then(paymentHistory => {
                paymentHistory = paymentHistory.sort((a, b) => new Date(b.documentDate) - new Date(a.documentDate))
                commit(setPaymentHistory, paymentHistory)
            })
            .catch(err => {
                console.log(err);
            })
            .finally(_=>{
                commit(SET_IS_LOADING, false)
            })
        },
        getCountPendingPayment({ commit }){
            return PaymentApi.getCountPendingPayment()
            .then(({ data: countPendingPayment }) => {
                commit('setCountPendingPayment', countPendingPayment)
            })
            .catch(err => {
                naf.getApiError(err)
            })
        },
        getAllPendingPayment({ commit }){
            return PaymentApi.getAllPendingPayment()
                .then(({ data: allPendingPayment }) => {
                    commit('setAllPendingPayment', allPendingPayment)
                })
                .catch(err => {
                    naf.getApiError(err)
                })
        },
        getInvoiceDetail({ commit, getters }){
            let { documentIdList, schoolId } = getters.selectedInvoiceDetail;
            if (!documentIdList || !schoolId) return StatusType.FAILURE;
            commit(SET_IS_LOADING, true)
            return PaymentApi.getInvoiceDetail(schoolId, documentIdList)
            .then(({ data: dataInvoiceDetail }) => {
                return dataInvoiceDetail;
            })
            .catch(err => {
                return StatusType.FAILURE;
            })
            .finally(() => {
                commit(SET_IS_LOADING, false)
            })
        }
    },

    getters: {
        paymentSummaryData: state => state.itemPaymentSummary,
        paymentHistoryData: state => state.paymentHistory,
        selectedInvoiceDetail: state => state.selectedInvoiceDetail,
        countPendingPayment: state => state.countPendingPayment,
        allPendingPayment: state => state.allPendingPayment,
    },
}