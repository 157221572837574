<template>
<v-row dense>
    <v-col cols="12">
        <v-card>
            <v-row dense>
                <v-col>
                    <v-card-title>Assessment</v-card-title>
                </v-col>
            </v-row>
            <template v-if="!items || !items.length">
                <v-card-subtitle>Nothing To Show</v-card-subtitle>
            </template>
            <template v-else>
                <v-list dense>
                <v-list-item v-for="item in items" :key="item.assessmentId" @click="_detail(item.subjectId)">
                    <v-list-item-content>
                        <v-list-item-title v-text="_formatDate(item.startDate) + ' | ' + item.startTime"></v-list-item-title>
                        <v-list-item-title v-text="item.subjectName"></v-list-item-title>
                        <v-list-item-subtitle v-text="item.title"></v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                        <v-list-item-subtitle>{{_countDayDate(item.submitDate, item.endDate)}}</v-list-item-subtitle>
                    </v-list-item-action>
                </v-list-item>
                </v-list>
            </template>
        </v-card>
    </v-col>
</v-row>
</template>

<script>
import { mapGetters } from 'vuex';
import { changeStringFormatDate, countDateNowToDateEnd } from '@/helpers/utils';

export default {
    data: () => ({}),
    
    computed:{
        ...mapGetters({ items: "getAssessmentData", selectStudent: "getStudentSelected" }),
    },

    watch:{
        selectStudent: {
            handler: function (newValue, oldValue) {
                this._loadData();
            },
            deep: true
        }
    },

    methods:{

        _detail(subjectId){
            this.$router.push({name: 'AssessmentDetail'});
        },

        _countDayDate(submitDate, endDate){
            if (submitDate === ""){
                return countDateNowToDateEnd(endDate);
            } else {
                return 'Done';
            }
        },

        _formatDate(date) {
            return changeStringFormatDate(date, 'dd-mm-yyyy');
        },

        _loadData() {
            this.$store.dispatch('getAssessment');
        }
    },

    created() {
        this._loadData();
    }
}
</script>