<template>
<div>
    <v-list>
      <v-list-item-group>
        <template v-for="(item, index) in items">
          <v-list-item 
          :key="item.id"
          @click="messageClick(item.id)">
            <template>
              <v-list-item-content>
                <v-list-item-title v-text="item.name"></v-list-item-title>
                <v-list-item-subtitle v-text="item.subject"></v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-action>
                <v-list-item-action-text>
                    {{_millisToStringDate(item.dateMessage)}}
                </v-list-item-action-text>

                <v-icon v-if="item.isRead">
                  mdi-email-open-outline 
                </v-icon>

                <v-icon v-else>
                  mdi-email
                </v-icon>
              </v-list-item-action>
            </template>
          </v-list-item>

          <v-divider
            v-if="index < items.length - 1"
            :key="index"
          ></v-divider>
        </template>
      </v-list-item-group>
    </v-list>

</div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    name: "Inbox",

    data: () => ({
        messageType: 'MESSAGE',
        countSwipe: '1',
        allStudent: true
    }),

    computed:{
        ...mapGetters({ items: "itemListAnnoucement", selectStudent: "getStudentSelected", switchStudent: "isAllStudent" }),
    },

    watch:{
      switchStudent(newValue) {
        this.allStudent = newValue
        this.loadData()
      },

      selectStudent(newValue) {
        this.loadData()
      }
    },

    created() { 
        this.allStudent = this.switchStudent
        this.loadData()
    },

    methods: {
        _millisToStringDate(millisDate){
            return Date.toDateString(new Date(millisDate), "dd-mm-yyyy")
        },

        loadData (){
            if (this.allStudent){
                 this.$store.dispatch('loadListAnnoucementData', 
                {'messageType':this.messageType,
                'schoolId':this.selectStudent.schoolId,
                'countSwipe':this.countSwipe,
                })
            } else {
                 this.$store.dispatch('loadStudentListAnnoucementData', 
                {'messageType':this.messageType,
                'studentId':this.selectStudent.nim,
                'countSwipe':this.countSwipe,
                })
            }
        },

        messageClick(id) {
            this.$router.push({name: 'ReadInbox', params: { id: id } });
        }
    }

}
</script>