<template>
    <v-menu dark bottom rounded offset-y>
        <template v-slot:activator="{ on }">
          <v-btn icon x-large v-on="on">
            <v-avatar size="24" style="border: 1px solid black;">
                <img
                :src="require('@/assets/' + listLang[langIdx].src)"
                :alt="listLang[langIdx].alt"
                >
            </v-avatar>
          </v-btn>
        </template>
        <v-card :color="themeColor">
          <v-list-item-content class="justify-center">
            <div class="text-center">
                <template v-for="(lang, i) in listLang">
                    <v-btn icon :key="i" @click="_onChangeLanguage(i)">
                        <v-avatar size="24">
                            <img :src="require('@/assets/' + lang.src)" :alt="lang.alt">
                        </v-avatar>
                    </v-btn>
                    <v-divider v-show="i != listLang.length-1" :key="i+'d'" class="my-2"></v-divider>
                </template>
            </div>
          </v-list-item-content>
        </v-card>
      </v-menu>
</template>

<script>
import { SET_IS_LOADING, THEME_COLOR } from '../../config/const.vue'
import LoginApi from '../../services/LoginApi'

export default {
    name: "LanguageSelector",
    data: () => ({
        themeColor: THEME_COLOR,
        listLang: [
            { id: "in", src:  "indonesia-flag.png", alt: "Indonesian"},
            { id: "en", src:  "united-kingdom.png", alt: "English"},
        ],
        langIdx: 0,
    }),
    methods: {
      _onChangeLanguage: async function(i){ 
        this.langIdx = i
        let { id } = this.listLang[this.langIdx]
        if (id == '') return
        this.$store.commit(SET_IS_LOADING, true)
        await LoginApi.changeCurrentLanguage(id)
        this.$store.commit(SET_IS_LOADING, false)
        window.jng.userContext.languageId = id
        naf.lang.changeLanguage(id)
      },
    },
    mounted(){
      try {
        let foundIdx = this.listLang.findIndexObj(languageId, "id")
        this.langIdx = foundIdx < 0 ? 1 : foundIdx
        naf.lang.changeLanguage(this.listLang[this.langIdx].id) 
      } catch (err) {
        // console.log(`err in LangSelector`, err)
      }
    },
}
</script>

<style>

</style>