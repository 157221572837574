<template>
<div class="schedule pa-6">
    <v-container>
      <v-card >
        <v-card-title>{{ selectStudent.name }}</v-card-title>    
        <v-row>
          <v-col
            cols="12"
            lg="6">
            <v-menu
              ref="menu1"
              v-model="menu1"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="auto">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="dateFormatted"
                  label="Start Date"
                  prepend-icon="mdi-calendar"
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="date"
                no-title
                @input="menu1 = false"
              ></v-date-picker>
            </v-menu>
          </v-col>

          <v-col
            cols="12"
            lg="6">
            <v-menu
              v-model="menu2"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="auto">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="computedDateFormatted"
                  label="End Date"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="date2"
                no-title
                @input="menu2 = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
      </v-card>  
   
  </v-container>

  <v-container>
      <v-list dense>
          <v-list-item v-for="item in items" :key="item.id">
              <v-list-item-content>
                  <v-list-item-title v-text="item.genValue+' - '+item.description"></v-list-item-title>
                  <v-list-item-subtitle v-text="item.description"></v-list-item-subtitle>
                  <v-list-item-subtitle v-text="item.note"></v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-action>
                  <v-list-item-subtitle v-text="formatDate(item.dateStart)+' - '+formatDate(item.dateEnd)"></v-list-item-subtitle>
              </v-list-item-action>
          </v-list-item>
      </v-list>
  </v-container>
</div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
    data: vm => ({
      date: new Date().toISOString().substr(0, 10),
      date2: new Date().toISOString().substr(0, 10),
      dateFormatted: vm.formatDate(new Date().toISOString().substr(0, 10)),
      menu1: false,
      menu2: false,
    }),

    computed:{
        computedDateFormatted () {
            return this.formatDate(this.date2)
        },

        ...mapGetters({ items: "itemListShedule", selectStudent: "getStudentSelected" }),
    },

    watch: {
      selectStudent(newValue) {
        this.updateView ()
      },

      date (val) {
        this.dateFormatted = this.formatDate(this.date)
        this.updateView ()
      },

      date2 (val) {
        this.updateView ()
      }

    },

    methods: {
      formatDate (date) {
        if (!date) return null

        const [year, month, day] = date.split('-')
        return `${day}/${month}/${year}`
      },

      updateView () {
          let startDate = this.date
          let endDate = this.date2 

          if(this.datediff(this.parseDate(startDate),this.parseDate(endDate)) >= 0){
              this.$store.dispatch('initSchedule', {startDate, endDate});  
          } else {
              jng.snackError("start date cannot be after the end date")
          }
      },

      parseDate(str) {
          //str => yyyy-mm-dd
          var mdy = str.split('-')
          return new Date(mdy[2], mdy[1]-1, mdy[0])
      },

      datediff(first, second) {
          return Math.round((second-first)/(1000*60*60*24));
      }

    },

    created() {
        this.updateView()
    }
}
</script>