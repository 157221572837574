<template>
<div class="mb-3">
    <v-card>
        <v-row dense>
            <v-col>
                <v-card-title>Absent</v-card-title>
            </v-col>
            <v-col align="right">
                <v-icon @click="alertDialog()">mdi-alert-circle-outline </v-icon>
            </v-col>
        </v-row>
        <v-list dense>
            <v-list-item v-for="(item, j) in items" :key="j" @click="schedule(item)">

                <v-list-item v-for="(child, i) in item.items" :key="i" >
                    <v-list-item-avatar>
                        <v-list-item-subtitle v-text="child.className_J"></v-list-item-subtitle>

                    </v-list-item-avatar>    
                    
                    <v-list-item-content>
                        <v-list-item-subtitle v-text="item.startTime+' - '+item.endTime"></v-list-item-subtitle>
                    
                        <v-list-item-title v-text="child.subjectName_J"></v-list-item-title>

                    </v-list-item-content>

                    <v-list-item-action>
                        <v-btn icon>
                            <v-icon color="grey lighten-1">mdi-menu-right </v-icon>
                        </v-btn>
                    </v-list-item-action>

                </v-list-item>
                
            </v-list-item>
        </v-list>
        <absent-schedule ref="absent_schedule" />
    </v-card>

</div>
</template>

<script>
import { mapGetters } from 'vuex';
import { AppsRightType } from '@/store/enums';

export default {
    name: "AbsentCard",
    data: () => ({
        dialog: false,
    }),

    computed:{
        ...mapGetters({ items: "itemsCurrentScheduleList", selectStudent: "getStudentSelected" }),
    },

    watch:{
        selectStudent: {
            handler: function (newValue, oldValue) {
                this._loadData();
            },
            deep: true
        }
    },

    methods:{
        async alertDialog(){
            if (
            await this.$root.$confirm(
              'Edconnect Info',
              'Please Be Absent No Later Than 15 Minutes After The Start Of The Lesson',
              { color: 'blue', useOkCancel: false, confirmPositive: 'close', })) {}
        },

        schedule(params){
            if(this.$root.$refs.main_component.appsRightFunction(AppsRightType.PARENT_ABLE_TO_ABSENT)){
                this.$refs.absent_schedule.openSchedule(params);
            };
        },

        _loadData() {
            this.$store.dispatch('getScheduleCurrentClassId');
        }
    },

    created() {
        this._loadData();
    }

}
</script>