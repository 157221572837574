<template>
    <div>
        <loading :color="'red'" style="position: relative;" :isScoped="true" :isLoadingScope="isLoading" />
        <div v-for="(payment, i) in dataList" :key="i">
            <div class="py-2 d-flex justify-space-between align-end" @click="toDetail(payment)" >
                <div>
                    <label-text :label="payment.documentDate.toDateString()" :text="setTextLabel(payment)"/>
                    <v-chip x-small :color="isPaid(payment)?'green':'red'" outlined>
                        {{isPaid(payment) ? label.paid : label.remain}}
                    </v-chip>
                </div>
                <div style="font-weight: bold;" class="d-flex align-baseline">
                Rp {{setToCurrency(payment.amount)}}
                </div>
            </div>
            <v-divider />
        </div>
    </div>
  
</template>

<script>
import LabelText from '../base/LabelText.vue'
import { setSelectedInvoiceDetail, SET_IS_LOADING } from '../../config/const.vue'
import Loading from '../base/Loading.vue'

export default {    
    name: "PaymentHistoryList",
    components: { LabelText, Loading },
    props: {
        dataList: {
            type: Array,
            default: () => []
        },
        isInvoice: {
            default: false
        },
    },
    data: () => ({
      label: {
        remain: "Sisa",
        paid: "Lunas",
      },
      isLoading: false
    }),
    computed: {
        textField() {
            return (this.isInvoice ? 'note' : 'documentId')
        },
    },
    methods: {
        setTextLabel(payment) {
            if (payment.isInvoice){
                return payment.note
            } else {
                var str = '';
                for(var i = 0; i < payment.documentIdList.length; i++) {
                    str += payment.documentIdList[i];
                }
                return str;
            }
        },
        isPaid(payment) {
          return !(payment.invoiceRemainAmount > 0)
        },
        setToCurrency(amount) {
          return jng.setToCurrency(amount)
        },
        toDetail(payment) {
            // let { documentId, schoolId } = payment
            let schoolId = payment.schoolId
            let documentId = payment.documentIdList[0]

            if (!documentId) return
            if (this.isInvoice) {
                this.$store.commit(setSelectedInvoiceDetail, payment)
                this.$router.push(`/payment/${btoa(documentId)}/${btoa(schoolId)}`)
            } else {
                this.$router.push(`/payment/${btoa(documentId)}`)
            }
        }
    },

}
</script>