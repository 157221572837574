<script>
export const LANGUAGE_CHANGE = "LanguangeChange"
export const THEME_COLOR = "#1976D2"

export const SET_IS_LOADING = "SET_IS_LOADING"
export const setUserData = "setUserData"
export const setStudentData = "setStudentData"
export const resetState = "resetState"
export const setSelectedInvoiceDetail = "setSelectedInvoiceDetail"
export default {}
</script>
