<template>
<div class="mark pa-6">
    <v-dialog v-model="dialogMark" scrollable max-width="600px">
        <v-card>
            <v-card-title class="headline">{{ mTitle }}</v-card-title>
            <v-list>
                <v-list-group v-for="item in items"
                :key="item.title"
                no-action>

                <template v-slot:activator>
                    <v-list-item-content>
                        <v-list-item-title v-text="item.title"></v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-content>
                        <v-list-item-subtitle v-html="item.mark"></v-list-item-subtitle>
                    </v-list-item-content>
                </template>

                <v-list-item v-for="child in item.items"
                :key="child.title">

                <v-list-item-content>
                    <v-list-item-title v-text="child.title"></v-list-item-title>
                </v-list-item-content>

                <v-list-item-content>
                        <v-list-item-subtitle v-html="child.mark"></v-list-item-subtitle>
                </v-list-item-content>

                </v-list-item>

                </v-list-group>
            </v-list>
        </v-card>    
    </v-dialog>
</div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    data: () => ({
        dialogMark: false,
        mTitle: ''
    }),

    computed:{
        ...mapGetters({ items: "itemsDetailMarkList" }),
    },

    methods: {
        openMark(params) {
            this.dialogMark = true;
            this.mTitle = params.subjectName
            this.$store.dispatch('loadMarkData', params);

        }
    }
}
</script>