jng.lang = jng.coalesce(jng.lang, {});
jng.langMaps = {};
jng.lang.en = { default: {}, sa: {}, digislamic: {}};
jng.lang.en.sa.items = {} 
jng.lang.en.digislamic.items = {}
jng.lang.en.default.items = {
    STUDENT: "Student",
    IDENTITY: {
        NICK_NAME: "Nickname",
        NAME: "Name",
        NISN: "NISN",
        BIRTH_PLACE: "Birth Place",
        BIRTH_DATE: "Birth Date",
        GENDER: "Gender",
        RELIGION: "Religion",
        CHILD_STATUS: "Child Status",
        HANDPHONE: "Phone Number",
    },
    STUDENT_DATA: {
        ADDRESS: "Address",
        PROVINCE: "Province",
        CITY: "City",
        DISTRICT: "District",
        ZIP_CODE: "Postal Code",
        PARENT_GUARDIAN: "Parent/Guardian",
        SIBLING: "Sibling(Same School)",
        FATHER: "Father",
        MOTHER: "Mother",
        GUARDIAN: "Guardian",
    },
    DETAIL_PAYMENT: "Payment Details",
    PAYMENT: "Payment",
    ATTENDANCE_STATUS: {
        ATTEND: "PRESENT",
        ALPHA: "WITHOUT PERMISSION",
        PERMISSION: "PERMISSION",
        SICK: "SICK",
        LATE: "LATE",
        NOT_YET: "NOT PRESENT",
        NO_SCHEDULE: "NO SCHEDULE",
        OUT: "OUT"
    },
    __ID: function(value) {
        return value + " ID";
    },
    __NAME: function(value) {
        return value + " Name";
    },
    __NICK_NAME: function(value) {
        return value + " Nick Name";
    },
    __NAME_ENGLISH: function(value) {
        return value + " Name (2nd)";
    },
    __STATUS: function(value) {
        return value + " Status";
    },
    __TITLE: function(value) {
        return value + " Title";
    },
    __CODE: function(value) {
        return value + " Code";
    },
    __NEW: function(value) {
        return value + " New";
    },
    __NOTE: function(value) {
            return value + " Note";
    },
    __NO: function(value) {
        return value + " No";
    },
    __START: function(value) {
        return "Start " + value;
    },
    __END: function(value) {
        return "End " + value;
    },
    __FIRST: function(value) {
        return value + " First";
    },
    __LAST: function(value) {
        return value + " Last";
    },
    __REPORT_NAME: function(value) {
        return "Name " + value + " Reported";
    },
    __EN: function(value) {
        return value + " (English)";
    },
    __NEW: function(value) {
        return "New " + value
    },
    __EDIT: function(value) {
        return "Edit " + value
    },
    __DELETE: function(value) {
        return "Delete " + value
    },
    __CHECK: function(value) {
        return "Check " + value
    },
    __CONFIRMATION: function(value) {
        return value + " Confirmation" 
    },
    __SAVE_SUCCESS: function(value) {
        return value + " has been saved successfully"
    },
    __CANCEL_SUCCESS: function(value) {
        return value + " has been cancelled successfully"
    },
    __GENERATE_SUCCESS: function(value) {
        return value + " has been generated successfully"
    },
    __CHANGE_SUCCESS: function(value) {
        return value + " has been changed successfully"
    },
    __DELETE_SUCCESS: function(value) {
        return value + " has been deleted successfully"
    },
    __DOWNLOAD_SUCCESS: function(value) {
        return value + " has been downloaded successfully"
    },
    __UPLOAD_SUCCESS: function(value) {
        return value + " has been uploaded successfully"
    },
    __EMAIL_SUCCESS: function(value) {
        return value + " has been emailed successfully"
    },
    __APPROVED: function(value) {
        return value + " has been approved successfully"
    },
    __REJECTED: function(value) {
        return value + " has been rejected successfully"
    },
    __DELETE_CONFIRMATION: function(value, data) {
        if (data == '') {
            return 'Are you sure, you want to delete this ' + (value == '' ? 'data' : value.toLowerCase()) + '?'
        }
        return 'Are you sure, you want to delete "' + data + '" ' + value.toLowerCase() + '?'
    },
    __CLOSE_CONFIRMATION: function(value) {
        return 'Are you sure, you want to close this ' + (value == '' ? 'form' : value) + '?'
    },

    __ID_DUPLICATION: function(value) {
        return "The " + value.toLowerCase() + " with specific ID already exists. Please choose different one."
    },
    __NAME_DUPLICATION: function(value) {
        return "The " + value.toLowerCase() + " with specific NAME already exists. Please choose different one."
    },
    __DUPLICATION: function(value) {
        return "The " + value.toLowerCase() + " is already exists. Please choose different one."
    },
    __ERROR_DATA_TYPE: function(value, type, atRow) {
        return "Please change " + value + ". The value should be " + type
    },
    __ERROR_EMPTY: function(value) {
        return "Please input " + value
    },
    __ERROR_EMPTY_SELECTION: function(value) {
        return "Please select " + value
    },
    __ERROR_MAX_LENGTH: function(value, length) {
        return value + " must be less than " + length + " characters"
    },
    __ERROR_LESS_THEN_TODAY: function(value) {
        return value + " should be greater than today"
    },
    __ERROR_EMPTY_AT_ROW: function(value, atRow) {
        return "Please input " + value + " at row " + atRow
    },
    __ERROR_DATA_TYPE_AT_ROW: function(value, type, atRow) {
        return "Please input " + value + " at row " + atRow + " with a " + type
    },
    __ERROR_DATA_TYPE_WITH_TARGET: function(value, type, target) {
        return "Please change " + value + ". "+target+" should be " + type
    },
    __RESOURCES: function(value) {
        return (value + " Resources").trim()
    },
    __DATE: function(value) {
        return value +" Date";
    },
    __NUMBER: function(value) {
        return value +" Number";
    },

    __CONSIDERATION: function(value) {
        return value + ", do you want to continue ?"
    },
    __DATA: function(value) {
        return value + " Data "
    },
    __ALL: function(value) {
        return "All " + value
    },
    __FIND: function(value) {
        return "Find By " + value
    },
    __PER: function(value) {
        return "Per " + value
    },
}