import ConfigApi from '../services/ConfigApi';
import StudentApi from '../services/StudentApi';
import { AppsRightType } from './enums';
import { setCurrentYearProgram, setStudentMarkSummary, setCheckIsMarkWeighted, SET_IS_LOADING, setJsonStudentMark, setGradeType } from './mutations';

export default {
    state: {
        storeSASchoolOnlyList:['elcmed', 'saBsd', 'salav', 'samed', 'sasby', 'sasen'],
        storeMarkDescList:['Excellent', 'Very Good', 'Good', 'Meeting Expectation', 'Approaching', 'Satisfactory', 'Mediocre', 'Beginning', 'Did Not Meet Requirement'],
        storeMarkColor:['#1C75BC', '#1C75BC', '#1C75BC', '#8DC63F', '#FFF200', '#FFF200', '#FFAB00', '#ED1C24', '#ED1C24'],

        storeMarkSummaryFormulationList: [],
        storeGradeType: '',

        storeCurrentYearProgram: [],
        storeJsonStudentMark: [],
        storeClassMarkSumByStudentId: [],
        storeStudentMarkList: [],
        storePeriodList: [],
        storeTypeList: [],
        storeDetailMarkList: [],

        storeisUsingMarkObservation: false,
        storeisUsingStudentMarkSummary: false,
        storeisUsingSubjectPerKD: false,
        storeisUsingWeightedMark: false
    },
    mutations: {

        setMarkSummaryFormulation(state, payload){
            state.storeMarkSummaryFormulationList= payload
        },

        setCurrentYearProgram(state, payload){
            state.storeCurrentYearProgram = []
            state.storePeriodList = []
            state.storeTypeList = []

            state.storeCurrentYearProgram = payload
            if (payload[0].periodList.length > 0) {
                state.storePeriodList = payload[0].periodList
            }
        },

        setStudentMarkSummary(state, payload){
            state.storeisUsingMarkObservation= payload.isUsingMarkObservation,
            state.storeisUsingStudentMarkSummary= payload.isUsingStudentMarkSummary,
            state.storeisUsingSubjectPerKD= payload.isUsingSubjectPerKD
        },

        setCheckIsMarkWeighted(state, payload){
            state.storeisUsingWeightedMark= payload
        },

        setJsonStudentMark(state, payload){
            state.storeJsonStudentMark.push(payload)
        },

        setGradeType(state, payload){
            state.storeGradeType = payload
        },

        setClassMarkSumByStudentId(state, payload){
            state.storeClassMarkSumByStudentId = []
            state.storeClassMarkSumByStudentId = payload
        },

        setGroupGradeType(state, payload){
            state.storeTypeList = []
            state.storeTypeList = payload
        },

        setAllDataMark(state, payload){
            state.storeStudentMarkList.push(payload)
        },

        setAllDetailMark(state, payload){
            state.storeDetailMarkList.push(payload)
        }
    },
    actions: {
        getStudentMarkFormulation({commit, state}, { schoolId, studentId }) {
            state.storeMarkSummaryFormulationList= []

            if ( state.storeSASchoolOnlyList.indexOf(schoolId) > -1 ){
                StudentApi.getDataStudentMarkFormulationData(schoolId, studentId)
                    .then(({ data: dataFormulation}) => {
                        commit('setMarkSummaryFormulation', dataFormulation)
                    })
                    .catch(err => {
                        console.log(err);
                    })
            }

        },
        
        loadCurrentYearProgram({ commit }, { schoolId, studentId}){
            StudentApi.getYearProgram(schoolId, studentId)
            .then(response => response.data)
            .then(storeCurrentYearProgram => {
                commit(setCurrentYearProgram, storeCurrentYearProgram)
            })
            .catch(err => {
                console.log(err);
            })
            .finally(() => {
                commit(SET_IS_LOADING, false)
            })
        },

        loadStudentMarkSummary({ commit, dispatch, getters }){
            dispatch('clearReportList')
            let apps_right = getters.appsRightList;
            let student_id = getters.getSelectedStudentId;
            let { schoolId } = getters.getStudentSelected;

            if (!apps_right && !student_id) return
            for (var i = apps_right.length - 1; i >= 0; i--) {
                if(apps_right[i].featureName === AppsRightType.GRADE && 
                    apps_right[i].userId === student_id &&
                    apps_right[i].visibility){
                        StudentApi.checkStudentMarkSummary(student_id)
                        .then(({ data: dataMark}) => {
                            commit(setStudentMarkSummary, dataMark)
                        })
                        .catch(err => {
                            console.log(err);
                        })
                    } else if(apps_right[i].featureName === AppsRightType.GRADE_IS_WEIGHTED && 
                        apps_right[i].userId === student_id){
                            commit(setCheckIsMarkWeighted, apps_right[i].visibility)
                        }
            }

            dispatch('getPaymentTypeList')
            dispatch('getJsonStudentMark', { schoolId, student_id })
            dispatch('getClassMarkSumByStudentId', { schoolId, student_id })
        },

        async getPaymentTypeList({ commit }) {
            const { data: gradetype } = await ConfigApi.selectGroupGradeType()
            commit(setGradeType, gradetype)
        },

        getJsonStudentMark({commit, state}, param) {
            commit(SET_IS_LOADING, true)
            StudentApi.getJsonStudentMark(param.schoolId, param.student_id)
                .then(({ data: dataJsonStudentMark }) => {
                    state.storeJsonStudentMark = []
                    for (var index = 0; index < dataJsonStudentMark.length; ++index) {
                        if(dataJsonStudentMark[index].weight > 0.00){
                            commit(setJsonStudentMark, dataJsonStudentMark[index])
                        }
                    }
                })
                .catch(err => {
                    console.log(err);
                })
                .finally(() => {
                    commit(SET_IS_LOADING, false)
                })
        },

        getClassMarkSumByStudentId({commit}, param) {
            StudentApi.getClassMarkSumByStudentId(param.schoolId, param.student_id)
                .then(({ data: dataClassMarkSum }) => {
                    commit('setClassMarkSumByStudentId', dataClassMarkSum)
                })
                .catch(err => {
                    console.log(err);
                })
                .finally(() => {})
        },

        getGroupGradeType({ commit, getters }, param) {

            var i = 0

            let groupstudentmark = []
            let groupgrade = []
            
            for (var index = 0; index < getters.getJsonStudentMark.length; ++index) {
                if (getters.getJsonStudentMark[index].gradePeriodId === param){
                    groupstudentmark.push(getters.getJsonStudentMark[index])
                }
            }

            for (var idx = 0; idx < getters.getGradeType.length; ++idx) {
                if (getters.getGradeType[idx].parentId === param){

                    for (var i = 0; i < groupstudentmark.length; ++i) {
                        if (getters.getGradeType[idx].id === groupstudentmark[i].groupGradeTypeId){
                            groupgrade.push(getters.getGradeType[idx])
                            break
                        }
                    }
                  
                }
            }

            commit('setGroupGradeType', groupgrade)
        },

        showAllDataMark({ commit, state, getters }, param){
            state.storeStudentMarkList = []
            let data = getters.getJsonStudentMark;
            let dataMarkFormulation = getters.getMarkSummaryFormulation;
            let dataMarkColor = getters.getMarkColor
            let isUsingWeightedMark = getters.isUsingWeightedMark

            let periodId = param.gradePeriodId;
            let typeId = param.gradeTypeId;
            var stdMark = 0;

            if (periodId === "" && typeId === "" ) return
            for (var index = 0; index < data.length; ++index){
                if (!(data[index].gradePeriodId === periodId && data[index].groupGradeTypeId === typeId)) continue
                if (isUsingWeightedMark){
                    stdMark = Math.round(data[index].mark / data[index].weight) 
                } else {
                    stdMark = Math.round(data[index].mark) 
                }
                
                var reportMark= ""
                var reportColor= "#ED1C24"
                var reportkkm= ""

                if ( dataMarkFormulation.length > 0) {
                    for ( var idx = 0; idx < dataMarkFormulation.length; ++idx ){
                        if ( dataMarkFormulation[idx].subjectId === data[index].subjectId ){
                            let itemFormula= dataMarkFormulation[idx].items
                            for ( var i = 0; i < itemFormula.length; ++i ){
                                if (itemFormula[i].mark !== 0 ) continue
                                if( !(stdMark >= Math.round(itemFormula[i].minMark) && stdMark <= Math.round(itemFormula[i].maxMark))) continue
                                reportMark= itemFormula[i].reportMark
                                try {
                                    reportColor= dataMarkColor[state.storeMarkDescList.indexOf(itemFormula[i].markDesc)]
                                }
                                catch(err) {
                                    reportColor= "#ED1C24"
                                }
                            }
                        }
                    }
                } else {
                    let clsMark = getters.getClassMarkSumByStudentId;
                    for ( var i = 0; i < clsMark.length; ++i ){
                        if (clsMark[i].classId === data[index].classId && clsMark[i].subjectId === data[index].subjectId){
                            reportkkm = Math.round(clsMark[i].kkm)                            
                            if(stdMark > reportkkm){
                                reportColor= "#8DC63F"
                            } else if(stdMark == reportkkm) {
                                reportColor= "#1C75BC"
                            } else if(stdMark < reportkkm) {
                                reportColor= "#ED1C24"
                            }
                        }
                    }
                }

                let markObject = {
                    textMark: reportMark,
                    mark: (reportkkm === "") ? stdMark : stdMark +' / '+reportkkm,
                    color: reportColor,
                    currMark: data[index].items,
                    studentId: data[index].studentId,
                    gradePeriodId: data[index].gradePeriodId,
                    groupGradeTypeId: data[index].groupGradeTypeId,
                    classId: data[index].classId,
                    schoolId: data[index].schoolId,
                    subjectId: data[index].subjectId,
                    subjectName: data[index].subjectNameInReport
                }

                commit('setAllDataMark', markObject)
            }
        },

        loadMarkData ({ commit, state }, param){
            state.storeDetailMarkList = []
            let data = param.currMark

            for ( var idx = 0; idx < data.length; ++idx ){

                StudentApi.getStudentAssessmentMark(param.studentId, param.gradePeriodId,
                    param.groupGradeTypeId, data[idx].gradeTypeId,
                    param.subjectId, param.classId,
                    param.schoolId, data[idx].subjectActivityId)
                    .then(response => {
                        let itemsData = response.data
                        let itemsMark = []

                        for ( var i = 0; i < itemsData.length; ++i ){
                            let itemObject = {
                                title: itemsData[i].assessmentName_J,
                                mark: Math.round(itemsData[i].mark)
                            }
                            itemsMark.push(itemObject)
                        }

                        for ( var i = 0; i < data.length; ++i ){
                            if (data[i].gradeTypeId === itemsData[0].gradeTypeId){
                                let markDetailObject = {
                                    title: data[i].gradeTypeName_J,
                                    mark: Math.round(data[i].mark),
                                    items: itemsMark
                                }
                                commit('setAllDetailMark', markDetailObject)
                                break
                            }
                        }

                    })
                    .catch(err => {
                        console.log(err);
                    })
            }
        },

        clearReportList ({ state }){
            state.storeJsonStudentMark = []
            state.storeClassMarkSumByStudentId = []
            state.storeStudentMarkList = []
            state.storeTypeList = []
        }
    },

    getters: {
        getCurrentYearProgram: state => state.storeCurrentYearProgram,
        getClassMarkSumByStudentId: state => state.storeClassMarkSumByStudentId,
        getJsonStudentMark: state => state.storeJsonStudentMark,
        getMarkSummaryFormulation: state => state.storeMarkSummaryFormulationList,
        getMarkColor: state => state.storeMarkColor,
        getGradeType: state => state.storeGradeType,

        itemsDetailMarkList: state => state.storeDetailMarkList,
        itemsPeriodList: state => state.storePeriodList,
        itemsTypeList: state => state.storeTypeList,
        itemsStudentMarkList: state => state.storeStudentMarkList,
        
        isUsingMarkObservation:  state => state.storeisUsingMarkObservation,
        isUsingStudentMarkSummary:  state => state.storeisUsingStudentMarkSummary,
        isUsingSubjectPerKD:  state => state.storeisUsingSubjectPerKD,
        isUsingWeightedMark:  state => state.storeisUsingWeightedMark,
        
    }
}