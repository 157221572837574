import Vue from 'vue'
import Vuex from 'vuex'
import UserData from './userStore';
import FinanceData from './financeStore';
import MessageData from './messageStore';
import AppsRightData from './appsRightStore';
import LecturerData from './lecturerStore';
import StudentData from './studentStore';
import AbsentData from './absentStore';
import AssessmentData from './assessmentStore';
import SheduleData from './sheduleStore';
import AttendanceData from './attendanceStore';

import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isLoading: false,
  },
  mutations: {
    SET_IS_LOADING(state, payload){
      state.isLoading = payload
    },
  },
  actions: {},

  modules: {
    UserData,
    FinanceData,
    MessageData,
    AppsRightData,
    LecturerData,
    StudentData,
    AbsentData,
    AssessmentData,
    SheduleData,
    AttendanceData
  },
  plugins: [createPersistedState({
    storage: window.sessionStorage,
  })]
})
