<template>
    <v-select
        v-if="isSelectOnly"
        :items="itemsStudent"
        item-text="name"
        item-value="id"
        :dark="isDark"
        :dense="dense"
        hide-details
        :menu-props="{ top: true, offsetY: true }"
        :label="showLabel ? label.title : undefined"
        :value="selectedStudent"
        @change="changedStudent"
    />
    <v-list-item v-else>
        <v-list-item-content>
            <v-select
                :items="itemsStudent"
                item-text="name"
                item-value="id"
                :dark="isDark"
                :dense="dense"
                :menu-props="{ top: true, offsetY: true }"
                :label="showLabel ? label.title : undefined"
                :value="selectedStudent"
                @change="changedStudent"
            />
        </v-list-item-content>
    </v-list-item>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    name: "StudentSelector",
    props: {
        showLabel: {
            default: true
        },
        isDark: {
            default: false
        },
        dense: {
            default: false
        },
        isSelectOnly: {
            default: false
        },
    },
    data: () => ({
        label: {
            title: "Student Name"
        }
    }),
    methods: {
        changedStudent(selectedStudent) {
            this.$store.dispatch('StudentSelected', selectedStudent)
        },
        _initLabel(){
          this.label.title = naf.lang.get("", "STUDENT__NAME")
        }
    },
    computed:{
    ...mapGetters({ itemsStudent: "itemListStudent", selectedStudent: "getSelectedStudentId"}),
    },
    mounted(){
      naf.lang.onChangeLanguage(() => {
        this._initLabel()
      })
      this._initLabel()
    },
}
</script>

<style>

</style>