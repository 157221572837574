<template>
  <v-card class="overflow-hidden" style="height: 100%;">
    <v-app-bar app color="success" dense dark elevate-on-scroll scroll-target="#scrolling-techniques-7">
        <v-btn icon>
            <v-icon @click.native="goBack">mdi-arrow-left</v-icon>
        </v-btn>

      <v-toolbar-title>{{label.title}}</v-toolbar-title>

      <v-spacer></v-spacer>

    </v-app-bar>
    <v-sheet id="scrolling-techniques-7" class="overflow-y-auto" height="100%" >
      <v-container class="bg-color-success col-white">
        <div class="d-flex justify-center" style="font: small-caption;">Total Payment</div>
        <div class="d-flex justify-center" style="font-size: 1.5rem;">{{paymentSummary}}</div>
      </v-container>
      <v-container>
        <div>Payment is easier with the following methods:</div>
        <!-- <payment-method-card imgName="doku.png" paymentMethod="Doku" :disabled="false"/> -->
        <payment-method-card imgName="card-vc.png" paymentMethod="Virtual Account" :disabled="true"/>
        <!-- <payment-method-card imgName="pintek.jpeg" paymentMethod="Pintek" :disabled="false"/> -->
      </v-container>
    </v-sheet>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import PaymentMethodCard from '@/components/payment/PaymentMethodCard.vue'

export default {
    name: 'PayInvoice',

    components: { PaymentMethodCard },

    props: {
    },

    data: () => ({
      label: {
        title: "Payment",
      },
      status: "PENDING"
    }),

    methods:{
        goBack(){
          this.$router.go(-1)
        },
    },

    computed:{
      ...mapGetters({ paymentSummaryDatas: 'paymentSummaryData' }),
      paymentSummary(){
        if (this.paymentSummaryDatas.length) return this.paymentSummaryDatas[0].invoiceAmount
        return "Rp 0"
      }
    },

    destroyed(){
    },

    mounted(){
    //   naf.lang.onChangeLanguage(() => {
    //     this._initLabel()
    //   })
    //   this._initLabel()
    },
}

</script>

<style lang="scss" scoped>
  $edconnect: #1776CA;
  $success: #4CAF50;
  .bg-color-success{
    background-color: $success;
  }
  .col-white{
    color: white;
  }
</style>