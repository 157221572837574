<template>
<v-card class="pa-6">
    <app-bar :label="label.inbox"  />
    
    <v-sheet
      id="scrolling-techniques-7"
      class="overflow-y-auto"
    >
    <v-container>
        
        <v-card-subtitle class="pb-0">Date: {{_millisToStringDate(data.dateMessage)}}</v-card-subtitle>
        <v-card-subtitle class="pb-0">From: {{ data.fromName }}</v-card-subtitle>
        <v-card-subtitle class="pb-0">To: {{ data.recipients[0].toName }}</v-card-subtitle>
        <v-card-title>Subject: {{ data.subject }}</v-card-title>

        <v-card-text class="text--primary">
            <div>{{ data.bodyMessage }}</div>
        </v-card-text>

        <v-card-subtitle class="pb-0">Attachment file</v-card-subtitle>
        <v-col v-for="(item, i) in data.attachments" :key="i" :cols="6">
          <v-card>
            <v-card-title v-text="item.fileName"></v-card-title>
            <v-card-actions>
            <v-btn icon>
              <v-icon @click.native="_openAttachment(item.fileId, item.fileName, data.id)">mdi-chevron-right</v-icon>
            </v-btn>
          </v-card-actions>
          </v-card>
        </v-col>

        <v-card-actions>
            <v-btn text color="red" @click="confirmClick(data.messageId)">
                Delete
            </v-btn>
        </v-card-actions>

    </v-container>

    </v-sheet>
</v-card>
</template>

<script>
import { mapGetters } from "vuex";
import AppBar from '@/components/base/AppBar.vue'

export default {
    name: 'Inbox',

    components: { AppBar },

    data () {
      return {
        label: {
          inbox: "Open Message"
        },
      }
    },

    computed:{
        ...mapGetters({ data: "getAnnoucementSelected", selectStudent: "getStudentSelected" }),
    },

    created() {
      this.$store.dispatch('selectAnnoucementData', {'Id':this.$route.params.id})
    },

    methods:{
        async confirmClick(id) {
        if (
            await this.$root.$confirm(
              'Edconnect Delete',
              'Are you sure you want to delete this message?',
              { color: 'red' })) {
                  this.$store.dispatch('deleteAnnoucementData', {'messageId':btoa(id), 'schoolId':btoa(this.selectStudent.schoolId,)})

                  this.$router.go(-1)

              }
        },

        _millisToStringDate(millisDate){
            return Date.toDateString(new Date(millisDate), "dd-mm-yyyy")
        },

        _openAttachment(fileId, fileName, assessmentId){
          this.$router.push({name: 'AttachmentView', params: { fileId: fileId, fileName: fileName, assessmentId: assessmentId }});
        },

    }

}

</script>