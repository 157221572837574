import { Api } from "./RestResource";

export class AppsRightApi {
    static getAppsRightMenuBySchoolIdAndStudentId(schoolId, studentId) {
        return Api.get(`apps-right/parent/menu/${schoolId}/${studentId}`)
    }

    static getAppsRightList(){
        return Api.get("apps-right/parent/")
    }
}