<template>
<!-- <div class="mark pa-6"> -->
    <v-dialog class="mark pa-6" v-model="dialogSchedule" scrollable max-width="600px">
        <v-card>
            <v-card-title class="headline grey lighten-2">{{ title }}</v-card-title>
            <v-card-subtitle>{{ teacher }}</v-card-subtitle>
            <v-card-subtitle>{{ startTime+' - '+endTime }}</v-card-subtitle>
            <v-radio-group v-model="absentSelect" row>
                <v-radio 
                v-for="{value, label} in absentOptions"
                :key="value"
                :value="value"
                :label="label"/>
            </v-radio-group>
            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" @click.native="saveabsent" :disabled="disabled">Save</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
<!-- </div> -->
</template>

<script>
import { mapGetters } from "vuex";

export default {
    data: () => ({
        absentSelect: 'ATTEND',
        absentOptions: [
            {label: 'ATTEND', value: 'ATTEND'},
            {label: 'SICK', value: 'SICK'},
            {label: 'PERMISSION', value: 'PERMISSION'},
            {label: 'ALPHA', value: 'ALPHA'},
        ],
        dialogSchedule: false,
        title: '',
        teacher: '',
        meetingDate: '',
        startTime: '',
        endTime: '',
        disabled: false
    }),

    computed:{
        ...mapGetters({ getAbsent: "statusAbsent", dataAbsent: "absentSchedule" }),
    },

    watch:{
        getAbsent: {
            handler: function (newValue, oldValue) {
                this.absentSelect = newValue
            },
            deep: true
        }
    },

    methods: {
        formatTime (tm) {
            var dt = new Date(this.meetingDate)
            var s =  tm.split(':');
            return new Date(dt.getFullYear(), dt.getMonth(), dt.getDate(),
            parseInt(s[0]), parseInt(s[1]), parseInt('00'));
        },
        
        openSchedule(params) {
            this.dialogSchedule = true;
            this.title = params.items[0].subjectName_J
            this.teacher = params.lecturerName_J
            this.meetingDate = params.meetingDate
            this.startTime = params.startTime
            this.endTime = params.endTime
            this.$store.dispatch('loadScheduleData', params);
            this.disabled = (new Date().getTime() - this.formatTime(this.startTime).getTime()) > 960000
        },

        saveabsent(){
            let absentStatus= this.absentSelect
            let data= this.dataAbsent
            let dataSource= this.dataAbsent.source

            if (dataSource.shiftId === "01"){
                this.$store.dispatch('submitSaveAllPerShift', {absentStatus, data});
            } else {
                this.$store.dispatch('submitSavePerShift', {absentStatus, data});
            }

            this.dialogSchedule = false;
        }
    }
}
</script>