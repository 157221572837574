export const loadStudentByParent = "loadStudentByParent"
export const getPaymentSummary = "getPaymentSummary"
export const getPaymentHistory = "getPaymentHistory"
export const getCountPendingPayment = "getCountPendingPayment"
export const getAppsRightMenu = "getAppsRightMenu"
export const getAppsRightList = "getAppsRightList"

export const StudentSelected = "StudentSelected"

export const setSelectedLecturerByClass = "setSelectedLecturerByClass"

export const loadCurrentYearProgram = "loadCurrentYearProgram"
export const loadStudentMarkSummary = "loadStudentMarkSummary"
export const getStudentMarkFormulation = "getStudentMarkFormulation"

export const getScheduleCurrentClassId = "getScheduleCurrentClassId"

export const getAssessment = "getAssessment"

export const clearReportList = "clearReportList"
