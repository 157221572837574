<template>
  <div class="home pa-2">
    <student-dashboard-card v-model="studentData" :absent="absent" @studentPage="_studentPage"/>

    <div v-if="finnace" >
      <payment-summary-card  />
    </div>

    <div v-if="parent_schedule" >
      <absent-card />
    </div>

    <div v-if="assessment" >
      <assessment-card />
    </div>

  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { AppsRightType } from '../store/enums';
import StudentDashboardCard from '@/components/student/StudentDashboardCard.vue';
import PaymentSummaryCard from '@/components/payment/PaymentSummaryCard.vue';
import AbsentCard from '../components/absent/AbsentCard.vue';
import AssessmentCard from '@/components/assessment/Assessment.vue';
import LoginApi from '../services/LoginApi';
import { getDontRemindMeChangePassword } from '../helpers/getRemindMeChangePassword';
import { setUserData } from '../config/const.vue';
export default {
  name: 'Home',
  components: {StudentDashboardCard, PaymentSummaryCard, AbsentCard, AssessmentCard},
  data: () => ({
    finnace: true,
    absent: false,
    parent_schedule: false,
    assessment: false,
    studentId: '',
    itemStudent: null,
    studentData: '',
  }),

  computed:{
    ...mapGetters({ selectStudent: "getStudentSelected"}),
  },

  watch:{
    selectStudent: {
      handler: function (newValue, oldValue) {
        this.updateData(newValue)
      },
      deep: true
    },

  },
  methods:{
    _studentPage: function(data) {
      this.$router.push({name: 'Student Data', params: { studentid: this.studentId } });
    },
    async updateData (payload) {
      if (!payload.nim){
        // jng.snackError("Error Data Processing Failed")
      } else {
        this.studentData = payload;
        this.studentId = payload.nim;
        this._updateView();
      }
    },

    _updateView() {
      this.appsRight = this.$store.getters.appsRightList
      this.finnace = this.$root.$refs.main_component.appsRightFunction(AppsRightType.FINANCE);
      this.absent = this.$root.$refs.main_component.appsRightFunction( AppsRightType.ABSENT);
      this.parent_schedule = this.$root.$refs.main_component.appsRightFunction(AppsRightType.SHOW_SCHEDULE);
      this.assessment = this.$root.$refs.main_component.appsRightFunction(AppsRightType.ASSESSMENT);
    },

    checkIsNeedPassword(){
      LoginApi.checkIfAlreadyLogin()
        .then(({ data }) => {
          this.$store.commit(setUserData, data)
          if(
            data.isNeedChangePassword
            && !naf.isPassChangePassword
            && !getDontRemindMeChangePassword()
          ) {
            this.$router.push({ name: "ChangePassword" })
          }
        })
        .catch(err => {
          console.log(err) 
        })
    },

  },

  created() {
      this.updateData(this.selectStudent);
      this.checkIsNeedPassword();
  },
}
</script>

<style lang="scss">
  div[data-app='true'] {
    background:rgba(45,85,255) !important;
    background-size: cover !important;
  }
</style>
