<template>
    <v-app-bar app color="primary" dense dark elevate-on-scroll scroll-target="#scrolling-techniques-7">
        <v-btn icon>
            <v-icon @click.native="_backHome">mdi-arrow-left</v-icon>
        </v-btn>

        <v-toolbar-title>{{label}}</v-toolbar-title>

      <v-spacer></v-spacer>
    </v-app-bar>
</template>

<script>

export default {
    name: "AppBar",
    props: {
        label: { default: "" },
        valueBack: { default: "" }
    },

    methods:{
        _backHome(){
            if (this.valueBack === ""){
                this.$router.go(-1)
            } else {
                this.$router.push({ name: this.valueBack })
            }
        },
    }
}

</script>