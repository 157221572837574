<template>
<div class="indentity-tab">
  <v-row>
    <v-col cols="6" v-for="identity in identityList" :key="identity.field">
      <v-row class="mt-2 align-center">
        <v-col cols="3" sm="1">
            <v-icon>{{identity.icon}}</v-icon>
        </v-col>
        <v-col cols="9" sm="5">
          <div class="label-small">
            {{label[identity.field]}}
          </div>
          <div v-if="identity.isDate">
              {{_millisToStringDate(items[identity.field])}}
          </div>
          <div v-else>
            {{items[identity.field] ? items[identity.field] : " - " }}
          </div>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
  <v-row>
    <v-col cols="6">
      <v-row class="mt-2 align-center">
        <v-col cols="3" sm="1">
            <v-icon>mdi-account-child-outline</v-icon>
        </v-col>
        <v-col cols="9" sm="6">
          <label-text :label="label.childStatus" :text="items.childOrder+' / '+items.childTotal" />
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</div>
</template>

<script>
import { mapGetters } from "vuex";
import ConfigApi from '../../services/ConfigApi';
import LabelText from '../base/LabelText.vue';

export default {
    name: 'Identity',
    components: {
        LabelText
    },
    data:() => ({
      label: {
        nickName : "Nickname",
        nisn : "NISN",
        birthPlace : "Birth Place",
        DOB : "Birth Date",
        genderName : "Gender",
        religionName : "Religion",
        childStatus : "Child Status",
      },
      identityList: [
        { icon: "mdi-account-outline", field: "nickName" },
        { icon: "mdi-alpha-n-box-outline", field: "nisn" },
        { icon: "mdi-cake", field: "birthPlace" },
        { icon: "mdi-cake-layered", field: "DOB", isDate: true },
        { icon: "mdi-gender-male-female", field: "genderName" },
        { icon: "mdi-airballoon", field: "religionName" },
      ]
    }),
    methods: {
      _millisToStringDate(millisDate){
        return Date.toDateString(new Date(millisDate), "dd-mm-yyyy")
      },
      _initLabel(){
        this.label.nickName = naf.lang.get("", "IDENTITY.NICK_NAME")
        this.label.nisn = naf.lang.get("", "IDENTITY.NISN")
        this.label.birthPlace = naf.lang.get("", "IDENTITY.BIRTH_PLACE")
        this.label.DOB = naf.lang.get("", "IDENTITY.BIRTH_DATE")
        this.label.genderName = naf.lang.get("", "IDENTITY.GENDER")
        this.label.religionName = naf.lang.get("", "IDENTITY.RELIGION")
        this.label.childStatus = naf.lang.get("", "IDENTITY.CHILD_STATUS")
      }
    },

    mounted(){
      naf.lang.onChangeLanguage(() => {
        this._initLabel()
      })
      this._initLabel()
    },

    computed:{
    ...mapGetters({ items: "itemsIdentity"}),
    },

}
</script>

<style lang="scss">
  .label-small{
    font-size: 12px;
    color: rgba(0,0,0,0.6);
  }
</style>