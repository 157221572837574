<template>
<v-card
    class="overflow-hidden mx-auto"
    height="200"
    max-width="500"
  >
  <v-bottom-navigation
    fixed
    grow
    v-model="value"
    color="primary"
  >
    <v-btn value="recent" :to="{ path: '/home'}">
      <span>Dashboard</span>

      <v-icon>mdi-view-dashboard</v-icon>
    </v-btn>

    <v-btn value="report" :to="{ path: '/report'}">
      <span>Report Card</span>

      <v-icon>mdi-file-chart </v-icon>
    </v-btn>

    <v-btn value="schedule" :to="{ path: '/schedule'}">
      <span>Schedule</span>

      <v-icon>mdi-calendar-month-outline </v-icon>
    </v-btn>

    <!-- <v-btn value="announcement" :to="{ path: '/announcement'}">
      <span>Announcement</span>

      <v-icon>mdi-bell</v-icon>
    </v-btn> -->

    <v-btn value="Message" :to="{ path: '/message'}">
      <span>Message</span>

      <v-icon>mdi-email</v-icon>
    </v-btn>
  </v-bottom-navigation>
  
  </v-card>
  
</template>

<script>
  export default {
    data: () => ({ value: 'recent' }),
  }
</script>