import AbsentApi from '../services/AbsentApi'

function groupBy(xs) {
    return xs.reduce((r, { absentDate: name, ...object }) => {
        var temp = r.find(o => o.name === name);
        if (!temp) r.push(temp = { name, children: [] });
        temp.children.push(object);
        return r;
    }, []);
};

export default {
    state: {
        storedAttendanceData:[],
        storedSelectAttendance:'',
        storedMonthlyAttendance:[],
    },

    mutations: {
        setMonthlyAttendance(state, payload){
            state.storedMonthlyAttendance = []
            state.storedMonthlyAttendance = payload
        },

        setAttendance(state, payload){
            state.storedAttendanceData = []
            state.storedAttendanceData = payload
        },

        setSelectAttendance(state, payload){
            state.storedSelectAttendance = payload
        }
    },

    actions: {
        setMonthlyAttendance({commit, getters}, param){
            let absentUsingShift = getters.absentUsingShift
            let student_id = getters.getSelectedStudentId;
            let { schoolId } = getters.getStudentSelected;

            AbsentApi.getMonthlyAttendance(schoolId, student_id, param, absentUsingShift)
            .then(response => response.data)
            .then(storedMonthlyAttendance => {
                commit('setMonthlyAttendance', groupBy(storedMonthlyAttendance))
            })
            .catch(err => {
                console.log(err);
            })
        },

        selectAttendance({commit, getters}, param){
            let data = getters.getAttendance
            let year =  param.year
            let month =  param.month.replace(/^0+/, '')

            commit('setSelectAttendance', '')

            for (var i = 0; i < data.length; ++i){
                if ( data[i].year == year && data[i].month == month ){
                    commit('setSelectAttendance', data[i])
                }
            }
        },

        getSummarizedAbsent({commit, getters, dispatch}, param){
            let student_id = getters.getSelectedStudentId;
            let { schoolId } = getters.getStudentSelected;

            if (getters.absentUsingShift) {
                AbsentApi.summarizedCurrentStudentAbsentPerMonth(schoolId, student_id)
                .then(response => {
                    commit('setAttendance', response.data)
                    dispatch('selectAttendance', param)
                })
                .catch(err => {
                    console.log(err);
                })
            } else {
                AbsentApi.summarizedCurrentHomeroomStudentAbsentPerMonth(schoolId, student_id)
                .then(response => {
                    commit('setAttendance', response.data)
                    dispatch('selectAttendance', param)
                })
                .catch(err => {
                    console.log(err);
                })
            }

        }
    },

    getters: {
        getAttendance: state => state.storedAttendanceData,
        getSelectAttendance: state => state.storedSelectAttendance,
        getMonthlyAttendance: state => state.storedMonthlyAttendance,
    },
}