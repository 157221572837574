<template>
    <v-progress-linear
        v-if="isScoped"
        :active="isLoadingScope"
        indeterminate
        absolute
        :color="color"
    />
    <v-progress-linear
        v-else
        :active="isLoading"
        indeterminate
        absolute
        :color="color"
    />
</template>

<script>
export default {
    name: "Loading",
    props: {
        color: {
            default: "white"
        },
        isScoped: {
            default: false
        },
        isLoadingScope: {
            default: false
        }
    },
    computed: {
      isLoading(){
        return this.$store.state.isLoading
      }
    },
}
</script>

<style>

</style>