export const SET_IS_LOADING = "SET_IS_LOADING"
export const setUserData = "setUserData"
export const setUserContext = "setUserContext"
export const setStudentData = "setStudentData"
export const resetState = "resetState"
export const setStudentSelected = "setStudentSelected"
export const setAnnoucementData = "setAnnoucementData"
export const setPaymentSummary = "setPaymentSummary"
export const setPaymentHistory = "setPaymentHistory"
export const setAnnoucementSelected = "setAnnoucementSelected"
export const setSelectedInvoiceDetail = "setSelectedInvoiceDetail"
export const setIsAllStudent = "setIsAllStudent"
export const setOutboxData = "setOutboxData"
export const setDraftData = "setDraftData"
export const setAppsRightConfig = "setAppsRightConfig"
export const setLecturerData = "setLecturerData"
export const setOutboxSelected = "setOutboxSelected"
export const setDraftSelected = "setDraftSelected"
export const setIsPassChangePassword = "setIsPassChangePassword"

// student
export const setCurrentYearProgram = "setCurrentYearProgram"
export const setStudentMarkSummary = "setStudentMarkSummary"
export const setCheckIsMarkWeighted = "setCheckIsMarkWeighted"
export const setJsonStudentMark = "setJsonStudentMark"
export const setGradeType = "setGradeType"
