import ScheduleApi from '../services/ScheduleApi'
import { AttendanceStatus } from './enums';


function absentObj(param) {
    let data= param.data
    let dataSource= data.source

    let paramObject= {
        classId: dataSource.classId,
        classScheduleId: dataSource.classScheduleId,
        id: data.id,
        items: [],
        lecturerId: dataSource.lecturerId,
        lecturerReplacementId: dataSource.lecturerReplacementId,
        lecturerReplacementName_J: dataSource.lecturerReplacementName_J,
        meetingDate: dataSource.meetingDate,
        meetingStatus: dataSource.meetingStatus,
        note: "",
        schoolId: dataSource.schoolId,
        shiftId: dataSource.shiftId,
        shiftTemplateId: dataSource.shiftTemplateId,
        teachingMaterial: dataSource.teachingMaterial,
        yearProgramId: dataSource.yearProgramId
    }

    let item= {
        absentStatus: param.absentStatus,
        late: 0,
        note:"",
        studentId: data.items[0].studentId,
        studentName_J: data.items[0].studentName
    } 

    paramObject.items.push(item)

    return paramObject
}

export default {
    state: {
        storeScheduleCurrent: [],
        storeAbsentSchedule:{},
        storeAbsentStatus:"",
        absentUsingShift: false,
    },

    mutations: {
        setCurrentSchedule(state, payload){
            state.storeScheduleCurrent= []
            state.storeScheduleCurrent= payload
        },

        setCurrentAbsent(state, payload){
            state.storeAbsentSchedule= payload
        },
        
        setStatusAbsent(state, payload){
            state.storeAbsentStatus= payload
        },

        setAbsentUsingShift(state, payload){
            state.absentUsingShift= payload
        },
    },

    actions: {
        getScheduleCurrentClassId({commit, getters}, param) {
            let { schoolId, classId } = getters.getStudentSelected;
            ScheduleApi.getDateScheduleByCurrentClassId(new Date().getTime(), schoolId, classId)
            .then(({ data: dataSchedule }) => {
                commit('setCurrentSchedule', dataSchedule)
            })
            .catch(err => {
                console.log(err);
            })
        },

        submitSavePerShift({commit}, param) {
            ScheduleApi.savePerShift(absentObj(param))
            .then(({ data: dataResponse }) => {
                
            })
            .catch(err => {
                console.log(err);
                if (err.response) console.log("err", err.response);
                jng.snackError(err.response.data.message)
            })
        },

        submitSaveAllPerShift({commit, getters}, param) {
            let allObjShift = []
            let _dataTmp = param.data
            
            let _dataItems = _dataTmp.items[0]
            let _dataSource = _dataTmp.source

            let absentStatus= param.absentStatus
            let schedules= getters.itemsCurrentScheduleList

            for (var i = 0; i < schedules.length; ++i){
                let objSource= {
                    id: _dataSource.id,
                    schoolId: _dataSource.schoolId,
                    yearProgramId: _dataSource.yearProgramId,
                    classScheduleId: _dataSource.classScheduleId,
                    classId: _dataSource.classId,
                    shiftTemplateId: schedules[i].shiftTemplateId,
                    shiftId: schedules[i].shiftId,
                    meetingDate: schedules[i].meetingDate,
                    lecturerId: schedules[i].lecturerId,
                    lecturerReplacementId: "",
                    lecturerReplacementName_J: "",
                    teachingMaterial: _dataSource.teachingMaterial,
                 
                }
                
                let objItems= {
                    studentAbsence: AttendanceStatus.ATTEND,
                    lateCount: 0,
                    studentNotes: "",
                    meetingDate: schedules[i].meetingDate,
                    shiftTemplateId: schedules[i].shiftTemplateId,
                    shiftId:  schedules[i].shiftId,
                    studentId: _dataItems.studentId,
                    studentName: _dataItems.studentName_J,
                }
                            
                let data= {
                    id: _dataSource.id,
                    note: "",
                    material: "",
                    items: [objItems],
                    source: objSource,
                }

                allObjShift.push(absentObj({ absentStatus, data }))
            }

            ScheduleApi.saveAllShift(allObjShift)
            .then(({ data: dataResponse }) => {
                
            })
            .catch(err => {
                console.log(err);
                if (err.response) console.log("err", err.response);
                jng.snackError(err.response.data.message)
            })

        },

        loadScheduleData({ commit, getters, dispatch }, param) {
            let { schoolId, classId, nim } = getters.getStudentSelected;
            let itemsSchedule = param.items;
            let subjectId = itemsSchedule[0].subjectId

            let todaya_date = onlyDate(new Date());

            function onlyDate(date) {
                var d = new Date(date),
                    month = '' + (d.getMonth() + 1),
                    day = '' + d.getDate(),
                    year = d.getFullYear();
            
                if (month.length < 2) 
                    month = '0' + month;
                if (day.length < 2) 
                    day = '0' + day;
            
                return [year, month, day].join('-');
            }

            ScheduleApi.getAbsentDailySchedule(schoolId,
                itemsSchedule[0].yearProgramId, param.lecturerId,
                new Date(todaya_date+" 00:00:00").getTime(), classId,
                param.shiftTemplateId, param.shiftId, nim)
            .then(({ data: dataAbsent }) => {
                let itemsAbsent = dataAbsent.items;
                if (itemsAbsent.length <= 0){
                    dispatch('selectStudentByClassSubjectAndStudentId', { dataAbsent, classId, subjectId, nim, schoolId })
                } else {
                    let objDataAbsent = dataAbsent
                    objDataAbsent.classId= classId

                    let AbsentModel = {
                        id: dataAbsent.id,
                        note: dataAbsent.note,
                        material: dataAbsent.teachingMaterial,
                        source: objDataAbsent,
                        items: []
                    }

                    for (var i = 0; i < itemsAbsent.length; ++i){
                        let resultItem = {
                            studentAbsence: itemsAbsent[i].AbsentStatus,
                            lateCount: itemsAbsent[i].late,
                            studentNotes: itemsAbsent[i].note,
                            meetingDate: dataAbsent.meetingDate,
                            shiftTemplateId: dataAbsent.shiftTemplateId,
                            shiftId: dataAbsent.shiftId,
                            studentId: itemsAbsent[i].studentId,
                            studentName: itemsAbsent[i].studentName_J,
                        }

                        AbsentModel.items.push(resultItem);
                    }

                    commit('setStatusAbsent', itemsAbsent[0].absentStatus)
                    commit('setCurrentAbsent', AbsentModel)
                }
            })
            .catch(err => {
                console.log(err);
            })
        },

        selectStudentByClassSubjectAndStudentId({ commit }, param) {
            let objDataAbsent = param.dataAbsent
            objDataAbsent.classId= param.classId

            let AbsentModel = {
                id: "",
                note: "",
                material: "",
                source: objDataAbsent,
                items: []
            }
            ScheduleApi.selectStudentByClassSubjectAndStudentId(param.classId, param.subjectId, param.nim, param.schoolId)
            .then(({ data: classData }) => {
                for (var i = 0; i < classData.length; ++i){

                    let resultItem = {
                        studentAbsence: AttendanceStatus.ATTEND,
                        lateCount: 0,
                        studentNotes: "",
                        meetingDate: objDataAbsent.meetingDate,
                        shiftTemplateId: objDataAbsent.shiftTemplateId,
                        shiftId: objDataAbsent.shiftId,
                        studentId: classData[i].studentId,
                        studentName: classData[i].studentName_J,
                    }

                    AbsentModel.items.push(resultItem);
                }
                commit('setStatusAbsent', AttendanceStatus.ATTEND)
                commit('setCurrentAbsent', AbsentModel)
            })
            .catch(err => {
                console.log(err);
            })
        }
    },

    getters: {
        itemsCurrentScheduleList: state => state.storeScheduleCurrent,
        absentSchedule: state => state.storeAbsentSchedule,
        statusAbsent: state => state.storeAbsentStatus,
        absentUsingShift: state => state.absentUsingShift
    },
}