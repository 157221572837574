import "../scss/main.scss"

import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import "./assets/scss/_mixin.scss";
import "./config/firebase"
import "./plugins/jng"
import "./plugins/lang-en"
import "./plugins/lang-in"
import "./plugins/naf"
import { setupComponents } from './config/setup-components';

setupComponents(Vue);
Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  components: { App },
  render: h => h(App)
}).$mount('#app')
