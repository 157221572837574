<template>
  <v-card class="overflow-hidden height-100">
    <v-app-bar
      app
      color="white"
      dense
      elevate-on-scroll
      scroll-target="#scrolling-techniques-7"
    >
        <v-btn icon v-if="!isForceChange" >
            <v-icon @click.native="backHome">mdi-arrow-left</v-icon>
        </v-btn>

      <!-- <v-toolbar-title>{{label.changePassword}}</v-toolbar-title> -->

      <v-spacer></v-spacer>

    </v-app-bar>
    <v-sheet
      id="scrolling-techniques-7"
      class="overflow-y-auto"
    >
      <v-container color="basil">
          <div class="home">
            <v-container color="basil">
              <h2>Change Password</h2>
            </v-container>
            <v-form v-model="valid" @submit.prevent="_submit">
              <v-container>
                <v-row>
                  <v-col
                    cols="12"
                    md="4"
                  >
                    <v-text-field
                      v-model="newPassword"
                      :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                      label="New Password"
                      :type="show1 ? 'text' : 'password'"
                      hint="At least 6 characters"
                      :rules="passRules"
                      @click:append="show1 = !show1"
                      required
                    ></v-text-field>
                  </v-col>

                  <v-col
                    cols="12"
                    md="4"
                  >
                    <v-text-field
                      v-model="confirmPassword"
                      label="Confirm New Password"
                      :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                      :type="show2 ? 'text' : 'password'"
                      @click:append="show2 = !show2"
                      :rules="confirmRules"
                      required
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-btn type="submit" class="mt-5" block color="primary" :disabled="!valid">
                  Change Password
                </v-btn>

                <v-checkbox
                  v-if="isForceChange"
                  v-model="dontRemindMe"
                  label="Don't remind me again."
                ></v-checkbox>

                <v-btn v-if="isForceChange" block color="secondary" @click="skipChangePassword" >
                  Lewati
                </v-btn>
              </v-container>
            </v-form>
          </div>
      </v-container>
    </v-sheet>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import { SET_IS_LOADING } from '@/config/const.vue';
import { getDontRemindMeChangePassword, setDontRemindMeChangePassword } from '@/helpers/getRemindMeChangePassword';
import LoginApi from '@/services/LoginApi';

export default {
    name: 'ChangePassword',

    data () {
      return {
        label: {
          changePassword: "Change Password"
        },
        valid: false,
        newPassword: "",
        confirmPassword: "",
        show1: false,
        show2: false,
        dontRemindMe: false,
        passRules: [
          v => !!v || 'Password is required', 
          v => v.length > 5 || 'At least 6 characters'
        ],
        confirmRules: [
          v => v == this.newPassword || 'Confirm Password did not match',
          v => !!v || 'Confirm Password is required',
        ],
      }
    },

    computed:{
    ...mapGetters({ student_name: "getStudentName"}),
    },

    async created() {
      if (!jng.user) {
        const { data } = await LoginApi.checkIfAlreadyLogin()
        jng.user = data
      }
    },

    mounted(){
      naf.lang.onChangeLanguage(() => {
        this._initLabel()
      })
      this._initLabel()
    },

    methods:{
        backHome(){
            this.$router.push({ name: 'Home' })
        },
        async _submit(){
          console.log('submit', this.valid)
          if(!this.valid) return
          try {
            this.$store.commit(SET_IS_LOADING, true)
            await LoginApi.changePassword(this.newPassword)
            this.setDontRemindMe()
            naf.snackSuccess("Change Passsword success")
            this.$router.push('/home')
          } catch (err) {
            console.log(err.response)
          } finally {
            this.$store.commit(SET_IS_LOADING, false)
          }

        },
        skipChangePassword(){
          this.setDontRemindMe()
          naf.isPassChangePassword = true
          this.$router.push({ name: 'Home' })
          
        },
        setDontRemindMe(){
          if (this.dontRemindMe) setDontRemindMeChangePassword('yes')
        },
        
        _initLabel(){
          // this.label.changePassword = naf.lang.get("", "STUDENT__DATA")
        }
    },
    computed: {
      isForceChange(){
        if (!jng.user) return false
        if (
            jng.user.isNeedChangePassword
            && !naf.isPassChangePassword
            && !getDontRemindMeChangePassword()
          ) return true
        return false
      },
    },
}

</script>
<style>
.height-100{
  height: 100%;
}
.basil {
  background-color: #FFFBE6 !important;
}
.basil--text {
  color: #356859 !important;
}
</style>