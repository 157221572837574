<template>
  <v-card class="overflow-hidden" style="height: 100%;">
    <app-bar :label="invoiceId"  />

    <v-sheet id="scrolling-techniques-7" class="overflow-y-auto" height="100%" >
      <v-container >
        <v-row class="mb-1">
          <v-col v-for="(invField, i) in invoiceFields" :key="i" :cols="invField.col">
            <label-text 
              :label="label[invField.field]" 
              :text="_formatValue(selectedInvoiceDetail[invField.field], invField)" 
            />
            <v-divider />
          </v-col>
        </v-row>
        <v-divider  />
        <v-divider  />
        <v-row class="my-1 payment-box" v-for="(payment, j) in invoiceDetails" :key="j">
          <v-col v-for="(pmtField, i) in paymentsFields" :key="i" :cols="pmtField.col">
            <label-text 
              :label="label[pmtField.field]" 
              :text="_formatValue(payment[pmtField.field], pmtField)" 
            />
            <v-divider />
          </v-col>
        </v-row>
      </v-container>
    </v-sheet>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import { setSelectedInvoiceDetail, SET_IS_LOADING } from '../../config/const.vue'
import LabelText from '@/components/base/LabelText.vue'
import AppBar from '@/components/base/AppBar.vue'
import ConfigApi from '../../services/ConfigApi'
import { StatusType } from '@/store/enums';

export default {
    name: 'InvoiceDetail',

    components: { AppBar, LabelText },

    data: () => ({
      label: {
        title: "Invoice Detail",
        amount: "Jumlah Tagihan",
        invoiceRemainAmount: "Sisa Tagihan",
        invTypeNameInParent: "Tipe Invoice",
        documentDate: "Tanggal Invoice",
        dueDate: "Jatuh Tempo",
        note: "Note",
        discount: "Disc",

        id: "ID Pembayaran",
        paymentType: "Tipe Pembayaran",
        paymentDate: "Tanggal Pembayaran",
        paymentAmount: "Jumlah Pembayaran",
        penalties: "Denda",
      },
      invoiceFields: [
        { field: "invoiceRemainAmount", isAmount: true, col: 12 },
        { field: "amount", isAmount: true, col: 6 },
        { field: "discount", col: 6 },
        { field: "invTypeNameInParent", col: 12 },
        { field: "documentDate", isDate: true, col: 6 },
        { field: "dueDate", isDate: true, col: 6 },
        { field: "note", col: 12 },
      ],
      paymentsFields: [
        { field: "id", col: 12 },
        { field: "paymentType", col: 6, isPaymentType: true },
        { field: "paymentDate", isDate: true, col: 6 },
        { field: "paymentAmount", isAmount: true, col: 6 },
        { field: "penalties", isAmount: true, col: 6 },
      ],
      paymentTypeList: [],
      invoiceDetails: [],
      invoiceIdList: [],
      dueDate: 0,
    }),

    methods:{

        async _getPaymentTypeList() {
          let { data } = await ConfigApi.selectPaymentType()
          this.paymentTypeList = data
        },
        
        _formatValue(value, invField){
          if (invField.field == 'dueDate') value = this.dueDate
          if (jng.isUndefined(value)) return " - "
          if (invField.isDate) return value.toDateString()
          if (invField.isAmount) return `Rp ${jng.setToCurrency(value)}`
          
          if (invField.isPaymentType) {
            let paymentTypeObj = this.paymentTypeList.findFirst(value, "id")
            if (paymentTypeObj) return paymentTypeObj.value
          }
          return value
        },
        _initLabel(){
          // this.label.title = naf.lang.get("", "PAYMENT")
        },
    },

    computed:{
      ...mapGetters({selectedInvoiceDetail: 'selectedInvoiceDetail'}),
      invoiceId(){ return this.selectedInvoiceDetail.invTypeNameInParent },
      schoolId(){ return atob(this.$route.params.schoolId) },
    },

    created() {
      if (!this.selectedInvoiceDetail.documentIdList[0]) return this.$router.push({ name: "Payment" })
      this.invoiceIdList = this.selectedInvoiceDetail.documentIdList;
      this._getPaymentTypeList()
      // this._getInvoiceDetail()

      this.$store.dispatch('getInvoiceDetail').then(res => {
            if (res != StatusType.FAILURE){
              this.invoiceDetails = res
            }
          })
    },

    mounted(){
      naf.lang.onChangeLanguage(() => {
        this._initLabel()
      })
      this._initLabel()
    },

    destroyed(){
      this.$store.commit(setSelectedInvoiceDetail, {})
    },
}

</script>

<style lang="scss" scoped>
  $edconnect: #1776CA;
  .payment-box{
    padding: 0.5rem;
    margin: 0.1rem;
    margin-top: 1rem;
    background-color: #f0f3f2;
    border-radius: 1rem;
  }
</style>