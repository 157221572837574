import { AppsRightApi } from "../services/AppsRightApi"
import { setAppsRightConfig } from "./mutations"
import { StatusType } from '@/store/enums';

export default {
    state: {
        appsRightConfig:[],
        appsRightList: [],
    },

    mutations: {
        setAppsRightConfig(state, payload){
            state.appsRightConfig= [],
            state.appsRightConfig = payload
        },
        setAppsRightList(state, payload){
            state.appsRightList = payload
        },
    },

    actions: {
        getAppsRightMenu({commit}, { schoolId, studentId }) {
            if (!schoolId || !studentId) return
            return AppsRightApi.getAppsRightMenuBySchoolIdAndStudentId(schoolId, studentId)
                .then(({ data: dataRights}) => {
                    commit(setAppsRightConfig, dataRights)
                })
                .catch(err => {
                    naf.getApiError(err)
                })
        },

        getAppsRightList({commit}) {
            return AppsRightApi.getAppsRightList()
                .then(({ data: dataRightsList }) => {
                    commit('setAppsRightList', dataRightsList)
                    return StatusType.SUCCESS;
                })
                .catch(err => {
                    return StatusType.FAILURE;
                })
        },
    },

    getters: {
        appsRightConfig: state => state.appsRightConfig,
        appsRightList: state => state.appsRightList,
    },
}