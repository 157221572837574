<template>
<div class="parent-tab">
  <div v-for="(parent, i) in parents" :key="i">
    <div class="header-box">{{(label.parents)[i]}}</div>
    <v-row class="mt-2 align-center" v-for="data in dataList" :key="data.field">
      <v-col cols="3" sm="1">
          <v-icon>{{data.icon}}</v-icon>
      </v-col>
      <v-col cols="9" sm="5">
        <div class="label-small">
          {{label[data.field]}}
        </div>
        <div>
          {{parent[data.field] ? parent[data.field] : " - " }}
        </div>
      </v-col>
    </v-row>
  </div>
</div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    name: 'Parent',

    data:() => ({
      label: {
        name: "Name",
        address: "Address",
        handPhone: "Phone Number",
        father: "FATHER",
        mother: "MOTHER",
        guardian: "GUARDIAN",
        parents: ['Father', 'Mother', 'Guardian']
      },
      dataList: [
        { icon: "mdi-account-outline", field: "name" },
        { icon: "mdi-home", field: "address" },
        { icon: "mdi-phone", field: "handPhone" },
      ]
    }),

    computed:{
    ...mapGetters({ itemsFather: "itemsFather", itemsMother: "itemsMother", itemsGuardian: "itemsGuardian"}),
    parents(){
      let parents = [
        this.itemsFather,
        this.itemsMother,
        this.itemsGuardian
      ]
      parents.traverse(parent => {
        parent.address = parent.address.address1
      })
      return parents
    },
    },

    
    methods: {
      _initLabel(){
        this.label.name = naf.lang.get("", "IDENTITY.NAME")
        this.label.address = naf.lang.get("", "STUDENT_DATA.ADDRESS")
        this.label.handPhone = naf.lang.get("", "IDENTITY.HANDPHONE")
        this.label.parents[0] = naf.lang.get("", "STUDENT_DATA.FATHER")
        this.label.parents[1] = naf.lang.get("", "STUDENT_DATA.MOTHER")
        this.label.parents[2] = naf.lang.get("", "STUDENT_DATA.GUARDIAN")
      }
    },

    mounted(){
      naf.lang.onChangeLanguage(() => {
        this._initLabel()
      })
      this._initLabel()
    },

}
</script>

<style lang="scss" scoped>
  .header-box {
    margin-top: 1.5rem;
    // background-color: aliceblue;
    background-color: #2196F3;
    color: white;
    font-weight: bold;
    padding: 0.4rem 0.8rem;
    border-radius: 0.6rem;
  }
</style>