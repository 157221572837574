<template>
<div class="report pa-2">
    <v-col cols="12">
        <v-container class="inputs-container">
            <v-card >
                <v-card-title>{{ selectStudent.name }}</v-card-title>   
                <div v-if="!dataMark">
                    <v-progress-circular
                        indeterminate
                        color="primary"
                    ></v-progress-circular>
                    Waiting for loading report
                </div>
                <div v-else>
                    <v-row align="center" class="px-2 py-0 ma-0">
                        <v-col class="d-inline-flex" cols="6">
                            <v-select
                            :items="prditems"
                            item-text="gradePeriodName_J"
                            item-value="gradePeriodNameId"
                            label="Grade Period"
                            @change="changedPeriod"/>
                        </v-col>
                        <v-col class="d-inline-flex" cols="6">
                            <v-select
                            :items="grditems"
                            item-text="value"
                            item-value="id"
                            label="Grade Type"
                            @change="changedGrade"/>
                        </v-col>
                    </v-row>
                    <div v-for="itm in markColor" :key="itm.id">
                        <v-row v-if="itm.id == 'mark'">
                            <v-col v-for="mark in itm.itemColors" :key="mark.id"  class="markIcon">
                                <v-icon small :color="mark.color">mdi-square</v-icon>{{mark.label}}
                            </v-col> 
                        </v-row>
                    </div>
                    
                    <v-list two-line>
                        <v-list-item-group multiple>
                        <template v-for="(item, index) in markitems">
                            <v-list-item :key="item.subjectName"  @click="detailClick(item)">
                                <template>
                                    <v-list-item-content>
                                        <v-list-item-title v-text="item.subjectName"/>
                                        <v-progress-linear :value="item.mark" :color="item.color"></v-progress-linear>
                                    </v-list-item-content>
                                    <v-list-item-content>
                                        <v-list-item-subtitle v-html="item.textMark"></v-list-item-subtitle>
                                    </v-list-item-content>
                                    <v-list-item-content>
                                        <v-list-item-subtitle v-html="item.mark"></v-list-item-subtitle>
                                    </v-list-item-content>
                                </template>
                            </v-list-item>
                            <v-divider
                                v-if="index + 1 < markitems.length" :key="index"
                            ></v-divider>
                        </template>
                        </v-list-item-group>
                    </v-list>

                </div>


            </v-card>
        </v-container>
    </v-col>

    <mark-detail-dialog ref="mark_dialog" />

</div>
</template>

<script>
import { mapGetters } from "vuex";

export default {

    data: () => ({
        dataMark : false,
        studentName: '',
        dialog: false,
        valmark:0,
        postData: {
            gradePeriodId:'',
            gradeTypeId:'',
        },
        markColor: [
                {id:"mark", itemColors:[{color: '#8DC63F', label: 'Good'}, {color: '#1C75BC', label: 'Moderat'}, {color: '#ED1C24', label: 'Not Good'}, {color: '#0E233A', label: 'Passing Grade'}]},
                {id:"mark_color_for_sa", itemColors:[{color: '#1C75BC', label: 'Excellent (A*: 90-100)'}, {color: '#8DC63F', label: 'Good (A: 80-89)'}, {color: '#8DC63F', label: 'Meeting Expectation (B: 70-79)'}, {color: '#FFF200', label: 'Approaching (C: 60-69)'}, {color: '#FFAB00', label: 'Showing Effort (D: 50-59)'}]},
                {id:"mark_color_per_kd_sa", itemColors:[{color: '#1C75BC', label: 'Excellent'}, {color: '#8DC63F', label: 'Good'}, {color: '#FFF200', label: 'Satisfactory'}, {color: '#ED1C24', label: 'Needs Improvement'}]},
            ]
    }),

    computed:{
        ...mapGetters({ prditems: "itemsPeriodList", grditems: "itemsTypeList", markitems: "itemsStudentMarkList", selectStudent: "getStudentSelected", dataStudentMark: "getJsonStudentMark" }),
    },

    watch:{
      dataStudentMark(newValue){
          this._readStatus()
      }
    },

    methods: {
        changedPeriod(selectedPeriod){
            if (selectedPeriod != null) {
                this.postData.gradePeriodId = selectedPeriod
                this.$store.dispatch('getGroupGradeType', selectedPeriod)
            }
        },

        changedGrade(selectedGrade){
            if (selectedGrade != null) {
                this.postData.gradeTypeId = selectedGrade
                this.$store.dispatch('showAllDataMark', this.postData)
            }
        },

        detailClick(params){
            this.$refs.mark_dialog.openMark(params);
        },

        _readStatus(){
            if(this.$store.getters.getJsonStudentMark.length === 0){
              this.dataMark = false
            } else {
                this.dataMark = true
            }
        }
    },

    created() {
        this._readStatus()
    }

}
</script>

<style scoped>
.v-progress-circular {
  margin: 1rem;
}
</style>

<style lang="scss">
.markIcon{
    font-size: 12px;
}
.inputs-container{
    padding: 0;
     .col-6 {
        padding-top: 0;
        padding-bottom: 0;
      }
      .col-3 {
        padding-top: 0;
        padding-bottom: 0;
      }
  }
</style>