import LecturerApi from '../services/LecturerApi';
import {setLecturerData, SET_IS_LOADING } from './mutations';

export default {
    state: {
        storeLecturerByClass: [],
        storedItemListLecturerByClass: []
    },
    mutations: {
        setLecturerData(state, payload){
            state.storeLecturerByClass = []
            state.storedItemListLecturerByClass = []
            state.storeLecturerByClass = payload

            Object.keys(payload).forEach(key => {
                let lecturerObject = {
                    to: payload[key].userId,
                    toName: payload[key].name,
                    subjectName: payload[key].name+" - "+payload[key].subjectName,
                    toType: "TO", toLevel: "PERSONAL", recipientType: "TEACHER",
                }
                state.storedItemListLecturerByClass.push(lecturerObject);
            })

        }
    },
    actions: {
        setSelectedLecturerByClass({ commit }, { schoolId, studentId }){
            LecturerApi.getSelectedLecturerByClass(studentId, schoolId)
            .then(response => response.data)
            .then(storeLecturerByClass => {
                commit(setLecturerData, storeLecturerByClass)
            })
            .catch(err => {
                console.log(err);
            })
            .finally(() => {
                commit(SET_IS_LOADING, false)
            })

        }
    },
    getters: {
        getLecturerByClass: state => state.storeLecturerByClass,
        itemListLecturerByClass: state => state.storedItemListLecturerByClass,
    }
}