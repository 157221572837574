<template>
  <v-app>

    <router-view name="toptoolbar"></router-view>

    <v-main>
      <!-- <transition name="slide"> -->
        <v-progress-linear
          :active="isLoading"
          indeterminate
          absolute
          color="blue"
        />
        <router-view></router-view>
      <!-- </transition> -->
    </v-main>

    <router-view name="bottomnav"></router-view>

    <confirm ref="confirm"></confirm>
    <snack-bar />

    <v-banner
        v-if="deferredPrompt"
        color="info"
        dark
        class="text-left"
      >
      Add edconnect Parent to Home Screen
      
      <template v-slot:actions>
        <v-btn text @click="dismiss">Dismiss</v-btn>
        <v-btn text @click="install">Install</v-btn>
      </template>
    </v-banner>
  </v-app>
</template>

<script>

  export default {
    data: () => ({
      appsRight: [],
      deferredPrompt: null
    }),

    mounted() {
      this.$root.$confirm = this.$refs.confirm.open;
    },

    methods: {
      async dismiss() {
        this.deferredPrompt = null;
      },
      async install() {
        this.deferredPrompt.prompt();
      },

       logoutFunction: function() {
         this.$store.dispatch('logout').then(res => {
           this.$router.push({name: 'Login'});
          })
        },

        appsRightFunction(feature) {
          this.appsRight = this.$store.getters.appsRightList
          var userid = this.$store.getters.getStudentSelected.nim;
          if (this.appsRight.length > 0){
            var newArray = this.appsRight.filter(function (el) {
              return el.userId === userid;
            });

            if (newArray.length > 0){
              for (var i=0; i < newArray.length; i++){
                if( newArray[i].featureName === feature){
                   return newArray[i].visibility;
                 }
              }
            } else { return false; }
          } else { return false; }
        }
    },
    computed: {
      isLoading(){
        return this.$store.state.isLoading
      }
    },
    created() {
      this.$root.$refs.main_component = this;
      window.addEventListener("beforeinstallprompt", e => {
        e.preventDefault();
        this.deferredPrompt = e;
      });

      window.addEventListener("appinstalled", () => {
        this.deferredPrompt = null;
      });

      if (process.env.NODE_ENV === 'production') {
        if(!/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
          if (localStorage.getItem('urlSchool') !== window.location.origin +'/api/'){
            localStorage.setItem('urlSchool', window.location.origin +'/api/') 
          }
        }
      }

    }
  }
</script>

<style lang="scss">
  .slide-leave-active, .slide-enter-active {
    transition: 1s;
  }
  .slide-enter {
    transform: translate(100%, 0);
  }
  .slide-leave-to {
    transform: translate(-100%, 0);
  }
</style>