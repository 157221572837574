export const AppsRightType = {
    ANNOUNCEMENT: "ANNOUNCEMENT",
    MESSAGE: "MESSAGE",
    ABSENT: "ABSENT",
    FINANCE: "FINANCE",
    GRADE: "GRADE",
    PURCHASE_HISTORY: "PURCHASE_HISTORY",
    OBSERVATION_MARK: "OBSERVATION_MARK",
    CHANGE_PASSWORD: "CHANGE_PASSWORD",
    CHANGE_EMAIL: "CHANGE_EMAIL",
    ATTENDANCE: "ATTENDANCE",
    KIDDIES: "KIDDIES",
    SCHEDULE: "SCHEDULE",
    GALLERY: "GALLERY",
    ASSESSMENT: "ASSESSMENT",
    STUDENT_PROFILE: "STUDENT_PROFILE",
    GRADE_IS_WEIGHTED: "GRADE_IS_WEIGHTED",
    FINANCE_SIDEBAR: "FINANCE_SIDEBAR",
    GRADE_SIDEBAR: "GRADE_SIDEBAR",
    ANNOUNCEMENT_DASHBOARD: "ANNOUNCEMENT_DASHBOARD",
    PARENT_ABLE_TO_ABSENT: "PARENT_ABLE_TO_ABSENT",
    PINTEK: "PINTEK",
    SHOW_SCHEDULE: "SHOW_SCHEDULE",
}

export const AttendanceStatus = {
    ATTEND: "ATTEND",
    ALPHA: "ALPHA",
    PERMISSION: "PERMISSION",
    SICK: "SICK",
    LATE: "LATE",
    NOT_YET: "NOT_YET",
    NO_SCHEDULE: "NO_SCHEDULE",
    OUT: "OUT"
}

export const StatusType = {
    SUCCESS: "SUCCESS",
    FAILURE: "FAILURE", 
    OK: "OK",
    NO: "NO"
}