jng.lang = jng.coalesce(jng.lang, {});
jng.langMaps = {};
jng.lang.in = { default: {}, sa: {}, digislamic: {}};
jng.lang.in.sa.items = {} 
jng.lang.in.digislamic.items = {}
jng.lang.in.default.items = {
    STUDENT: "Siswa",
    IDENTITY: {
        NICK_NAME: "Nama Panggilan",
        NAME: "Nama",
        NISN: "NISN",
        BIRTH_PLACE: "Tempat Lahir",
        BIRTH_DATE: "Tanggal Lahir",
        GENDER: "Jenis Kelamin",
        RELIGION: "Agama",
        CHILD_STATUS: "Status Anak",
        HANDPHONE: "Nomor HP",
    },
    STUDENT_DATA: {
        ADDRESS: "Alamat",
        PROVINCE: "Provinsi",
        CITY: "Kota",
        DISTRICT: "Kecamatan",
        ZIP_CODE: "Kode Pos",
        PARENT_GUARDIAN: "Orang Tua/Wali",
        SIBLING: "Saudara(Satu sekolah)",
        FATHER: "Ayah",
        MOTHER: "Ibu",
        GUARDIAN: "Wali",
    },
    DETAIL_PAYMENT: "Detail Pembayaran",
    PAYMENT: "Pembayaran",
    ATTENDANCE_STATUS: {
        ATTEND: "HADIR",
        ALPHA: "TANPA IZIN",
        PERMISSION: "IZIN",
        SICK: "SAKIT",
        LATE: "TERLAMBAT",
        NOT_YET: "BELUM ADA ABSENSI",
        NO_SCHEDULE: "TIDAK ADA JADWAL",
        OUT: "KELUAR"
    },
    __ID: function(value) {
        return "ID " + value;
    },
    __NAME: function(value) {
        return "Nama " + value;
    },
    __NICK_NAME: function(value) {
        return "Nama Alias " + value;
    },
    __NAME_ENGLISH: function(value) {
        return value + " Nama (Bilingual)";
    },
    __STATUS: function(value) {
        return "Status " + value;
    },
    __TITLE: function(value) {
        return "Judul " + value;
    },
    __CODE: function(value) {
        return "Kode " + value;
    },
    __NEW: function(value) {
        return value + " baru";
    },
    __NOTE: function(value) {
        return "Catatan " + value;
    },
    __NO: function(value) {
        return "No " + value;
    },
    __START: function(value) {
        return value + " Awal";
    },
    __END: function(value) {
        return value + " Akhir";
    },
    __FIRST: function(value) {
        return value + " Awal";
    },
    __LAST: function(value) {
        return value + " Akhir";
    },
    __REPORT_NAME: function(value) {
        return "Nama " + value + " di Laporan";
    },
    __EN: function(value) {
        return value + " (Inggris)";
    },
    __NEW: function(value) {
        return "Membuat " + value
    },
    __EDIT: function(value) {
        return "Mengubah " + value
    },
    __DELETE: function(value) {
        return "Menghapus " + value
    },
    __CHECK: function(value) {
        return "Cek " + value
    },
    __CONFIRMATION: function(value) {
        return "Konfirmasi " + value
    },
    __SAVE_SUCCESS: function(value) {
        return value + " berhasil disimpan"
    },
    __CANCEL_SUCCESS: function(value) {
        return value + " berhasil dibatalkan"
    },
    __GENERATE_SUCCESS: function(value) {
        return value + " berhasil dibuat"
    },

    __CHANGE_SUCCESS: function(value) {
        return value + " berhasil diubah"
    },
    __DELETE_SUCCESS: function(value) {
        return value + " berhasil dihapus"
    },
    __DOWNLOAD_SUCCESS: function(value) {
        return value + " berhasil diunduh"
    },
    __UPLOAD_SUCCESS: function(value) {
        return value + " berhasil diunggah"
    },
    __EMAIL_SUCCESS: function(value) {
        return value + " berhasil dikirim email"
    },
    __APPROVED: function(value) {
        return value + " berhasil disetujui"
    },
    __REJECTED: function(value) {
        return value + " berhasil ditolak"
    },

    __DELETE_CONFIRMATION: function(value, data) {
        if (data == '') {
            return 'Apakah Anda yakin mau menghapus ' + (value == '' ? 'data' : value.toLowerCase()) + ' ini?'
        }
        return 'Apakah Anda yakin mau menghapus ' + value.toLowerCase() + ' "' + data + '"?'
    },
    __CLOSE_CONFIRMATION: function(value) {
        return 'Apakah Anda mau menutup ' + (value == '' ? 'isian' : value) + ' ini?'
    },

    __ID_DUPLICATION: function(value) {
        return value + " dengan ID tersebut digunakan. Silahkan pilih yang lain."
    },
    __NAME_DUPLICATION: function(value) {
        return value + " dengan NAMA tersebut sudah digunakan. Silahkan pilih yang lain."
    },
    __ERROR_DATA_TYPE: function(value, type, atRow) {
        return "Tolong ubah nilai " + value + "menjadi bertipe " + type
    },
    __ERROR_EMPTY: function(value) {
        return "Tolong diisi " + value
    },
    __ERROR_EMPTY_SELECTION: function(value) {
        return "Tolong dipilih " + value
    },
    __ERROR_MAX_LENGTH: function(value, length) {
        return value + " tidak boleh melebihi " + length + " karakter"
    },
    __ERROR_LESS_THEN_TODAY: function(value) {
        return value + " harus lebih besar dari hari ini"
    },
    __ERROR_EMPTY_AT_ROW: function(value, atRow) {
        return "Silahkan memasukkan " + value + " pada baris ke " + atRow
    },
    __ERROR_DATA_TYPE_AT_ROW: function(value, type, atRow) {
        return "Tolong ubah nilai " + value + "menjadi bertipe " + type + " pada baris ke " + atRow
    },
    __ERROR_DATA_TYPE_WITH_TARGET: function(value, type, target) {
        return "Tolong ubah nilai " + value + ". "+target+" menjadi bertipe " + type
    },
    __RESOURCES: function(value) {
        return ("Sumber Daya " + value).trim()
    },
    __DATE: function(value) {
        return "Tanggal " + value;
    },
    __NUMBER: function(value) {
        return "Nomer " + value;
    },
    __CONSIDERATION: function(value) {
        return value + ", apakah ingin melanjutkan ?"
    },
    __DATA: function(value) {
        return "Data " + value
    },
    __ALL: function(value) {
        return "Semua " + value
    },
    __FIND: function(value) {
        return "Cari " + value
    },
    __PER: function(value) {
        return "Per " + value
    },
}