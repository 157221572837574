<template>
  <v-card class="overflow-hidden height-100">
    <app-bar :label="label.label" :valueBack="label.backPage" />

    <v-sheet id="scrolling-techniques-7" class="overflow-y-auto" height="100%" >
      <v-container>
          <div>
              <v-card color="blue" class="pa-2" style="min-height: 88.5vh;">
                  <v-card-title style="color: white;">{{ student_name }}</v-card-title>

                  <v-tabs v-model="tab" background-color="transparent" color="white" show-arrows>
                      <v-tab v-for="item in items" :key="item.id">{{ item.name }}</v-tab>
                  </v-tabs>

                  <v-tabs-items class="pa-2" v-model="tab">
                    <v-tab-item v-for="item in items" :key="item.id">
                      <indentity-tab v-if="item.id == 'student'" />
                      <address-tab v-else-if="item.id == 'address'" />
                      <parent-tab v-else-if="item.id == 'parent'" />
                      <siblingschool-tab v-else />
                    </v-tab-item>
                  </v-tabs-items>

              </v-card>
          </div>
      </v-container>
    </v-sheet>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import { SET_IS_LOADING } from '../../config/const.vue';
import AppBar from '@/components/base/AppBar.vue'

export default {
    name: 'StudentData',

    components: { AppBar },

    data () {
      return {
        studentId: 0,
        tab: null,
        
        label: {
          label: "Student Data",
          backPage: "Home"
        },

        items: [
          {id: "student",name: 'Student Data'}, 
          {id: "address",name: 'Address'},
          {id: "parent",name: 'Parent/Guardian'},
          {id: "sibling",name: 'Sibling(Same School)'}, 
        ],
      }
    },

    computed:{
    ...mapGetters({ student_name: "getStudentName"}),
    },

    created() {
      this.$store.commit(SET_IS_LOADING, true)
      if (!this.$route.params.studentid) return this.$router.push('/home')
      this.$store.dispatch('loadStudentProfile', {'studentId':this.$route.params.studentid})
        .then(()=> {
          this.$store.commit(SET_IS_LOADING, false)
        })
    },

    mounted(){
      naf.lang.onChangeLanguage(() => {
        this._initLabel()
      })
      this._initLabel()
    },

    methods:{
        
        _initLabel(){
          this.label.studentData = naf.lang.get("", "STUDENT__DATA")
          this.items[0].name = naf.lang.get("", "STUDENT__DATA")
          this.items[1].name = naf.lang.get("", "STUDENT_DATA.ADDRESS")
          this.items[2].name = naf.lang.get("", "STUDENT_DATA.PARENT_GUARDIAN")
          this.items[3].name = naf.lang.get("", "STUDENT_DATA.SIBLING")
        }
    }
}

</script>
<style>
.height-100{
  height: 100%;
}
.basil {
  background-color: #FFFBE6 !important;
}
.basil--text {
  color: #356859 !important;
}
</style>