<template>
    <div>
        <div :class="`label-small ${isLabelClickAble&&'pointer'}`" >
            {{label}}
        </div>
        <div v-if="textHtml" v-html="textHtml" :class="`${isTextClickAble&&'pointer'}`" />
        <div v-else :class="`${isTextClickAble&&'pointer'}`" >
            {{text ? text : value}}
        </div>
        <v-divider v-if="showDivider" />
    </div>
</template>

<script>
export default {
    name: "LabelText",
    props: {
        label: { default: "" },
        value: { default: "" },
        text: { default: "" },
        isLabelClickAble: { default: false },
        isTextClickAble: { default: false },
        showDivider: { default: false },
        textHtml: { default: "" },
    },
}
</script>

<style lang="scss" scoped>
    .label-small{
        font-size: 12px;
        color: rgba(0,0,0,0.6);
    }
    .pointer{
        cursor: pointer;
    }
</style>
