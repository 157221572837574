<template>
<div class="message pa-6">
    <v-container>
        <v-card >
            <v-card-title>{{ selectStudent.name }}</v-card-title> 
            <v-subheader>
                <v-switch
                inset
                :input-value="switchStudent"
                :label="`Show For All Student`"
                @change="changedStudent"/>
            </v-subheader>
            <v-tabs v-model="tabs" fixed-tabs>
                <v-tab v-for="item in items" :key="item.id">{{ item.name }}</v-tab>
            </v-tabs>

            <v-tabs-items v-model="tabs">
                <v-tab-item v-for="item in items" :key="item.id">
                    <inbox-tab v-if="item.id == 'inbox'" />
                    <sent-tab v-else-if="item.id == 'sent'" />
                    <draft-tab v-else />
                </v-tab-item>
            </v-tabs-items>

            <v-fab-transition>
                <v-btn 
                color="blue"
                fab
                dark
                small
                absolute
                bottom
                right
                @click="$router.push('new-message')"> <v-icon>mdi-plus</v-icon> </v-btn>
            </v-fab-transition> 
        </v-card>
    </v-container>
       
</div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    name: "Message",

    data: () => ({
        tabs: null,
        items: [
          {id: "inbox", name: 'Inbox'}, 
          {id: "sent", name: 'Sent'},
          {id: "draft", name: 'Draft'},
        ],
    }),

    computed:{
        ...mapGetters({ switchStudent: "isAllStudent", selectStudent: "getStudentSelected" }),
    },

    methods: {
        changedStudent(switchStudent) {
            this.$store.dispatch('setSwitchAllStudent', switchStudent)
        },
    }


}
</script>