"use strict";
if (window.jng === undefined) {
  window.jng = {};
  let jng = window.jng;
  jng.DOM = {
      eventHandlers: {}
  }
  jng.DOM.isExists = function (el, elSource) {
      if (el == elSource) return true
      for (let i = 0; i < elSource.children.length; i++) {
          if (el == elSource.children[i]) return true
      }
      return false
  }
  jng.DOM.invokeEventHandler = function(e) {
    let allHandlers = jng.DOM.eventHandlers[e.type]
    for(let i = 0; i < allHandlers.length; i++) {
        allHandlers[i](e)
    }
  }
  jng.DOM._specialEventHandle = function(e) {
    if (e.type == 'keyup') {
        if (e.key === 'Escape'){
            if (jng.modal && jng.modal.isShowed) {
                jng.modal.close()
            } else {
                for (let i = jng.confirmationList.length - 1; i >= 0; i--) {
                    if (jng.confirmationList[i].isShow) {
                        jng.confirmationList[i].closeByUser()
                        break;
                    }
                }
            }
        }
    }
  }
  jng.DOM.initEventListener = function() {
    document.addEventListener('keyup', jng.DOM._specialEventHandle)
  }
  jng.DOM.destroyEventListener = function() {
    document.removeEventListener('keyup', jng.DOM._specialEventHandle)
  }
  jng.DOM.addEventListener = function(eventName, func) {
    if (!jng.DOM.eventHandlers[eventName]) {
        jng.DOM.eventHandlers[eventName] = []
        document.addEventListener(eventName, jng.DOM.invokeEventHandler)
    }
    jng.DOM.eventHandlers[eventName].push(func)
  }
  jng.DOM.removeEventListener = function(eventName, func) {
    if (!jng.DOM.eventHandlers[eventName]) return
    for (let i = 0; i < jng.DOM.eventHandlers[eventName].length; i++) {
        if (jng.DOM.eventHandlers[eventName][i] == func) {
            jng.DOM.eventHandlers[eventName].deleteAt(i)
            break
        }
    }
    if (jng.DOM.eventHandlers[eventName].length == 0) {
        jng.DOM.eventHandlers[eventName] = undefined
        document.removeEventListener(eventName, jng.DOM.invokeEventHandler)
    }
  }


  jng.length = {
      MEDIUM_ID: 10,
      ID: 20,
      NAME: 50
  }

  jng.isUndefined = function (obj) {
    return obj === undefined || obj === null;
  };

  jng.isDefined = function (args) {
    var i, iArgumentLength;
    for (i = 0, iArgumentLength = arguments.length; i < iArgumentLength; i += 1) {
        if (jng.isUndefined(arguments[i])) {
            return false;
        }
    }
    return true;
  };

  jng.isDefinedProperty = function (obj, arzProp) {
    if (jng.isUndefined(obj)) {
        return false;
    }

    var value = jng.getPropertyValue(obj, arzProp);
    if (jng.isUndefined(value)) {
        return false;
    }
    return true;
 };

jng.isObject = function (obj) {
    if (jng.isArray(obj)) return false;
    return obj instanceof Object;
};

jng.makeAsArray = function (data) {
    if (jng.isArray(data)) {
        return data;
    }
    var result = [];
    result[0] = data;
    return result;
};

jng.getSchoolId = function(){
    if(jng.user.workplaceId !== ''){
        return jng.user.workplaceId;
    }else{
        return jng.userContext.workplaceRights[0].id;
    }
}

jng.extract = function (obj, arzProperty) {
    var resultItem = {};
    var j;
    for (j = 0; j < arzProperty.length; j += 1) {
        resultItem[arzProperty[j]] = obj[arzProperty[j]];
    }
    return resultItem;
};

jng.isArray = function (obj) {
    return obj instanceof Array;
};

jng.isFunction = function (obj) {
    return obj instanceof Function;
};

jng.isString = function(obj) {
    return (typeof obj) === 'string';
};
jng.compareDate = function(date1, date2) {
    var dt1 = date1
    if (!jng.isDate(date1)) {
        dt1 = new Date(date1)
    }
    var dt2 = date2
    if (!jng.isDate(date2)) {
        dt2 = new Date(date2) 
    }
    return dt1.compareDate(dt2)
}
jng.isDate = function (obj) {
    var result = obj instanceof Date;
    if (result) {
        if (isNaN(obj.getTime())) {
            return false;
        }
    }
    return result;
};

jng.isBoolean = function(obj) {
    return obj === true || obj === false;
}

jng.isLiteral = function (obj) {
    if (jng.isArray(obj)) {
        return false;
    }
    if (jng.isObject(obj)) {
        return false;
    }
    return true;
};

jng.isValueEqual = function (valueData1, valueData2) {    
    if (valueData1 instanceof Date) {
        return (function isDateEqual(date1, date2) {
            if (date1.compareDate(date2) === 0) {
                return true;
            }
            return false;
        })(valueData1, valueData2);
    } else if (jng.isString(valueData1) && jng.isString(valueData2)) {
        return valueData1.trim().toLowerCase() == valueData2.trim().toLowerCase() 
    }
    return valueData1 === valueData2;
};

jng.isObjectEqual = function (data1, data2, arzProperty) {
    //Begin-Code
    var idxHeader;
    for (idxHeader = 0; idxHeader < arzProperty.length; idxHeader += 1) {
        var valueData1 = data1[arzProperty[idxHeader]];
        var valueData2 = data2[arzProperty[idxHeader]];
        if (!jng.isValueEqual(valueData1, valueData2)) {
            return false;
        }
    }
    return true;
};

jng.getPropertyValue = function (obj, arzProp) {
    var currObj = obj;
    var i;
    for (i = 0; i < arzProp.length; i += 1) {
        currObj = jng.getValueOfObject(currObj, arzProp[i]);
        if (jng.isUndefined(currObj)) {
            return undefined;
        }
    }
    return currObj;
};

jng.getValueOfObject = function (objSrc, propertyName) {
    if (jng.isUndefined(objSrc)) {
        return undefined;
    }
    if (propertyName === undefined) {
        return undefined;
    }
    if (propertyName === '') {
        return undefined;
    }
    if (objSrc === null) {
        return undefined;
    }
    if (propertyName.startsWith('"') || propertyName.startsWith("'")) {
        return propertyName.substring(1, propertyName.length - 1);
    }

    var props = propertyName.split('.');
    var i;
    var result = objSrc;
    for (i = 0; i < props.length; i++) {
        const propElList = props[i].split('[')
        const propName = propElList[0]
        if (jng.isUndefined(result[propName])) return undefined
        result = result[propName]

        for (let arrIdx = 1; arrIdx < propElList.length; arrIdx++) {
            const propEl = propElList[arrIdx]
            const idx = propEl.substring(0, propEl.length - 1)
            if (jng.isUndefined(result[idx])) return undefined
            result = result[idx]
        }
    }
    return result;
}

jng.setIconDatePicker = (el)=>{
    var setIcon = (arr, value)=>{
        for(var obj of arr ){
            obj.innerHTML = value;
        }
    };
    var nextMonth = el.querySelectorAll('.mx-icon-next-month');
    var lastMonth = el.querySelectorAll('.mx-icon-last-month');
    var nextYear = el.querySelectorAll('.mx-icon-next-year');
    var lastYear = el.querySelectorAll('.mx-icon-last-year');
    setIcon(nextMonth, '<b> > </b>');
    setIcon(lastMonth, "<b> < </b>");
    setIcon(nextYear, '<b> >> </b>');
    setIcon(lastYear, '<b> << </b>');
}

jng.setToCurrency = function(Value){
    let valueAsNum = 0
    if (jng.isString(Value)) {
        valueAsNum = jng.currencyToNumber(Value)            
    } else {
        valueAsNum = Math.round(Value * 100) / 100
    }
    var value = String(valueAsNum).trim();
    var arr1 = [];
    var arr2 = [];
    var inc = 0;
    // var isNegative = value.startsWith("-")
    // if (isNegative) value = value.substring(1)
    var valueComp = value.split(".")
    if (valueComp.length == 0) return ''
    value = valueComp[0]
    for(var data of value){
        arr1.push(data);
    };
    
    for(const data of arr1.reverse()){
        if(String(Number(data)) == "NaN"){
            continue;
        }

        if(inc == 3){
            arr2.push(',');
            inc = 0;
        }
        arr2.push(data);
        inc++;
    }

    var result = '';
    
    for(const val of arr2.reverse()){
        result = result.concat(val);
    }
    if (valueComp.length > 1) {
        result = result.concat(".")
        for(var digit of valueComp[1]) {
            result = result.concat(digit)
        }
    }
    // Value = result;
    if(valueAsNum < 0){
        result = "-"+result;
    }
    return result;
}

jng.currencyToNumber = function(value){
    return Number(String(value).replace(/,/g , ''));
};

jng.allTime = [];
jng.createTime = function(timeName, type){
    jng.allTime = [];
    return jng.addTime(timeName, type);
}
jng.addTime = function(timeName, type){
    let intTime = 1000;
    if(type=="MILLIS"){
        jng.allTime.push({timeName: timeName, timeNum: 0, desc: "in millis", type: type});
        intTime = 1;
    }
    else if(type=="SECOND")jng.allTime.push({timeName: timeName, timeNum: 0, desc: "in second", type: "SECOND"});
    else jng.allTime.push({timeName: timeName, timeNum: 0, desc: "in second", type: "SECOND"});
    let curInterval = setInterval(function(){
        let opt = jng.allTime.findFirst(timeName, "timeName");
        if(opt!=undefined){
            opt.timeNum += 1;
        }
    }, intTime);
    let opt = jng.allTime.findFirst(timeName, "timeName");
    if(opt!=undefined)opt.interval = curInterval;
    return {
        stopTime: function(timeName){
            let opt = jng.allTime.findFirst(timeName, "timeName");
            if(opt!=undefined){
                clearInterval(opt.interval);
                let lastTime = opt.timeNum;
                jng.allTime.deleteFirst(timeName, 'timeName');

                return lastTime;
            }else{
                return 0;
            }
        },
        getCurrentTime: function(timeName){
            let opt = jng.allTime.findFirst(timeName, "timeName");
            if(opt!=undefined)return opt.timeNum;
            else return 0;
        }
    }
};

jng.setValueOfObject = function (objSrc, propertyName, value) {
    var props = propertyName.split('.');
    var i;
    var result = objSrc;
    for (i = 0; i < props.length - 1; i++) {
        const propElList = props[i].split('[')
        const propName = propElList[0]
        if (jng.isUndefined(result[propName])) result[props[i]] = {};
        result = result[propName]

        for (let arrIdx = 1; arrIdx < propElList.length; arrIdx++) {
            const propEl = propElList[arrIdx]
            const idx = propEl.substring(0, propEl.length - 1)
            if (jng.isUndefined(result[idx])) result[idx] = {}
            result = result[idx]
        }
    }
    result[props[props.length - 1]] = value;


}

jng.coalesce = function (args) {
    var i;
    for (i = 0; i < arguments.length; i++) {
        if (jng.isDefined(arguments[i])) {
            return arguments[i];
        }
    }
}

jng.coalesceProp = function (obj, arzProp, nullValue) {
    if (jng.isDefinedProperty(obj, arzProp)) {
        return jng.getPropertyValue(obj, arzProp);
    }
    return nullValue;
};

jng.isEmpty = function(str) {
    if (jng.isUndefined(str)) return true
    if (str.trim() == '') return true
    return false
}
jng.coalesceAndEmpty = function (args) {
    var i;
    for (i = 0; i < arguments.length; i++) {
        if (jng.isDefined(arguments[i])) {
            if (arguments[i] === '' && i < arguments.length - 1) {
                continue;
            }
            return arguments[i];
        }
    }
}

jng.cloneLiteral = function (obj) {
    var result = {};
    var prop;
    for (prop in obj) {
        if (obj.hasOwnProperty(prop)) {
            result[prop] = obj[prop];
        }
    }
    return result;
};

jng.clone = function (objOrArr) {
    function cloneObject(obj) {
        var result = {};
        var prop;
        for (prop in obj) {
            if (obj.hasOwnProperty(prop)) {
                if (jng.isArray(obj[prop])) {
                    var currObjArr = obj[prop];
                    result[prop] = [];
                    for(var i = 0; i < currObjArr.length; i++) {
                        result[prop][i] = jng.clone(currObjArr[i]);
                    }
                } else if (jng.isDate(obj[prop])) {
                    result[prop] = new Date(obj[prop].getTime());
                } else if (obj[prop] instanceof Function) {
                    result[prop] = obj[prop];
                } else if (jng.isObject(obj[prop])) {
                    result[prop] = jng.clone(obj[prop]);
                } else {
                    result[prop] = obj[prop];
                }
            }
        }
        return result;
    }

    //BEGIN
    if (!jng.isObject(objOrArr) && !jng.isArray(objOrArr)) {
        return objOrArr;
    }

    if (jng.isArray(objOrArr)) {
        var result = [];
        var i;
        for (i = 0; i < objOrArr.length; i++) {
            result.push(cloneObject(objOrArr[i]));
        }
        return result;
    }

    return cloneObject(objOrArr);
};
jng.unReactiveObject = function(obj){
    var temp = [obj];
    temp = temp.clone();
    return temp[0];
}

jng.compareArray = function(arr1, arr2){
    if(!jng.isArray(arr1) || !jng.isArray(arr2)){
        console.warn("is not an array");
        return false;
    }
    
    let isDifferent = true;

    if(arr1.length>arr2.length)return isDifferent;
    let checker1 = [];
    for (var obj1 of arr1) {
        let isHaveIdenticObject = false;
        for (var obj2 of arr2) {
            if(typeof obj1 == 'object' && typeof obj2 == 'object'){
                if(jng.compareObject(obj1, obj2)==false) isHaveIdenticObject = true;
            }else if(obj1==obj2)isHaveIdenticObject = true;
            
        }
        checker1.push(isHaveIdenticObject);
    }

    let checker2 = [];
    for (var obj2 of arr2) {
        let isHaveIdenticObject = false;
        for (var obj1 of arr1) {
            if(typeof obj1 == 'object' && typeof obj2 == 'object'){
                if(jng.compareObject(obj2, obj1)==false) isHaveIdenticObject = true;
            }else if(obj1==obj2)isHaveIdenticObject = true;
        }
        checker2.push(isHaveIdenticObject);
    }

    let opt = checker1.find(e=>e==false);
    if(jng.isDefined(opt))return isDifferent;
    opt = checker2.find(e=>e==false);
    if(jng.isDefined(opt))return isDifferent;

    isDifferent =false;
    return isDifferent
}

jng.compareObject = function(obj1, obj2, isWithArray){
    if(jng.isUndefined(isWithArray))isWithArray = false;
    let sizeObj1 = 0, sizeObj2 = 0;
    for(let prop in obj1)sizeObj1++;
    for(let prop in obj2)sizeObj2++;
    let mainObject = sizeObj1>sizeObj2? obj1 : obj2;
    let checker = [];
    for(let prop in mainObject){
        let isDifferent = true;
        if(jng.isDefined(obj1[prop]) && jng.isDefined(obj2[prop])){
            if(jng.isArray(obj1[prop]) && jng.isArray(obj2[prop])){
                if(isWithArray)isDifferent = jng.compareArray(obj1[prop], obj2[prop]);
            }else if(typeof obj1[prop] == 'object' && typeof obj2[prop] == 'object'){
                isDifferent = jng.compareObject(obj1[prop], obj2[prop]);
            }else isDifferent = obj1[prop] == obj2[prop];
        }else isDifferent = true;
        checker.push(isDifferent);
    }
    let opt = checker.find(e=>e==false);
    if(jng.isDefined(opt))return true;
    else return false;
}

jng.duplicateRemove = function(arr, szProp){
    if(jng.isArray(arr)){
        let collection = [];
        let result = [];
        for(let prop of arr.clone()){
            let findRes = collection.findFirst(prop[szProp], szProp);
            if(jng.isDefined(findRes)){
                continue;
            }else{
                collection.push(prop);
                result.push(prop);
            }
        }
        if(szProp == undefined){
            result = [];
            for(let obj of arr.clone()){
                if(result.indexOf(obj) === -1){
                    result.push(obj);
                }
            }
        }

        return result;
    }
}

jng.checkDuplicateItem = function(arr,szProp){
    let result = false
    if(jng.isArray(arr)){
        let groupted = arr.groupBy([szProp])
        groupted.traverse((item)=>{
            if(item.items.length > 1){
                result = true
            }
        })
    }
    return result
}

jng.arrayDistinct = function(arr, arzProp){
    if(arr.length>0){
        let newArr = [];
        let arrDistinct = arr.distinct(arzProp);
        for(let obj of arrDistinct){
            let opt = arr.find(e=>e[arzProp[0]] == obj[arzProp[0]]);
            if(jng.isDefined(opt))newArr.push(opt);
        }
        return newArr;
    }else return arr;
}

jng.multiDuplicateRemove = function(arr, multiSzProp){
    if(jng.isArray(arr)){
        let collection = [];
        let result = [];
        let multiAction = " ";
        for(let msz of multiSzProp){
            multiAction += 'prop["'.concat(msz, '"] == item["', msz, '"] && ');
        }
        for(let prop of arr.clone()){
            let findRes = collection.findFirstUsingEquality((item)=>{
                return eval(multiAction.slice(0, multiAction.length-3));
            });
            if(jng.isDefined(findRes)){
                continue;
            }else{
                collection.push(prop);
                result.push(prop);
            }
        }

        return result;
    }else{
        console.log("must be array")
        return [];
    }
}

jng.createurl = function(idList){
    if(jng.isArray(idList)){
        var slash = "/";
        var inc = 0;
        for(const list of idList){
            inc++;
            slash = slash + list ;
            if(inc !== idList.length){
                slash = slash + "/";
            }
        }
        var result = slash;
        return result;
    }
};

jng.createSearchQuery = function (queryParams) {
    var result = "";
    var i;
    for (i = 0; i < queryParams.length; i += 1) {
        if (queryParams[i].szValue === "") {
            continue;
        }
        if (result !== "") {
            result += "[;]";
        }
        result += queryParams[i].szName + "[=]" + queryParams[i].szValue;
    }
    return btoa(result);
};

jng.updateObject = function(obj, updateObj) {
    var prop;
    for (prop in obj) {
        if (obj.hasOwnProperty(prop)) {
            if (jng.isDefined(updateObj[prop])) {
                obj[prop] = updateObj[prop];
            }
        }
    }
};

jng.getHash = function(szReplacement, szSplitParam, iIndexHash){
    let loc = location.hash.replace(szReplacement, "");
    loc = loc.split(szSplitParam);
    if(jng.isArray(loc)){
        if(loc.length>iIndexHash){
            return loc[iIndexHash];
        }else return "";
    }else return "";
}

jng.emptyPromise = function() {
    return new Promise(function(resolve){resolve();})
}

jng.calculateMax = function(vals) {
    var result = -1
    for(var i = 0; i < vals.length; i++ ) {
        if (result < vals[i]) {
            result = vals[i]
        }
    }
    return result
}

jng.calculateHighestModus = function(vals, propName) {
    var tmp = {}
    vals.traverse(item => {
        var num = item
        if (jng.isDefined(propName)) {
            num = item[propName]
        }
        if (jng.isUndefined(tmp[num])) {
            tmp[num] = 0
        }
        tmp[num] ++
    })


    var prop;
    var maxCount = -1
    for (prop in tmp) {
        if (tmp.hasOwnProperty(prop)) {
            if (maxCount < tmp[prop]) {
                maxCount = tmp[prop]
            }
        }
    }

    var maxProps = []
    for (prop in tmp) {
        if (tmp.hasOwnProperty(prop)) {
            if (maxCount == tmp[prop]) {
                maxProps.add(prop)
            }
        }
    }

    return jng.calculateMax(maxProps)
}

jng.calculateAvg = function(vals, propName) {
    var sum = 0
    var total = 0
    vals.traverse(item => {
        var num = item
        if (jng.isDefined(propName)) {
            num = item[propName]
        }
        sum += num
        total ++
    })
    if (total == 0) return 0
    return sum / total
}




    /**
     * Delete first occurance obj in this array.
     * @param {*} obj
     */
    Array.prototype.deleteFirst = function (obj, szProperty) {
        var idx = this.findIndexObj(obj, szProperty);
        if (idx >= 0) {
            this.deleteAt(idx);
        }
    };

    Array.prototype.deleteAt = function (idx) {
        if (idx > -1) {
            this.splice(idx, 1);
        }
    };
    
    Array.prototype.unReactiveObject = function () {
        return jng.unReactiveObject(this);
    };

    Array.prototype.deleteUsingEquality = function (equalityFunc, deleteFunc) {
        var idx = 0;
        while (idx < this.length) {
            if (equalityFunc(this[idx])) {
                if (jng.isUndefined(deleteFunc)) {
                    this.deleteAt(idx);
                } else {
                    deleteFunc(idx);
                }
            } else {
                idx += 1;
            }
        }
    };

    Array.prototype.removeAll = function () {
        this.splice(0, this.length);
    };

    Array.prototype.setValueInArrayUsingFunc = function (szProperty, funcValueForItem) {
        var idx, max;
        for (idx = 0, max = this.length; idx < max; idx += 1) {
            this[idx][szProperty] = funcValueForItem(this[idx]);
        }
    };

    Array.prototype.addRange = function (listObj) {
        if (jng.isDefined(listObj)) {
            var i, max;
            for (i = 0, max = listObj.length; i < max; i += 1) {
                this.add(listObj[i]);
            }
        }
    };

    Array.prototype.pushAll = function (listObj) {
        if (jng.isDefined(listObj)) {
            var i, max;
            for (i = 0, max = listObj.length; i < max; i += 1) {
                this.push(listObj[i]);
            }
        }
    };

    Array.prototype.findIndexObj = function (obj, szProperty) {
        var idx, max;
        if (jng.isUndefined(obj)) {
            return -1;
        }
        if (jng.isArray(obj)) {
            for(idx = 0, max = obj.length; idx < max; idx += 1) {
                var result = this.findIndexObj(obj[idx], szProperty);
                if (result !== -1) return result;
            }
        } else {
            for (idx = 0, max = this.length; idx < max; idx += 1) {
                if (!jng.isObject(this[idx]) || jng.isUndefined(szProperty)) {
                    if (jng.isValueEqual(this[idx], obj)) {
                        return idx;
                    }
                } else {
                    var arrItem = jng.getValueOfObject(this[idx], szProperty);
                    if (jng.isValueEqual(arrItem, obj)) {
                        return idx;
                    }
                }
    
            }
        }
        
        return -1;
    };

    Array.prototype.findIndexUsingEquality = function (equalityFunc) {
        var idx, max;
        for (idx = 0, max = this.length; idx < max; idx += 1) {
            if (equalityFunc(this[idx])) {
                return idx;
            }
        }
        return -1;
    };

    Array.prototype.findIndexByObject = function (data1, funcOrArzProperty) {
        var idx;
        for (idx = 0; idx < this.length; idx += 1) {
            if (jng.isFunction(funcOrArzProperty)) {
                if (funcOrArzProperty(this[idx], data1)) {
                    return idx;
                }
            } else {
                if (jng.isObjectEqual(this[idx], data1, funcOrArzProperty)) {
                    return idx;
                }
            }
        }
        return -1;
    };

    Array.prototype.findFirst = function (obj, szProperty) {
        var idx = this.findIndexObj(obj, szProperty);
        if (idx < 0) {
            return undefined;
        }
        return this[idx];
    };

    Array.prototype.findFirstUsingEquality = function (equalityFunc) {
        var idx = this.findIndexUsingEquality(equalityFunc);
        if (idx === -1) {
            return undefined;
        }
        return this[idx];
    };


    Array.prototype.isExists = function (obj, szProperty) {
        var idx = this.findIndexObj(obj, szProperty);
        return idx >= 0;
    };

    Array.prototype.isExistsByObject = function (data1, funcOrArzProperty) {
        var idx = this.findIndexByObject(data1, funcOrArzProperty);
        if (idx === -1) {
            return false;
        }
        return true;
    };

    Array.prototype.orderBy = function (arzProperty, order) {
        function compare(data1, data2) {
            var idxHeader;
            for (idxHeader = 0; idxHeader < arzProperty.length; idxHeader += 1) {
                var valueData1 = jng.coalesce(data1[arzProperty[idxHeader]], '');
                var valueData2 = jng.coalesce(data2[arzProperty[idxHeader]], '');
                var result = 0;
                if (valueData1 < valueData2) {
                    result = -1;
                } else if (valueData1 > valueData2) {
                    result = 1;
                }
                if (result !== 0) {
                    if (jng.coalesce(order, 'ASC') === 'ASC') {
                        return result;
                    } else {
                        return result * -1;
                    }
                }
            }
            return 0;
        }

        return this.sort(compare);
    };

    Array.prototype.add = function (obj) {
        this[this.length] = obj;
        //this.push(obj);
    };

    Array.prototype.findAllByArray = function (arrObj, arzProperty) {
        function isObjectEqual(data) {
            var idxHeader;
            for (idxHeader = 0; idxHeader < arzProperty.length; idxHeader += 1) {
                var valueData1 = data[arzProperty[idxHeader]];
                var valueData2 = arrObj[idxHeader];
                if (!jng.isValueEqual(valueData1, valueData2)) {
                    return false;
                }
            }
            return true;
        }
        //Begin Code
        var result = [];
        if (jng.isUndefined(arrObj)) {
            return result;
        }
        var idx, max;
        for (idx = 0, max = this.length; idx < max; idx += 1) {
            if (isObjectEqual(this[idx])) {
                result.add(this[idx]);
            }
        }
        return result;
    };

    Array.prototype.findAll = function (obj, property) {
        return this.findAllByArray([obj], [property]);
    };

    Array.prototype.findAllUsingEquality = function (equalityFunc) {
        var result = [];
        var idx, max;
        for (idx = 0, max = this.length; idx < max; idx += 1) {
            if (equalityFunc(this[idx])) {
                result.push(this[idx]);
            }
        }
        return result;
    };

    Array.prototype.groupBy = function (headerProperties, szItemName) {
        function getResultIndex(result, data) {
            var idxResult;
            for (idxResult = 0; idxResult < result.length; idxResult += 1) {
                if (jng.isObjectEqual(result[idxResult], data, headerProperties)) {
                    return idxResult;
                }
            }
            return -1;
        }
        function createNewHeaderData(data) {
            var resultItem = {};
            var idxHeader;
            for (idxHeader = 0; idxHeader < headerProperties.length; idxHeader += 1) {
                var valueData = data[headerProperties[idxHeader]];
                resultItem[headerProperties[idxHeader]] = valueData;
            }
            return resultItem;
        }

        //Begin - code
        var itemName = jng.isDefined(szItemName) ? szItemName : "items";
        var result = [];
        var idx, max;
        for (idx = 0, max = this.length; idx < max; idx += 1) {
            var data = this[idx];
            var idxResult = getResultIndex(result, data);
            var resultItem;
            if (idxResult < 0) {
                resultItem = createNewHeaderData(data);
                resultItem[itemName] = [];
                result.push(resultItem);
            } else {
                resultItem = result[idxResult];
            }
            resultItem[itemName].push(data);
        }
        return result;
    };

    Array.prototype.ungroupBy = function (szItemName) {
        var itemName = jng.isDefined(szItemName) ? szItemName : "items";

        function updateItemWithHeader(dataItem, dataHeader) {
            var prop;
            for (prop in dataHeader) {
                if (dataHeader.hasOwnProperty(prop)) {
                    if (prop !== itemName) {
                        dataItem[prop] = dataHeader[prop];
                    }
                }
            }
        }
        //Begin - code
        var result = [];
        var idx, max;
        for (idx = 0, max = this.length; idx < max; idx += 1) {
            var data = this[idx];
            var itemIdx;
            for (itemIdx = 0; itemIdx < data[itemName].length; itemIdx += 1) {
                var dataItem = data[itemName][itemIdx];
                updateItemWithHeader(dataItem, data);
                result.add(dataItem);
            }
        }
        return result;
    };

    
    
    Array.prototype.setValueInArray = function (szProperty, funcOrValue) {
        var idx, max;
        for (idx = 0, max = this.length; idx < max; idx += 1) {
            if (jng.isFunction(funcOrValue)) {
                this[idx][szProperty] = funcOrValue(this[idx]);
            } else {
                this[idx][szProperty] = funcOrValue;
            }
        }
    };

    Array.prototype.extractSingleValue = function (szProperty) {
        var result = [];
        var i;
        for (i = 0; i < this.length; i += 1) {
            result.add(this[i][szProperty]);
        }
        return result;
    };

    Array.prototype.cloneLiteral = function () {
        var result = [];
        var i;
        for (i = 0; i < this.length; i += 1) {
            result.push(jng.cloneLiteral(this[i]));
        }
        return result;
    };

    Array.prototype.clone = function () {
        var result = [];
        var i;
        for (i = 0; i < this.length; i += 1) {
            result.push(jng.clone(this[i]));
        }
        return result;
    };

    Array.prototype.copy = function () {
        var result = [];
        var i;
        for (i = 0; i < this.length; i += 1) {
            result.add(this[i]);
        }
        return result;
    };

    jng.toLanguageKey = function (str) {
        return str.toUpperCase().replace(".", "_")
    }
    String.prototype.toLanguageKey = function() {
        return jng.toLanguageKey(this)
    }
    String.prototype.contains = function (str) {
        return this.indexOf(str) >= 0
    }

    String.prototype.containsAsWord = function (str) {
        if (str.endsWith(' ' + str)) return true
        if (str.contains(' ' + str + ' ')) return true
        return false
    }
    String.prototype.replaceScheme = function(startWordRegex, lastWordRegex, replaceText){
        var n = this;
        return n.replace(n.substring(n.search(startWordRegex),n.search(lastWordRegex) + 1), replaceText);
    }



    String.prototype.firstCharacterLower = function () {
        return this.substring(0, 1).toLowerCase() + this.substring(1);
    }
    String.prototype.firstCharacterUpper = function () {
        return this.substring(0, 1).toUpperCase() + this.substring(1);
    }

    String.prototype.toSentenceUpperCase = function () {
        return this.substring(0, 1).toUpperCase() + this.toLowerCase().substring(1);
    }

    String.prototype.toWordUpperCase = function () {
        var strList = this.toLowerCase().split(' ');
        var result = '';
        for(var i = 0; i < strList.length; i++) {
            result += strList[i].toSentenceUpperCase() + ' ';
        }
        if (result !== '') return result.substring(0, result.length - 1);
        return '';
    }

    String.prototype.toNumber = function (nanValue) {
        if (!isNaN(this)) {
            return Number(this);
        }
        return jng.coalesce(nanValue, 0);
    }

    String.prototype.padl = function (iWidth, opt_chFillWith) {
        var ch;
        ch = opt_chFillWith || '0';
        return this.length >= iWidth ? this.toString() : String.replicate(ch, iWidth - this.length) + this;
    };

    String.prototype.splitRemoveEmpty = function (separator) {
        var result = this.split(separator);
        var i;
        for (i = result.length - 1; i >= 0; i -= 1) {
            if (result[i] === "") {
                result.deleteAt(i);
            }
        }
        return result;
    };

    String.prototype.getPart = function (index, separator) {
        var result = this.split(separator);
        return result[index];
    };

    String.prototype.getToLastPart = function (separator, idx) {
        var result = '';
        var itemList = this.split(separator);
        var i;
        var lastIdx = itemList.length - 1 - jng.coalesce(idx, 0);
        for (i = 0; i <= lastIdx; i++) {
            result += itemList[i];
            if(i < lastIdx) result += '.';
        }
        return result;
    }

    String.prototype.getLastPart = function (separator, idx) {
        var result = this.split(separator);
        return result[result.length - 1 - jng.coalesce(idx, 0)];
    };

    String.prototype.splitLastPart = function (separator) {
        var result = this.split(separator);
        if (result.length == 0) return [];
        if (result.length == 1) return result;
        var leftPart = result[0];
        for(var idx = 1; idx < result.length - 1; idx++) {
            leftPart += '__' + result[idx]
        }
        return [leftPart, result[result.length - 1]];
    };

    String.prototype.concatUrl = function(data) {
        let concatUrl = this;
        if(jng.isDefined(data)) {
          if (jng.isArray(data)) {
            concatUrl += data.reduce((acc, value) => acc + '/' + value, '');
          } else {
            concatUrl += '/' + data;
          }
        }
        while(concatUrl.indexOf('//') > -1) {
            concatUrl = concatUrl.replace('//', '/');
        }
        return concatUrl;
      }
    String.prototype.replaceToSomething = function(stringStyle){
        let start = "";
        let startEl = "";

        let end = "";
        let endEl = "";
        
        let value = this;
        if(value.length>0){
            if(value[0]=="_")value = " "+value;
        }
        if(stringStyle == "italic"){
            start = / _/g;
            end = /_ /g;
            startEl = " <span style='font-style: italic;font-size: inherit;line-height:inherit'>";
            endEl = "</span> ";
        }

        if(stringStyle !== ""){
            let val = value.replace(start, startEl);
            val += " ";
            val = val.replace(end, endEl)
            value = val;
        }

        return value
    }

    String.prototype.replaceFromFindReplace = function(iFrom, countFrom,szFindWord, szReplaceWord){
        let lastWord = "";
        let value = this;

        if(countFrom == "last"){
            lastWord = value.slice(value.length - iFrom, value.length);

            value = value.slice(0, value.length - iFrom);
        }else{
            lastWord = value.slice(0, iFrom);

            value = value.slice(iFrom, value.length);
        }
        lastWord = lastWord.replace(RegExp(szFindWord, "g"), szReplaceWord);

        if(countFrom == "last"){
            return value + lastWord;
        }else{
            return lastWord + value;
        }

    }

    // String.prototype.compare = function (szText) {
    //     var thisStr = this.toString();
    //     var textStr = szText.toString();
    //     if (thisStr === textStr) {
    //         return 0;
    //     }
    //     if (thisStr < textStr) {
    //         return -1;
    //     }
    //     return 1;
    // };

    /**
     * Replicate ch as long as iWidth.
     * @param {string} ch. Should be single string. Example '0'
     * @param {int} iWidth. Example 4
     * @returns {string}. Return empty if iWidth <= 0. Example "0000"
     */
    String.replicate = function (ch, iWidth) {
        var i, result = [];

        if (iWidth <= 0) {
            return "";
        }
        for (i = 0; i < iWidth; i += 1) {
            result[i] = ch;
        }
        return result.join();
    };
    
    Array.prototype.traverse = function(func) {
        var i;
        for (i = 0; i < this.length; i += 1) {
            func(this[i], i);
        }
    };

    jng.traverseProperty = function(obj, funcProcess) {
        var prop;
        for (prop in obj) {
            if (obj.hasOwnProperty(prop)) {
                funcProcess(prop);
            }
        }
    };

    Array.prototype.extract = function (arzProperty) {
        var result = [];
        var i;
        for (i = 0; i < this.length; i += 1) {
            var resultItem = jng.extract(this[i], arzProperty);
            result.add(resultItem);
        }
        return result;
    };

    Array.prototype.distinct = function (arzProperty) {

        var result = [];
        var tmpResult = this.extract(arzProperty);

        var idx, max;
        for (idx = 0, max = tmpResult.length; idx < max; idx += 1) {
            if (!result.isExistsByObject(tmpResult[idx], arzProperty)) {
                result.add(tmpResult[idx]);
            }
        }
        return result;
    };

    Array.prototype.addDistinct = function (obj, arzPropOrFunc) {
        var idx;
        if (jng.isFunction(arzPropOrFunc)) {
            idx = this.findIndexUsingEquality(arzPropOrFunc);
        } else {
            idx = this.findIndexByObject(obj, arzPropOrFunc);
        }
        if (idx === -1) {
            this[this.length] = obj;
        }
        //this.push(obj);
    };

    Array.prototype.equals = function (arr, arzProp) {
        if (this.length !== arr.length) return false

        for(let i = 0; i < this.length; i++) {
            let idx = arr.findIndexByObject(this[i], arzProp);
            if (idx < 0) return false
        }
        return true
    };

    Array.prototype.toString = function (delimiter) {
        if (this.length == 0) return ''
        let result = ''
        for(let i = 0; i < this.length; i++) {
            result += this[i]
            if (i < this.length - 1) {
                result += ","
            }
        }
        return result
    };

    Number.prototype.toRomawiString = function(){
        let romawi = ['', "I", "II", "III", "IV", "V", "VI", "VII", "VIII", "IX", "X", "XI", "XII", "XIII"];
        return romawi[this];
    }

    Number.prototype.toWordString = function(lang){
        let word = [];
        if(lang=="in"){
            word = ['Kosong', "Satu", "Dua", "Tiga", "Empat", "Lima", "Enam", "Tujuh", "Delapan", "Sembilan", "Sepuluh", "Sebelas", "Dua Belas", "Tiga Belas"];
        }else{
            word = ['Zero', "One", "Two", "Three", "Four", "Five", "Six", "Seven", "Eight", "Nine", "Ten", "Eleven", "Twelve", "Thirteen"];
        }
        return word[this];
    }

    // Number as Date
    Number.prototype.toDateString = function(){
        let number = this
        return Date.toDateString(new Date(number), "dd MMMM yyyy");
    }

    Number.prototype.toDateMonthString = function(){
        let number = this
        return Date.toDateString(new Date(number), "dd MMMM");
    }

    Number.prototype.toDayName = function(){
        let dayInNumber = new Date(this).getDay()
        return Date.getDayName(dayInNumber);
    }

    Number.prototype.toMonthName = function(){
        let monthInNumber = new Date(this).getMonth()
        return Date.getMonthName(monthInNumber);
    }

    jng.createSearchQuery = function (queryParams) {
        var result = "";
        var i;
        for (i = 0; i < queryParams.length; i += 1) {
            if (queryParams[i].szValue === "") {
                continue;
            }
            if (result !== "") {
                result += "[;]";
            }
            result += queryParams[i].szName + "[=]" + jng.coalesce(queryParams[i].szValue, '');
        }
        return btoa(result);
    };

    /**
     * 
     * @param {Object} data 
     */
    jng.log = (data) => {
        console.log(Object.keys(data)[0], Object.values(data)[0])
    }

    Date.prototype.toTimeString = function () {
        var szHour, szMinute;
        szHour = this.getHours().toString();
        szMinute = this.getMinutes().toString();
        return szHour.padl(2) + ":" + szMinute.padl(2);
    };

    /**
     * Return date as string in format DDD / yyyy-MM-dd (result from toDateString)
     * @returns {string}
     */
    Date.prototype.toFullDateString = function () {
        var dayOfWeek, result;
        dayOfWeek = this.getDay();
        result = Date.getDayName(dayOfWeek) + " / " + this.toDateString();
        return result;
    };
    String.prototype.toEnglishDate = function(){
        let val = this;
        for(let day of ["Senin::Monday", "Selasa::Tuesday", "Rabu::Wednesday", "Kamis::Thursday", "Jumat::Friday", "Sabtu::Saturday", "Minggu::Sunday"]){
            let dayS = day.split("::");
            val = val.replace(dayS[0], dayS[1]);
        }
        return val;
    }

    /**
     * Return date as string in yyyy-MM-dd
     * @returns {string}
     */
    Date.toDateString = function (date, format) {
        if (jng.isUndefined(date)) {
            return '';
        }
        let curDate = date
        if (!jng.isDate(date)) {
            curDate = new Date(date)
        }
        if (!jng.isDate(curDate)) {
            return ''
        }
        var szDate, szYear, szMonth, szResult;
        szDate = curDate.getDate().toString();
        szYear = curDate.getFullYear().toString();
        szMonth = (curDate.getMonth() + 1).toString();
        if(jng.isUndefined(format)){
            szResult = szYear + "-" + szMonth.padl(2) + "-" + szDate.padl(2);
        }else if(format === 'dd-mm-yyyy' || format === 'dd-MM-yyyy' ){
            szResult = szDate.padl(2) + "-" + szMonth.padl(2) + "-" + szYear;
        }else if(format === 'dd/mm/yyyy' || format === 'dd/MM/yyyy' ){
            szResult = szDate.padl(2) + "/" + szMonth.padl(2) + "/" + szYear;
        }else if (format === 'dd MMMM yyyy') {
            szResult = szDate.padl(2) + " " + curDate.getMonthName() + " " + szYear;
        }else if (format === 'dd MMMM') {
            szResult = szDate.padl(2) + " " + curDate.getMonthName();
        }
        return szResult;
    };

    Date.toDateFullEnglishString = function (date) {
        if (jng.isUndefined(date)) {
            return '';
        }
        var szDate, szYear, szMonth, szResult;
        szDate = date.getDate().toString();
        szYear = date.getFullYear().toString();
        szResult = Date.getMonthNameInEnglish(date.getMonth() + 1) + ' ' + szDate.padl(2) + ", " + szYear;
        return szResult;
    };

    Date.toDateFullEnglishString2ndFormat = function (date) {
        if (jng.isUndefined(date)) {
            return '';
        }
        var szDate, szYear, szMonth, szResult;
        szDate = date.getDate().toString();
        szYear = date.getFullYear().toString();
        szResult = szDate.padl(2) + ' ' + Date.getMonthNameInEnglish(date.getMonth() + 1) + " " + szYear;
        return szResult;
    };

    Date.prototype.toDateString = function (format) {
        return Date.toDateString(this, format);
    };

    Date.prototype.toDateMonthString = function () {
        var szDate, szMonth, szResult;
        szDate = this.getDate().toString();
        szMonth = (this.getMonth() + 1).toString();
        szResult = szMonth.padl(2) + "/" + szDate.padl(2);
        return szResult;
    };

    Date.prototype.yearMonthName = function () {
        return this.getFullYear() + ' ' + this.getAbbrMonthName();
    };

    Date.prototype.yearMonth = function () {
        return this.getFullYear() + '' +  (this.getMonth() + 1).toString().padl(2);
    };
    
    /**
     * Return date as string in yyyy-MM-dd HH:mm:ss
     * @returns {string}
     */
    Date.prototype.toDateTimeString = function (bNumericVersion) {
        var monthName = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        var szDate, szYear, szResult, szHour, szMinute, szSecond;
        szDate = this.getDate().toString();
        szYear = this.getFullYear().toString();
        szHour = this.getHours().toString();
        szMinute = this.getMinutes().toString();
        szSecond = this.getSeconds().toString();

        if (jng.isUndefined(bNumericVersion)) {
            bNumericVersion = false;
        }

        if (bNumericVersion) {
            var szMonth = (this.getMonth() + 1).toString();
            szResult = szYear + "-" + szMonth.padl(2) + "-" + szDate.padl(2) + " " + szHour.padl(2) + ":" + szMinute.padl(2) + ":" + szSecond.padl(2);
        } else {
            szResult = monthName[this.getMonth()] + " " + szDate.padl(2) + ", " + szYear + " " + szHour.padl(2) + ":" + szMinute.padl(2) + ":" + szSecond.padl(2);
        }
        return szResult;
    };

    /**
     * Return week of year.
     * @returns {int}
     */
    Date.prototype.getWeek = function () {
        var oneJan;
        oneJan = new Date(this.getFullYear(), 0, 1);
        return Math.ceil((((this - oneJan) / 86400000) + oneJan.getDay() + 1) / 7);
    };

    /**
     * Add / subtract day to current date (change this object) and return it.
     * @param {int} dayNumber. Positive to add and negative to minus
     * @returns {Date}
     */
    Date.prototype.addDay = function (dayNumber) {
        this.setDate(this.getDate() + dayNumber);
        return this;
    };

    /**
     * Add / subtract month to current date (change this object) and return it.
     * @param {int} monthNumber. Positive to add and negative to minus
     * @param {int} monthNumber. Positive to add and negative to minus
     * @returns {Date}
     */
    Date.prototype.addMonth = function (monthNumber) {
        this.setMonth(this.getMonth() + Number(monthNumber));
        return this;
    };

    /**
     * Add / subtract minute to current date (change this object) and return it.
     * @param {int} minuteNumber. Positive to add and negative to minus
     * @returns {Date}
     */
    Date.prototype.addMinute = function (minuteNumber) {
        this.setMinutes(this.getMinutes() + minuteNumber);
        return this;
    };

    Date.prototype.diff = function (szDateType, otherDate) {
        var inMs = this - otherDate;
        if (szDateType === "yy") {
            return parseInt(inMs / 365 / 24 / 3600 / 1000);
        }
        if (szDateType === "mm") {
            return parseInt(inMs / 30 / 24 / 3600 / 1000);
        }
        if (szDateType === "dd") {
            return parseInt(inMs / 24 / 3600 / 1000);
        }
        if (szDateType === "hh") {
            return parseInt(inMs / 3600 / 1000);
        }
        if (szDateType === "mi") {
            return parseInt(inMs / 60 / 1000);
        }
        if (szDateType === "ss") {
            return parseInt(inMs / 1000);
        }
        return inMs;
    };

    Date.prototype.diffUntilDay = function(otherDate) {
        var inMs = this - otherDate;
        var result = {};
        result.year = Math.floor(inMs / 365 / 24 / 3600 / 1000);
        inMs = inMs - (result.year * 365 * 24 * 3600 * 1000);
        result.month = 0;
        if (inMs > 0) {
            result.month = Math.floor(inMs / 30 / 24 / 3600 / 1000);
        }
        inMs = inMs - (result.month * 30 * 24 * 3600 * 1000);
        result.day = 0;
        if (inMs > 0) {
            result.day = Math.floor(inMs / 24/ 3600 / 1000);
        }
        return result;
    }

    /**
     * Compare current date (this object) with other date. Comparison is based on date.
     * @param {Date} otherDate. Other date to compare.
     * @returns {boolean}. True if equal.
     */
    Date.prototype.isDateEqual = function (otherDate) {
        return this.compareDate(otherDate) === 0;
    };

    /**
     * Compare current datetime (this object) with other datetime. Comparison is based on milliseconds.
     * @param {Date} otherDate. Other date to compare.
     * @returns {boolean}. True if equal.
     */
    Date.prototype.isDateTimeEqual = function (otherDate) {
        return this.compareDateTime(otherDate) === 0;
    };

    /**
     * Compare if current datetime (this object) between or equal with startDate and endDate. Comparison is based on milliseconds.
     * @param {Date} startDate
     * @param {Date} endDate
     * @returns {boolean}. True if equal.
     */
    Date.prototype.isBetweenTime = function (startDate, endDate) {
        return this.compareDateTime(startDate) >= 0
            && this.compareDateTime(endDate) <= 0;
    };

    /**
     * Compare if current date (this object) between or equal with startDate and endDate. Comparison is based on date.
     * @param {Date} startDate
     * @param {Date} endDate
     * @returns {boolean}. True if equal.
     */
    Date.prototype.isBetween = function (startDate, endDate) {
        return this.compareDate(startDate) >= 0
            && this.compareDate(endDate) <= 0;
    };

    /**
     * Compare current datetime (this object) with other datetime. Comparison is based on milliseconds.
     * @param {Date} otherDate. Other datetime to compare.
     * @returns {int}. 0 --> equal, 1 --> this object > otherDate, -1 --> this object < otherDate.
     */
    Date.prototype.compareDateTime = function (otherDate) {
        if (this.getTime() === otherDate.getTime()) {
            return 0;
        }
        if (this.getTime() > otherDate.getTime()) {
            return 1;
        }
        return -1;
    };

    /**
     * Compare current date (this object) with other date. Comparison is based on date.
     * @param {Date} otherDate. Other date to compare.
     * @returns {int}. 0 --> equal, 1 --> this object > otherDate, -1 --> this object < otherDate.
     */
    Date.prototype.compareDate = function (otherDate) {
        if (this.getDate() === otherDate.getDate()
                && this.getMonth() === otherDate.getMonth()
                && this.getFullYear() === otherDate.getFullYear()) {
            return 0;
        }
        if (this.getTime() > otherDate.getTime()) {
            return 1;
        }
        return -1;
    };

    Date.toServerStr = function(date) {
        if (jng.isUndefined(date)) return ''
        if (jng.isDate(date)) {
            return date.toServerStr()
        } else if (jng.isString(date)) {
            if (date == '') return ''
            return (new Date(date)).toServerStr()
        } else {
            return (new Date(date)).toServerStr()
        }
    };
    Date.toServerLong = function(date) {
        if (jng.isDate(date)) {
            return date.getTime()
        } else if (jng.isString(date)) {
            return (new Date(date)).getTime()
        } else {
            return date
        }
    };
    Date.prototype.toServerStr = function () {
        return Date.toDateString(this);
    };

    Date.prototype.toControlValue = function () {
        return Date.toDateString(this);
    };

    Date.convertFromStr = function (str, format, delimiter) {
        try{
            var formatParts = format.split(' ')
            var dateFormatParts = formatParts[0].split(delimiter)
            var timeParts = str.split(' ');
            var dateParts = timeParts[0].split(delimiter);
            var hour = 0;
            var min = 0;
            var sec = 0;
            if (timeParts.length > 1) {
                var detTimeParts = timeParts[1].split(":");
                hour = parseInt(detTimeParts[0]);
                min = parseInt(detTimeParts[1]);
                sec = parseInt(detTimeParts[2]);
            }
            if (dateParts.length != 3) return undefined
            if (dateFormatParts.length != 3) return undefined

            var day = 0
            var month = 0
            var year = 0
            for(let i = 0; i < dateFormatParts.length; i++) {
                if (dateFormatParts[i].toLowerCase() == 'dd') {
                    day = dateParts[i]
                } else if (dateFormatParts[i].toLowerCase() == 'mm') {
                    month = dateParts[i]
                } else {
                    year = dateParts[i]
                }
            }
            return new Date(parseInt(year), parseInt(month) - 1, parseInt(day), hour, min, sec);
        } catch (e){
            return undefined;
        }
    };

    Date.fromServerStr = function (str) {
        try{
            var timeParts = str.split(' ');
            var dateParts = timeParts[0].split('-');
            var hour = 0;
            var min = 0;
            var sec = 0;
            if (timeParts.length > 1) {
                var detTimeParts = timeParts[1].split(":");
                hour = parseInt(detTimeParts[0]);
                min = parseInt(detTimeParts[1]);
                sec = parseInt(detTimeParts[2]);
            }
            return new Date(parseInt(dateParts[0]), parseInt(dateParts[1]) - 1, parseInt(dateParts[2]), hour, min, sec);
        } catch (e){
            return undefined;
        }
    };

    /**
     * Set only minute and second of current date (change this object). Year, month, date, and hour is remain not change.
     * @param {int} minute.
     * @param {int} second. Optional.
     */
    Date.prototype.resetMinute = function (minute, second) {
        this.setMinutes(minute);
        if (jng.isDefined(second)) {
            this.setSeconds(second);
        }
    };

    /**
     * Set only time of current date (change this object). Year, month, and date is remain not change.
     * @param {int} hour
     * @param {int} minute
     * @param {int} second
     */
    Date.prototype.resetTime = function (hour, minute, second) {
        this.setHours(hour);
        this.resetMinute(minute, second);
    };

    /**
     * Set time of otherDate to current date (change this object). Time is hour, minute, second.
     * @param {Date} otherDate.
     */
    Date.prototype.resetTimeByDate = function (otherDate) {
        this.resetTime(otherDate.getHours(), otherDate.getMinutes(), otherDate.getSeconds());
    };

    /**
     * Get current day name.
     * @returns {string}
     */
    Date.prototype.getDayName = function () {
        return Date.getDayName(this.getDay());
    };

    Date.prototype.getMonthName = function () {
        return Date.getMonthName(this.getMonth() + 1);
    };
    Date.prototype.getAbbrMonthName = function () {
        return Date.getAbbrMonthName(this.getMonth() + 1);
    };

    /**
     * Check whether this date is valid date or not.
     * @returns {boolean}. True if valid.
     */
    Date.prototype.valid = function () {
        return !isNaN(this.getDate());
    };

    Date.prototype.clone = function () {
        return new Date(this.getTime());
    };

    Date.getMonth = function(date) {
        if (jng.isUndefined(date)) {
            return (new Date()).getMonth() + 1;
        }
        return date.getMonth() + 1;
    };

    Date.getYear = function(date) {
        if (jng.isUndefined(date)) {
            return (new Date()).getFullYear();
        }
        return date.getFullYear();
    };

    Date.setToMilis = function(objOrArr, propNameOrArr) {
        function setMilis(obj, propName) {
            var value = jng.getValueOfObject(obj, propName);
            if (jng.isDate(value)) {
                jng.setValueOfObject(obj, propName, value.getTime());
            }
        }

        //BEGIN
        if (jng.isArray(objOrArr)) {
            for(var i = 0; i < objOrArr.length; i++) {
                Date.setToMilis(objOrArr[i], propNameOrArr);
            }
        } else {
            if (jng.isArray(propNameOrArr)) {
                for(var i = 0; i < propNameOrArr.length; i++) {
                    Date.setToMilis(objOrArr, propNameOrArr[i]);
                }
            } else {
                setMilis(objOrArr, propNameOrArr);
            }
        }
    };
    Date.convertToDate = function(value) {
        if (!jng.isDate(value)) {
            if (!jng.isString(value)) {
                return new Date(value)
            } else {
                return Date.fromServerStr(value)
            }
        }
        return value
    }
    Date.setToDate = function(objOrArr, propNameOrArr) {
        function setDate(obj, propName) {
            var value = jng.getValueOfObject(obj, propName);
            if (!jng.isDate(value)) {
                if (!jng.isString(value)) {
                    jng.setValueOfObject(obj, propName, new Date(value));
                } else {
                    jng.setValueOfObject(obj, propName, Date.fromServerStr(value));
                }

            }
        }

        //BEGIN
        if (jng.isArray(objOrArr)) {
            for(var i = 0; i < objOrArr.length; i++) {
                Date.setToDate(objOrArr[i], propNameOrArr);
            }
        } else {
            if (jng.isArray(propNameOrArr)) {
                for(var i = 0; i < propNameOrArr.length; i++) {
                    Date.setToDate(objOrArr, propNameOrArr[i]);
                }
            } else {
                setDate(objOrArr, propNameOrArr);
            }
        }
    };

    /**
     * Get month number of middle date between startDate and endDate. Middle date = startDate  - endDate / 2
     * @param {Date} startDate
     * @param {Date} endDate
     * @returns {int}
     */
    Date.getMonthOfMiddleDate = function (startDate, endDate) {
        var intervalInMs, middleDate;
        intervalInMs = (endDate.getTime() - startDate.getTime()) / 2;
        middleDate = new Date(startDate);
        middleDate.setMilliseconds(middleDate.getMilliseconds() + intervalInMs);
        return middleDate.getMonth();
    };

    /**
     * Get 3 character of month name.
     * @param {int} monthNum. Start from 0 (0 for January) till 11 (for December)
     * @returns {string}
     */
    Date.getAbbrMonthName = function (monthNum) {
        var monthName = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DES"];
        return monthName[monthNum - 1];
    };

    Date.getMonthName = function (monthNum) {
        var monthName = ["Januari", "Februari", "Maret", "April", "Mei", "Juni", "Juli", "Agustus", "September", "Oktober", "November", "Desember"];
        return monthName[monthNum - 1];
    };

    Date.getMonthNameInEnglish = function (monthNum) {
        var monthName = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        return monthName[monthNum - 1];
    };

    /**
     * Get first date of month from date parameter. Year, month are same with date parameter and date equal to 1.
     * @param {Date} date. Example 2014-01-06
     * @returns {Date}. Example 2014-01-01
     */
    Date.getFirstDateOfMonth = function (date) {
        var yearNum, monthNum;
        yearNum = date.getFullYear();
        monthNum = date.getMonth();
        return new Date(yearNum, monthNum, 1);
    };

    /**
     * Get last date of month from date parameter. Year, month are same with date parameter and date equal to last date of it's month.
     * @param {Date} date. Example 2014-01-06
     * @returns {Date}. Example 2014-01-31
     */
    Date.getLastDateOfMonth = function (date) {
        var yearNum, monthNum, result;
        yearNum = date.getFullYear();
        monthNum = date.getMonth();
        result = new Date(yearNum, monthNum + 1, 1);
        result.addDay(-1);
        return result;
    };

    /**
     * Get first date of week. First date of week is always Sunday of that week.
     * @param {int} yearNum. Year number example 2014.
     * @param {int} weekNum. Week number example 1.
     * @returns {Date}. Example will return 2014-01-01 (first date of week 1 in year 2014)
     */
    Date.getFirstDateOfWeek = function (yearNum, weekNum) {
        var result,
            numDays,
            dayOfWeek;

        result = new Date(yearNum, 0, 1);
        if (weekNum === 1) {
            return result;
        }
        numDays = (weekNum - 1) * 7;
        dayOfWeek = result.getDay();
        return result.addDay(numDays - dayOfWeek);
    };

    /**
     * Get last date of week. Last date of week is always Saturday.
     * @param {int} yearNum. Year number example 2014.
     * @param {int} weekNum. Week number example 1.
     * @returns {Date}. Example will return 2014-01-04 (first date of week 1 in year 2014)
     */
    Date.getLastDateOfWeek = function (yearNum, weekNum) {
        var result,
            dayOfWeek,
            firstDate;

        result = new Date(yearNum, 0, 1);
        if (weekNum === 1) {
            dayOfWeek = result.getDay();
            return result.addDay(6 - dayOfWeek);
        }

        firstDate = Date.getFirstDateOfWeek(yearNum, weekNum);
        return firstDate.addDay(6);
    };

    /**
     * Get week distance between date parameter and first date of month from date parameter. This distance includes current week (week difference + 1)
     * @param {Date} date. Example 2014-01-22
     * @returns {number}. Example 4 ==> 4 (week at 2014-01-22) - 1 (week at 2014-01-01) + 1
     */
    Date.getWeekDistanceFromMonth = function (date) {
        var firstDateOnMonth,
            firstWeekNum,
            currWeekNum;

        firstDateOnMonth = Date.getFirstDateOfMonth(date);
        firstWeekNum = firstDateOnMonth.getWeek();
        currWeekNum = date.getWeek();
        return currWeekNum - firstWeekNum + 1;
    };

    /**
     * Get name of day of week. 0 means Sunday
     * @param {int} dayOfWeek. Example 5
     * @returns {string}. Example "Jumat"
     */
    Date.getDayName = function (dayOfWeek) {
        var dayNames = ["Minggu", "Senin", "Selasa", "Rabu", "Kamis", "Jumat", "Sabtu"];
        return dayNames[dayOfWeek];
    };

    Date.getDayNameInEnglish = function (dayOfWeek) {
        var dayNames = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
        return dayNames[dayOfWeek];
    };

    /**
     * Get first character of day of week name. 0 means Sunday.
     * @param {int} dayOfWeek. Example 5
     * @returns {string}. Example "J"
     */
    Date.getShortDayName = function (dayOfWeek) {
        var dayNames = ["M", "S", "S", "R", "K", "J", "S"];
        return dayNames[dayOfWeek];
    };

    Date.getMinDate = function() {
        return Date.createDate(1900, 1, 1);
    };

    Date.isDefaultDate = function(date) {
        return date.compareDate(Date.getMinDate()) === 0 ||
                date.compareDate(Date.createDate(1970, 1, 1)) === 0 ||
                date.compareDate(Date.createDate(1900, 1, 1)) <= 0;
    }

    Date.createDateFromYYYYMMDD = function(str) {
        return Date.createDate(str.getPart(0, '-'), str.getPart(1, '-'), str.getPart(2, '-'));
    };

    Date.createDateFromDDMMYYYY  = function(str) {
        return Date.createDate(str.getPart(2, '-'), str.getPart(1, '-'), str.getPart(0, '-'));
    };

    Date.replaceIfMinDate = function(date, replaceDate) {
        if (date.compareDate(Date.getMinDate()) == 0) return replaceDate;
        return date;
    };

    Date.getCurrentYear = function() {
        return (new Date()).getFullYear();
    };
    /**
     * Get date for first week day of month.
     * @param {number} iYear. Example: 2014
     * @param {number} iMonth. Example: 1
     * @returns {Date}. Example 2013-12-29
     */
    Date.getFirstDateOfWeekDay = function (iYear, iMonth) {
        var dtFirst = new Date(iYear, iMonth, 1);
        var iCurrWeekDay = dtFirst.getDay();
        return dtFirst.addDay(iCurrWeekDay * -1);
    };

    /**
     * Get date for last week day of month
     * @param {number} iYear. Example: 2014
     * @param {number} iMonth. Example: 1
     * @returns {Date}. Example: 2014-02-01
     */
    Date.getLastDateOfWeekDay = function (iYear, iMonth) {
        var dtLast = new Date(iYear, iMonth + 1, 1);
        dtLast.addDay(-1);
        var iCurrWeekDay = dtLast.getDay();
        if (iCurrWeekDay === 0) {
            return dtLast;
        }
        return dtLast.addDay(7 - iCurrWeekDay);
    };
    Date.createDate = function (iYear, iMonth, iDate) {
        var result = new Date();
        result.setDate(iDate);
        result.setMonth(iMonth - 1);
        result.setYear(iYear);
        result.resetTime(0, 0, 0);
        return result;
    };

    Date.compareTimeInStr = function (szTime1, szTime2) {
        if (szTime1 < szTime2) {
            return -1;
        }
        if (szTime1 === szTime2) {
            return 0;
        }
        return 1;
    };

    Date.getStrTimeHour = function(szTime) {
        return szTime.substring(0, 2);
    }

    jng.countAge = function(birthDate,date){
        var thatYear = date.getFullYear();
        var thatMonth = date.getMonth();
        var thatDate = date.getDate();

        var ageInThisYear = thatYear - birthDate.getFullYear();

        if(thatMonth <= birthDate.getMonth()){
            if(thatDate <= birthDate.getDate()){
                return ageInThisYear
            }else{
                return ageInThisYear - 1
            }
        }else{
            return ageInThisYear - 1
        }

    }

    Date.getStrTimeMinute = function(szTime) {
        return szTime.substring(3, 5);
    }

    Date.getStrTimeSecond = function(szTime) {
        if (szTime.length > 6) {
            return szTime.substring(6, szTime.length);
        }
        return '00';
    }

    Date.formatTime = function(szTime) {
        return Date.getStrTimeHour(szTime) + ':' + Date.getStrTimeMinute(szTime);
    }

    Date.diffTimeInStrInMin = function (szStartTime, szEndTime) {
        function calculateSecond(time) {
            var hour = parseInt(Date.getStrTimeHour(time));
            var minute = parseInt(Date.getStrTimeMinute(time));
            var second = parseInt(Date.getStrTimeSecond(time));
            return hour * 3600 + minute * 60 + second;
        }

        //Begin Code
        var second1 = calculateSecond(szStartTime);
        var second2 = calculateSecond(szEndTime);
        return (second2 - second1) / 60;
    };

    Date.TIME_COMPARATION_EXCLUDE_TYPE = {
        START: 1,
        END: 2,
        BOTH: 3
    };
    Date.isTimeOverlapInStr = function (szEvaluateTime, szStartTime, szEndTime, excludeType) {
        if (jng.isDefined(excludeType)) {
            if (excludeType === Date.TIME_COMPARATION_EXCLUDE_TYPE.START) {
                return Date.compareTimeInStr(szEvaluateTime, szStartTime) > 0 && Date.compareTimeInStr(szEvaluateTime, szEndTime) <= 0;
            } else if(excludeType === Date.TIME_COMPARATION_EXCLUDE_TYPE.END) {
                return Date.compareTimeInStr(szEvaluateTime, szStartTime) >= 0 && Date.compareTimeInStr(szEvaluateTime, szEndTime) < 0;
            } else if (excludeType === Date.TIME_COMPARATION_EXCLUDE_TYPE.BOTH) {
                return Date.compareTimeInStr(szEvaluateTime, szStartTime) > 0 && Date.compareTimeInStr(szEvaluateTime, szEndTime) < 0;
            }
        }
        return Date.compareTimeInStr(szEvaluateTime, szStartTime) >= 0 && Date.compareTimeInStr(szEvaluateTime, szEndTime) <= 0;

    }
    Date.intersactionTimeInStr = function (szStartTime1, szEndTime1, szStartTime2, szEndTime2, excludeType) {
        var excludeTypeStart;
        var excludeTypeEnd;
        if (jng.isDefined(excludeType)) {
            if (excludeType === Date.TIME_COMPARATION_EXCLUDE_TYPE.START) {
                excludeTypeStart = Date.TIME_COMPARATION_EXCLUDE_TYPE.START;
                excludeTypeEnd = Date.TIME_COMPARATION_EXCLUDE_TYPE.END;
            } else if (excludeType === Date.TIME_COMPARATION_EXCLUDE_TYPE.END) {
                excludeTypeStart =  Date.TIME_COMPARATION_EXCLUDE_TYPE.END;
                excludeTypeEnd =  Date.TIME_COMPARATION_EXCLUDE_TYPE.START;
            } else {
                excludeTypeStart =  Date.TIME_COMPARATION_EXCLUDE_TYPE.BOTH;
                excludeTypeEnd =  Date.TIME_COMPARATION_EXCLUDE_TYPE.BOTH;
            }
        }
        if (Date.isTimeOverlapInStr(szStartTime1, szStartTime2, szEndTime2, excludeTypeStart))
            return true;
        if (Date.isTimeOverlapInStr(szEndTime1, szStartTime2, szEndTime2, excludeTypeEnd))
            return true;
        if (Date.isTimeOverlapInStr(szStartTime2, szStartTime1, szEndTime1, excludeTypeStart))
            return true;
        if (Date.isTimeOverlapInStr(szEndTime2, szStartTime1, szEndTime1, excludeTypeEnd))
            return true;
        return false;
    };

    
}