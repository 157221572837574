<template>
    <v-container class="login fill-height" fluid :style="{'background-image': 'url(' + background + ')'}">
        <v-row justify="center">
            <v-col cols="12" sm="8" md="5" lg="4" xl="3">
                <h2 class="primary--text mt-3 mb-5">Forgot your password?</h2>
                <v-stepper v-model="step" alt-labels non-linear>                    
                    <v-stepper-content step="1">
                        <v-card flat>
                            <v-row justify="center">
                                <v-col cols="12">
                                    <v-card-text class="text-body-1 text-justify text--secondary">
                                        Please enter your email address below and click "Get Your Token" button. You will receive an email which contains your token (a number) to reset your password.
                                    </v-card-text>
                                    <v-card-text>
                                        <v-form 
                                            ref="form"
                                            v-model="valid"
                                            lazy-validation>
                                                <v-text-field
                                                    v-model="email"
                                                    :counter="30"
                                                    :rules="emailRules"
                                                    label="Email"
                                                    required/>
                                            </v-form>
                                    </v-card-text>
                                </v-col>
                                <v-col cols="9" class="mt-5 mb-12">
                                    <div class="text-center">
                                        <v-btn rounded color="primary" dark width="100%" @click="_resetPassword">
                                            <span v-if="!isLoading">Get Token</span>
                                            <v-progress-circular v-else size="20" indeterminate color="primary" />
                                        </v-btn>
                                    </div>
                                    <div class="text-center mt-10">
                                        Or return to <router-link to="/">Login Page </router-link>
                                    </div>
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-stepper-content>
                    <v-stepper-content step="2">
                    <v-card flat>
                        <v-row justify="center">
                            <v-col cols="12">
                                <v-card-text class="text-body-1 text-justify text--secondary">
                                    Enter the token number that we sent. If you don't have it yet you can go back to previous step by clicking number 1 above.
                                </v-card-text>
                                <v-card-text>
                                    <v-text-field
                                            v-model="email"
                                            :counter="30"
                                            :rules="emailRules"
                                            label="Email"
                                            required/>
                                    <v-form 
                                        ref="form"
                                        v-model="validPassword"
                                        lazy-validation>
                                            <v-text-field
                                            v-model="token"
                                            :counter="30"
                                            :rules="passwordRules"
                                            label="Token"
                                            required/>
                                        </v-form>
                                </v-card-text>
                            </v-col>
                            <v-col cols="9" class="mt-5 mb-12">
                                <div class="text-center">
                                    
                                    <v-btn rounded color="primary" dark width="100%" @click="_login" :disabled="loadingPassword">
                                        Reset Password & Login
                                        <v-progress-circular
                                            v-if="loadingPassword"
                                            indeterminate
                                            color="green"
                                            size="20"
                                            class="inside-btn"
                                            ></v-progress-circular>                                                
                                    </v-btn>
                                </div>
                                <div class="text-center mt-10">
                                    Or return to <router-link to="/">Login Page </router-link>
                                </div>

                            </v-col>
                        </v-row>
                    </v-card>
                </v-stepper-content>

                <v-stepper-header class="force-label">
                    <v-stepper-step
                        :complete="step > 1"
                        step="1"
                        @click="step = 1">
                        Input Email
                    </v-stepper-step>
                    <v-stepper-step
                        :complete="step > 2"
                        step="2"
                        @click="step = 2"
                    >
                        Input Token
                    </v-stepper-step>
                </v-stepper-header>
                </v-stepper>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import LoginApi from "@/services/LoginApi.js";

    export default {
        name: "ForgotPassword",
        data:() => ({
            show: false,
            valid: false,
            background: '',
            step: 1,
            emailRules: [
                v => !!v || 'E-mail is required',
                v => /.+@.+/.test(v) || 'E-mail must be valid',
            ],
            email: "",
            validPassword: true,
            passwordRules: [
                v => !!v || 'Password is required',
            ],

            errorMsg: "",
            isLoading: false,
            loadingPassword: false,
            token:""
        }),
        methods:{
            async _login() {
                if (!this.validPassword) return
                this.loadingPassword = true
                await this.$store.dispatch('loginUser', {userName: this.email, password: this.token});
                this.loadingPassword = false
            },
            async _resetPassword() {
                let { email } = this
                if (!email) return naf.snackError("Email is required")
                if (!this.valid) return naf.snackError("Email is invalid")
                try {
                    this.isLoading = true
                    await LoginApi.resetPassword(email)
                    this.step += 1
                } catch ({response}) {
                    if (!response) return
                    const { message } = response.data
                    jng.snackError(message)
                } finally {
                    this.isLoading = false
                }
            },
        },
        created() {
            this.background = 'img/background2.jpg'
        }
    }
</script>
<style lang="scss" scoped>
    h2 {
        text-shadow: 1px 1px white;
    }
    .login {
        background-position: center center;
        background-size: cover;
    }
</style>