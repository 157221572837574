import router from '../router';
import { StatusType } from '@/store/enums';
import ConfigApi from '../services/ConfigApi';
import LoginApi from '../services/LoginApi';
import ParentApi from '../services/ParentApi';
import StudentApi from '../services/StudentApi';

import {
    getAppsRightMenu, getCountPendingPayment, getPaymentSummary, getScheduleCurrentClassId,
    getStudentMarkFormulation, loadCurrentYearProgram, loadStudentByParent, loadStudentMarkSummary,
    setSelectedLecturerByClass, StudentSelected, clearReportList
} from './actions';
import { SET_IS_LOADING, setStudentData, resetState, setUserData, setStudentSelected } from './mutations';

export default {
    state: {
        storedUrlScholl: '',
        storedCodeScholl: '',

        storedUserData: '',
        storedStudentData: [],
        storedItemListStudent: [],

        storedStudentSelected: {},
        selectedStudent: "",

        storedUserName: '',
        storedUserId: '',

        itemsIdentity: {},
        itemsAddress: {},
        itemsFather: {},
        itemsMother: {},
        itemsGuardian: {},
        itemsSiblingsSameSchool: [],
        isPassChangePassword: false,
        userContext: {},
    },

    mutations: {
        setEndPoint(state, payload) {
            state.storedUrlScholl = payload.url
            state.storedCodeScholl = payload.code
        },

        setIsPassChangePassword(state, payload) {
            state.isPassChangePassword = payload
        },

        setUserData(state, payload) {
            window.jng.user = payload
            state.storedUserData = payload
        },

        setUserContext(state, payload) {
            window.jng.userContext = payload
            state.userContext = payload
        },

        setStudentData(state, payload) {
            state.storedStudentData = payload

            state.storedItemListStudent = []

            Object.keys(payload).forEach(key => {
                let studentObject = {
                    id: payload[key].nim,
                    name: payload[key].name,
                }
                state.storedItemListStudent.push(studentObject);
            })

        },

        setStudentSelected(state, payload) {
            let students = state.storedStudentData;

            for (var i = students.length - 1; i >= 0; i--) {
                if (students[i].nim === payload) {
                    state.storedStudentSelected = students[i]
                    state.selectedStudent = students[i].nim
                }
            };
        },

        setStudentProfile(state, payload) {
            state.storedUserName = payload.studentName
            state.storedUserId = payload.id

            state.itemsIdentity = payload.identity
            state.itemsAddress = payload.address

            state.itemsFather = payload.father
            state.itemsMother = payload.mother
            state.itemsGuardian = payload.guardian

            state.itemsSiblingsSameSchool = []

            Object.keys(payload.siblingsSameSchool).forEach(key => {
                let siblingSchoolObject = {
                    name: payload.siblingsSameSchool[key].siblingName,
                    grade: payload.siblingsSameSchool[key].className,
                    status: payload.siblingsSameSchool[key].status,
                }
                state.itemsSiblingsSameSchool.push(siblingSchoolObject);
            })

        },

        resetState(state) {
            window.jng.user = undefined
            window.jng.userContext = undefined

            state.storedUserData = '',
            state.storedStudentData = [],
            state.storedItemListStudent = [],

            state.storedStudentSelected = {},
            state.selectedStudent = '',

            state.storedUserName = '',
            state.storedUserId = '',

            state.appsRightConfig = [],
            state.appsRightList = []
        },

        resetCodeSite(state) {
            state.storedUrlScholl = '',
            state.storedCodeScholl = ''
        }
    },

    actions: {

        StudentSelected({ commit, dispatch, state, getters }, studentId) {
            commit(setStudentSelected, studentId)
            let { schoolId, classId } = state.storedStudentSelected

            dispatch(getAppsRightMenu, { schoolId, studentId })
            dispatch(loadCurrentYearProgram, { schoolId, studentId })
            dispatch(setSelectedLecturerByClass, { schoolId, studentId })
            dispatch(getStudentMarkFormulation, { schoolId, studentId })
            
            let appsRight = getters.appsRightList

            if (appsRight.length > 0) {
                dispatch(loadStudentMarkSummary)
            }
        },

        loadStudentProfile({ commit, dispatch }, param) {
            commit(SET_IS_LOADING, true)
            StudentApi.getStudentProfile(param.studentId)
                .then(response => response.data)
                .then(storedStudentProfile => {
                    dispatch('mapStudentProfileValues', storedStudentProfile)
                })
                .catch(err => {
                    console.log(err);
                })
                .finally(() => {
                    commit(SET_IS_LOADING, false)
                })
        },

        loginUser({ commit }, param) {
            commit(SET_IS_LOADING, true)
            return LoginApi.login(param)
                .then( ({ data: response})  => {
                    localStorage.setItem('token', response.token)
                    commit(setUserData, response)
                    return StatusType.SUCCESS;
                })
                .catch(err => {
                    console.log(err);
                    if (err.response) console.log("err", err.response);
                    const errorMessage = err.response.data.message
                    const lockedMsg = "User is locked"
                    if (errorMessage.contains(lockedMsg)) router.push('/forgot-password')
                    jng.snackError(errorMessage)
                    return StatusType.FAILURE;
                })
                .finally(() => {
                    commit(SET_IS_LOADING, false)
                })
        },

        loadStudentByParent({ commit, dispatch }) {
            commit(SET_IS_LOADING, true)
            return ParentApi.getStudentByParent()
                .then(response => response.data)
                .then(storedUserData => {
                    if (storedUserData.length > 0) {
                        commit(setStudentData, storedUserData)
                        dispatch(StudentSelected, storedUserData[0].nim)
                        return StatusType.SUCCESS;
                        // router.currentRoute.path != '/home' && router.push('/home');
                    } else {
                        jng.snackError("Login Failed")
                        dispatch('logout')
                    }
                })
                .catch(err => {
                    console.log(err);
                    dispatch('logout')
                })
                .finally(() => {
                    commit(SET_IS_LOADING, false)
                })
        },

        async mapStudentProfileValues({ commit }, studentProfile) {
            jng.log({ studentProfile })
            commit(SET_IS_LOADING, true)
            const { data: genders } = await ConfigApi.selectGender()
            const { data: religions } = await ConfigApi.selectReligion()
            let studentGender = genders.findFirst(studentProfile.identity.genderId, "id")
            let studentReligion = religions.findFirst(studentProfile.identity.religionId, "id")
            studentProfile.identity.genderName = studentGender.value
            studentProfile.identity.religionName = studentReligion.value
            commit('setStudentProfile', studentProfile)
            commit(SET_IS_LOADING, false)
        },

        logout({ commit }) {
            LoginApi.logout()
                .then(response => response.data)
                .catch(err => {
                    localStorage.removeItem('token')
                    commit(resetState)
                })
                .finally(() => {
                    localStorage.removeItem('token')
                    commit(resetState)
                })
        },

        setupSch({ commit }) {
            commit('resetCodeSite')
        },

        setSchoolURL({ commit }, schoolCode) {
            commit(SET_IS_LOADING, true)
            return LoginApi.getSchoolURL(schoolCode)
                .then(response => {
                    commit('setEndPoint', response.data)
                    return StatusType.SUCCESS;
                })
                .catch(err => {
                    console.log(err);
                    return StatusType.FAILURE;
                })
                .finally(() => {
                    commit(SET_IS_LOADING, false)
                })
        },

    },

    getters: {
        getUrlScholl: state => state.storedUrlScholl,
        getCodeScholl: state => state.storedCodeScholl,

        getUserData: state => state.storedUserData,

        getStudentData: state => state.storedStudentData,

        itemListStudent: state => state.storedItemListStudent,

        getStudentName: state => state.storedUserName,

        getStudentSelected: state => state.storedStudentSelected,
        getSelectedStudentId: state => state.selectedStudent,

        itemsIdentity: state => state.itemsIdentity,
        itemsAddress: state => state.itemsAddress,
        itemsFather: state => state.itemsFather,
        itemsMother: state => state.itemsMother,
        itemsGuardian: state => state.itemsGuardian,
        itemsSiblingsSameSchool: state => state.itemsSiblingsSameSchool

    },
}
