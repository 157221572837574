<template>
  <v-card class="overflow-hidden height-100">
    <app-bar :label="label.label" :valueBack="label.backPage" />

    <v-sheet
      id="scrolling-techniques-7"
      class="overflow-y-auto"
    >
      <v-container>
          <div class="home">
                <v-row class="edc-classroom-blue" v-for="(absentItem, j) in absentList" :key="j">
                    <v-col :sm="4">{{absentItem.absentDate.toDateMonthString()}}</v-col>
                    <v-col :sm="3">{{absentItem.absentDate.toDayName()}}</v-col>
                    <v-col :sm="5">{{_getAbsentStatusLabel(absentItem.absentStatus)}}</v-col>
                </v-row>
          </div>
      </v-container>
    </v-sheet>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import AppBar from '@/components/base/AppBar.vue'
import { getAttendanceStatusLabel } from '../../helpers/attendanceStatusHelper';

export default {
    name: 'AttendanceMonthlySummary',

    components: { AppBar },

    data () {
      return {
        label: {
          label: "Kehadiran",
          backPage: "Home"
        },
        absentList: []
      }
    },

    watch:{
      itemsMonthly: {
        handler: function (newValue, oldValue) {
          this._absentList()
        },
        deep: true
      },
    },

    computed:{
      ...mapGetters({ itemsMonthly: "getMonthlyAttendance" }),
    },

    created() {
      var date = new Date();
      this.$store.dispatch('setMonthlyAttendance', new Date(date.getFullYear(), date.getMonth(), 1).getTime())
    },

    mounted(){
      naf.lang.onChangeLanguage(() => {
        this._initLabel()
      })
      this._initLabel()
    },

    methods:{
      _absentList(){
        this.absentList = []
        for (var i = 0; i < this.itemsMonthly.length; i++) {
          let childItem = this.itemsMonthly[i].children;

          this.absentList.push({
            absentDate: this.itemsMonthly[i].name,
            absentStatus: childItem[0].items[0].absentStatus,
          })
          
        }
      },

      _getAbsentStatusLabel(status){
        return getAttendanceStatusLabel(status)
      },

      _initLabel(){
      }
    }
}

</script>