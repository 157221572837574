<template>
<div>
    <v-list>
      <v-list-item-group>
        <template v-for="(item, index) in items">
          <v-list-item 
          :key="item.id"
          @click="messageClick(item.id)">
            <template>
              <v-list-item-content>
                <v-list-item-title  v-for="(recipient, i) in item.nameRecipients" :key="i">{{ recipient }}</v-list-item-title>
                <v-list-item-subtitle v-text="item.subject"></v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-action>
                <v-list-item-action-text>
                    {{_millisToStringDate(item.dateMessage)}}
                </v-list-item-action-text>
              </v-list-item-action>
            </template>
          </v-list-item>

          <v-divider
            v-if="index < items.length - 1"
            :key="index"
          ></v-divider>
        </template>
      </v-list-item-group>
    </v-list>
</div>
</template>

<script>

import { mapGetters } from "vuex";

export default {
    name: "Draft",

    data: () => ({
        messageType: 'MESSAGE',
    }),

    computed:{
    ...mapGetters({ items: "itemListDraft", selectStudent: "getStudentSelected", switchStudent: "isAllStudent" }),
    },

    watch:{
    switchStudent(newValue) {
      this.loadData(newValue)
    }},

    created() { 
        this.loadData(this.switchStudent)
    },

    methods: {
        _millisToStringDate(millisDate){
            return Date.toDateString(new Date(millisDate), "dd-mm-yyyy")
        },

        loadData (allStudent){
            if (allStudent){
                 this.$store.dispatch('loadListDraftData', 
                {'messageType':this.messageType})
            } else {
                 this.$store.dispatch('loadStudentListDraftData', 
                {'messageType':this.messageType,
                'studentId':btoa(this.selectStudent.nim),
                'schoolId':btoa(this.selectStudent.schoolId)
                })
            }
        },

        messageClick(id) {
           this.$router.push({name: 'NewMessage', params: { id: id, status: 'DRAFT' }});
        }
    }
}
</script>