import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import About from '../views/About.vue'
import Report from '../views/Report.vue'
import Schedule from '../views/Schedule.vue'
import Announcement from '../views/Announcement.vue'
import Message from '../views/Message.vue'

import Login from '@/views/login/index.vue'
import ForgotPassword from '@/views/login/ForgotPassword.vue'
import ChangePassword from '@/views/login/ChangePassword.vue'

import Payment from '@/views/financial/payment/index.vue'
import PaymentDetail from '@/views/financial/payment/PaymentDetail.vue'
import InvoiceDetail from '@/views/financial/InvoiceDetail.vue'
import PendingPayment from '@/views/financial/payment/PendingPayment.vue'
import PayInvoice from '@/views/financial/payment/PayInvoice.vue'
import StudentData from '@/views/student/StudentData.vue'

import ReadInbox from '@/views/message/ReadInbox.vue'
import NewMessage from '@/views/message/NewMessage.vue'
import AttachmentView from '@/views/message/AttachmentView.vue'

import AssessmentDetail from '../views/assessment/AssessmentDetail.vue'
import AssessmentAttachment from '../views/assessment/AssessmentAttachment.vue'
import AttendanceMonthlySummary from '../views/absent/AttendanceMonthlySummary.vue'

import Attendance from '../views/Attendance.vue'
import AttendanceDetail from '../views/absent/AttendanceDetail.vue'

import TopToolbar from '../components/navigation/TopToolbar.vue';
import BottomNav from '../components/navigation/BottomNav.vue';

import LoginApi from '../services/LoginApi'
import store from "../store"
import { setUserContext, setUserData, SET_IS_LOADING } from '../store/mutations'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    components: {
      default: Login
    }
  },
  {
    path: '/home',
    name: 'Home',
    components: {
      default: Home,
      toptoolbar: TopToolbar,
      bottomnav: BottomNav
    },
    // props: true,
    // beforeEnter: (to, from, next) => {
    //   if (from.name == "Login") console.log(`from`, jng.user)
    //   next()
    // }
  },
  {
    path: '/report',
    name: 'Report',
    components: {
      default: Report,
      toptoolbar: TopToolbar,
      bottomnav: BottomNav
    }
  },
  {
    path: '/schedule',
    name: 'Schedule',
    components: {
      default: Schedule,
      toptoolbar: TopToolbar,
      bottomnav: BottomNav
    }
  },
  {
    path: '/announcement',
    name: 'Announcement',
    components: {
      default: Announcement,
      toptoolbar: TopToolbar,
      bottomnav: BottomNav
    }
  },
  {
    path: '/message',
    name: 'Message',
    components: {
      default: Message,
      toptoolbar: TopToolbar,
      bottomnav: BottomNav
    }
  },
  {
    path: '/about',
    name: 'About',
    components: {
      default: About,
      toptoolbar: TopToolbar,
      bottomnav: BottomNav
    }
  },
  {
    path: '/payment',
    name: 'Payment',
    components: {
      default: Payment
    }
  },
  {
    path: '/payment/pay',
    name: 'Payment.pay',
    components: {
      default: PayInvoice
    }
  },
  {
    path: '/payment/:paymentId',
    name: 'Payment.paymentDetail',
    components: {
      default: PaymentDetail
    },
    props: (route) => ({
        paymentId: route.params.paymentId,
    })
  },
  {
    path: '/payment/:invoiceId/:schoolId',
    name: 'Payment.invoiceDetail',
    components: {
      default: InvoiceDetail
    },
    props: (route) => ({
        invoiceId: route.params.invoiceId,
        schoolId: route.params.schoolId
    })
  },
  {
    path: '/check-pending',
    name: 'Payment.checkPending',
    components: {
      default: PendingPayment
    }
  },
  {
    path: '/student_data',
    name: 'Student Data',
    components: {
      default: StudentData
    }
  },
  {
    path: '/change-password',
    name: 'ChangePassword',
    components: {
      default: ChangePassword
    }
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    components: {
      default: ForgotPassword
    }
  },
  {
    path: '/read-inbox',
    name: 'ReadInbox',
    components: {
      default: ReadInbox
    }
  },
  {
    path: '/new-message',
    name: 'NewMessage',
    components: {
      default: NewMessage
    }
  },
  {
    path: '/ttachment-view',
    name: 'AttachmentView',
    components: {
      default: AttachmentView
    }
  },
  {
    path: '/assessment-detail',
    name: 'AssessmentDetail',
    components: {
      default: AssessmentDetail
    }
  },
  {
    path: '/assessment-attachment',
    name: 'AssessmentAttachment',
    components: {
      default: AssessmentAttachment
    }
  },
  {
    path: '/attendance-monthly-summary',
    name: 'AttendanceMonthlySummary',
    components: {
      default: AttendanceMonthlySummary
    }
  },
  {
    path: '/attendance',
    name: 'Attendance',
    components: {
      default: Attendance
    }
  },
  {
    path: '/attendance-detail',
    name: 'AttendanceDetail',
    components: {
      default: AttendanceDetail
    }
  }
]

const router = new VueRouter({
  routes
})

router.beforeEach(async (to, from, next) => {
  if (jng.isDefined(window.jng.user)) {
        if (jng.isUndefined(window.jng.userContext) || window.jng.userContext.id !== window.jng.user.userName) {
            const { data } = await LoginApi.getUserContext()
            window.jng.userContext = data
            store.commit(setUserContext, data)
        }
      }

  next()
})

export default router
