<template>
    <v-card class="overflow-hidden" style="height: 100%;">
        <app-bar :label="label.title" />

        <v-sheet id="scrolling-techniques-7" class="overflow-y-auto" height="100%" >
            <v-container>
                <v-row dense>
                    <v-col v-for="item in items" :key="item.fileId" cols="12">
                        <v-card>
                            <v-img :src="item.srcImg"
                            class="grey darken-4"
                            height="200px"
                            contain>
                            <v-card-title v-text="item.fileName"></v-card-title>
                            </v-img>
                        </v-card>
                    </v-col>
                </v-row>
            </v-container>
        </v-sheet>
    </v-card>
</template>

<script>
import { mapGetters } from 'vuex';
import AppBar from '@/components/base/AppBar.vue'

export default {

    components: { AppBar },

    data: () => ({
        label: {
            title: "Attachment Detail"
        },
    }),

    created() {
        this.$store.dispatch('clearstoredAssessmentMedia');
        this.$store.dispatch('getAssessmentMedia', {'fileId':this.$route.params.fileId, 'fileName':this.$route.params.fileName, 'assessmentId':this.$route.params.assessmentId})
    },

    computed:{
        ...mapGetters({ items: "getAssessmentMedia" }),
    },

}
</script>