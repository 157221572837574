<template>
  <img
    id="logo"
    alt="Logo"
    :src="logoUrl"
  >
</template>
<script>
    export default {
        props: {
          isForLogin: {
            type: Boolean,
            default: false
          }
        },
        data:() => ({
            logoUrl: ''
        }),
        created() {
          this.logoUrl = 'img/edc-color.png'
        }
    }
</script>
<style>
#logo {
    display: block;
    margin: auto;
    max-height: 75px;
    max-width: 75px;
    min-width: 50px;
}

@keyframes turn {
  100% {
    transform: rotateY(0deg);
  }
}
</style>
