<template>
<v-card @click="_toStudentPage" color="rgba(45,85,255)" dark class="mb-3">
    <v-row class="ma-0 pa-0">
    <v-col cols="1" class="d-flex align-center">
        <v-avatar>
        <v-icon >mdi-account</v-icon>
        </v-avatar>
    </v-col>
    <v-col cols="8">
        <v-list-item>
        <v-list-item-content>
            <v-list-item-title>{{ studentName }}</v-list-item-title>
            <v-list-item-subtitle>
            {{ studentNIM }}
            </v-list-item-subtitle>
            <v-list-item-subtitle>
            {{ studentGRADE }}
            </v-list-item-subtitle>
            <v-list-item-subtitle>
            {{ studentTeacher }}
            </v-list-item-subtitle>
        </v-list-item-content>
        </v-list-item>
    </v-col>
    </v-row>
    <absent-today-btn v-if="absent" />
</v-card>
</template>

<script>
import AbsentTodayBtn from '../absent/AbsentTodayBtn.vue';
export default {
    components: {AbsentTodayBtn},
    props: {
        value: {
           default: ''
        },
        absent: {
           default: false
        },
    },
    data: () => ({
        studentName: '',
        studentNIM: '',
        studentGRADE: '',
        studentTeacher: '',
    }),
    watch: {
        value() {
            this._updateData(this.value)
        }
    },
    methods: {
        _toStudentPage() {
          this.$emit('studentPage')
        },
        _updateData (payload) {
            this.studentName = payload.name;
            this.studentNIM = 'NIM: '+payload.nim;
            this.studentGRADE = 'Grade: '+payload.className_J;
            this.studentTeacher = payload.teacherName_J
        },
    },
    created() {
        this._updateData(this.value)
    }
}
</script>