import firebase from 'firebase/app';
import 'firebase/messaging'

const config = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_PROJECT_ID + '.firebaseapp.com',
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_PROJECT_ID + '.appspot.com',
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: '1:'+ process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID +':web:' + process.env.VUE_APP_FIREBASE_APP_ID 

};

try {
  firebase.initializeApp(config);

  const messaging = firebase.messaging();

Notification.requestPermission().then(() => {
  console.log('Notification permission granted.');

    // Get Token
    messaging.getToken().then((token) => {
      console.log('client token', token)
    })
  }).catch((err) => {
    console.log('Unable to get permission to notify.', err);
  });
} catch (err) {
  console.log(err)
}
