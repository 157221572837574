import ScheduleApi from '../services/ScheduleApi';
import { AppsRightType } from './enums';
import { SET_IS_LOADING } from './mutations';

export default {
    state: {
        storedSheduleData:[],
    },

    mutations: {
        setSheduleList(state, payload){
            state.storedSheduleData.push(payload)
        },
    },

    actions: {

        initSchedule({ dispatch, getters }, { startDate, endDate }) {
            let { schoolId, currGradeId } = getters.getStudentSelected;
            let appsRight = getters.appsRightList

            dispatch('getShedule', { schoolId, currGradeId, startDate, endDate })
            // if (appsRight.length > 0){
            //     for ( var i = 0; i < appsRight.length; ++i ){
            //         if( appsRight[i].featureName === AppsRightType.SCHEDULE ){
            //             dispatch('getShedule', { schoolId, currGradeId })
            //         }
            //     }
            // }
        },

        getShedule({ commit, state }, { schoolId, currGradeId, startDate, endDate }){
            commit(SET_IS_LOADING, true)

            const firstDate = new Date(startDate).getTime()/1000
            const lastDate = new Date(endDate).getTime()/1000

            ScheduleApi.getStudentScheduleList(schoolId, currGradeId)
            .then(response => {
                state.storedSheduleData = []

                let itemsData = []
                itemsData = response.data

                for ( var i = 0; i < itemsData.length; ++i ){
                    let check = new Date(itemsData[i].dateStart).getTime()/1000

                    if (check >= firstDate && check <= lastDate){
                        commit('setSheduleList', itemsData[i])
                    }
                }
                
            })
            .catch(err => {
                jng.snackError(err.response.data.message)
                console.log(err);
            })
            .finally(()=> {
                commit(SET_IS_LOADING, false)
            })
        }
    },

    getters: {
        itemListShedule: state => state.storedSheduleData,
    }
}