<template>
  <div :class="`method-card mt-2 ${!disabled&&'pointer'}`">
    <div :class="`d-flex align-center ${disabled&&'color-disabled'}`">
        <img :src="getImgUrl" alt="logo">
        <div>{{paymentMethod}}</div>
    </div>
    <v-icon color="black" :disabled="disabled">mdi-chevron-right</v-icon>
 </div>
</template>

<script>
export default {
    name: "PaymentMethodCard",
    props: {
        imgName: { default: "" },
        paymentMethod: { default: "" },
        caption: { default: "" },
        disabled: { default: false },
    },
    computed: {
        getImgUrl() {
            return require(`../../assets/${this.imgName}`)
        }
    },
}
</script>

<style lang="scss" scoped>
  $edconnect: #1776CA;
  $success: #4CAF50;
  .method-card{
    background-color: whitesmoke;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    height: 4rem;
    border: 1px solid gray;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &.pointer:active{
        border: 1px solid white;
    }
  }
  .pointer{
      cursor: pointer;
  }
  img{
    height: 3rem;
    margin-right: 0.6rem;
  }
  .color-disabled{
      color: #cccccc;
  }
</style>