<template>
    <v-card-actions @click="_click">
        <v-btn outlined block>
            {{absentStatusLabel?absentStatusLabel:"Loading status..."}}
        </v-btn>
    </v-card-actions>
</template>

<script>
import { mapGetters } from 'vuex'
import { getAttendanceStatusLabel } from '../../helpers/attendanceStatusHelper'
import AbsentApi from '../../services/AbsentApi'

export default {
    name: "AbsentTodayBtn",
    data: () => ({
        absentStatus: "",
        absentStatusLabel: "", 
    }),
    computed:{
        ...mapGetters({selectedStudent: "getStudentSelected"})
    },
    methods: {
        _getAbsentToday(){
            // console.log('selectedStudent', this.selectedStudent)
            let { nim, schoolId } = this.selectedStudent
            if (!nim || !schoolId) return
            AbsentApi.getDailyAttendance(schoolId, nim)
                .then(({ data }) => {
                    this.absentStatus = data.absentStatus
                    this.$store.commit("setAbsentUsingShift", data.absentUsingShift)
                    this.absentStatusLabel = getAttendanceStatusLabel(data.absentStatus) || "No Absent"
                })
                .catch(err => {
                    jng.snackError(err.response.data.message)
                })
        },
        _initLabel(){
            this.absentStatusLabel = getAttendanceStatusLabel(this.absentStatus)
        },
        _click(event){
            event.stopPropagation()
            console.log("click")
            this.$router.push({name: 'AttendanceMonthlySummary'});
        },
    },
    mounted(){
      naf.lang.onChangeLanguage(() => {
        this._initLabel()
      })
      this._initLabel()
    },
    created(){
        this._getAbsentToday()
    },
}
</script>

<style>

</style>