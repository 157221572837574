<template>
    <v-row dense>
      <v-col v-for="(item, i) in items" :key="i" cols="12">
        <v-card :color="item.color" dark class="mb-3">
          <div>
              <v-card-title class="headline" v-text="item.invoiceAmount"></v-card-title>
              <v-card-subtitle v-text="item.minimumDueDate"></v-card-subtitle>
              <v-card-actions>
                <div @click="toPage('Payment')" class="text-underline size-08">{{ label.detailPayment }}</div>
              </v-card-actions>
              <v-card-actions>
                <v-btn outlined block @click="toPage('Payment.pay')">
                  {{ label.pay }}
                </v-btn>
              </v-card-actions>
              <v-divider dark />
              <div class="footer-payment">
                <div>Last Payment</div>
                <div>{{item.lastPaymentAmount}}</div>
              </div>
              <div class="footer-payment">
                <div>Received Date</div>
                <div>{{item.lastPaymentDate}}</div>
              </div>
              <div v-if="countPendingPayment > 0" class="footer-payment pb-2">
                <div>Pending Payment: {{countPendingPayment}}</div>
                <div @click="toPage('Payment.checkPending')"><span class="text-underline">{{countPendingPayment ? label.checkStatus : ''}}</span></div>
              </div>
            </div>
        </v-card>
      </v-col>
    </v-row>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
    name: "PaymentSummaryCard",
    data: () => ({
        label: {
            detailPayment: 'Detail Payment',
            checkStatus: "Check Status",
            pay: "Pay"
        },
    }),
    methods: {
      toPage(name){
        this.$router.push({ name });
      },

      _loadData() {
        this.$store.dispatch('getPaymentSummary');;
      }
    },
    computed:{
    ...mapGetters({ items: "paymentSummaryData", countPendingPayment: "countPendingPayment", selectStudent: "getStudentSelected" }),
    },

    watch:{
        selectStudent: {
            handler: function (newValue, oldValue) {
                this._loadData();
            },
            deep: true
        }
    },

    created() {
      // this.$store.dispatch('getCountPendingPayment');
      this._loadData();
    }
}
</script>

<style lang="scss" scoped>
  $size-08: 0.8rem;

  .footer-payment{
    padding: 0.5rem 1rem 0.1rem 1rem;
    display: flex;
    justify-content: space-between;
    div{
      font-size: $size-08;
    }
  }
  .text-underline{ 
    text-decoration: underline;
    cursor: pointer;
  } 
  .text-underline:active{ 
    color: blue;
  } 
  .size-08{
    font-size: $size-08;
  }
</style>