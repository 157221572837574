<template>
<v-card class="pa-6">
    <app-bar :label="label.label"  />

    <v-sheet id="scrolling-techniques-7" class="overflow-y-auto" height="100%" >
        <v-container>
            <v-col>
                <v-list dense>
                    <v-list-item v-for="item in itemsNow" :key="item.assessmentId">
                        <v-list-item-content>
                            <v-list-item-title v-text="_formatDate(item.startDate) + ' | ' + item.startTime"></v-list-item-title>
                            <v-list-item-title v-text="item.subjectName"></v-list-item-title>
                            <v-list-item-subtitle v-text="item.title"></v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                            <v-list-item-subtitle>{{_countDayDate(item.submitDate, item.endDate)}}</v-list-item-subtitle>
                        </v-list-item-action>
                    </v-list-item>
                </v-list>
                <v-divider></v-divider>
                <v-list dense>
                    <v-list-group  v-for="item in itemsAllDays" :key="item.name"  no-action>
                        <template v-slot:activator>
                            <v-list-item-content>
                                <v-list-item-title v-text="item.name"></v-list-item-title>
                            </v-list-item-content>
                        </template>

                        <v-list-item v-for="child in item.children" :key="child.assessmentId">
                            <v-list-item-content>
                                <v-text-field  style="width: 100%;"
                                :value="child.className"
                                label="Class"
                                readonly></v-text-field>

                                <v-text-field  style="width: 100%;"
                                :value="child.teacherName"
                                label="Teacher"
                                readonly></v-text-field>
                                
                                <v-text-field style="width: 100%;"
                                :value="child.title"
                                label="title"
                                readonly></v-text-field>

                                <v-text-field  style="width: 100%;"
                                :value="child.note"
                                label="note"
                                readonly></v-text-field>

                                <v-text-field  style="width: 100%;"
                                :value="_countDayDate(child.submitDate, child.endDate)"
                                label="Due date"
                                readonly></v-text-field>

                                <v-btn
                                color="primary"
                                text
                                @click="_showMark(child)">
                                    Show Mark
                                </v-btn>

                                <v-btn icon>
                                    <v-icon @click.native="attchForm(child)">mdi-file-document-edit-outline </v-icon>
                                </v-btn>
                            </v-list-item-content>
                        </v-list-item>

                    </v-list-group>
                </v-list>
            </v-col>

            <assessment-mark-dialog ref="assessment_dialog" />
        </v-container>
    </v-sheet>    
</v-card>
</template>

<script>
import { mapGetters } from 'vuex';
import { changeStringFormatDate, countDateNowToDateEnd } from '@/helpers/utils';
import AppBar from '@/components/base/AppBar.vue'

export default {
    components: { AppBar },

    data: () => ({
        label: {
            label: "Assessment Detail"
        },
    }),

    computed:{
        ...mapGetters({ itemsNow: "getAssessmentNow", itemsAllDays: "getAssessmentAllDay" }),
    },

    created() {
      this.$store.dispatch('selectAssessment')
    },

    methods:{
        _countDayDate(submitDate, endDate){
            if (submitDate === ""){
                return countDateNowToDateEnd(endDate);
            } else {
                return 'Done';
            }
        },

        _showMark(params){
            this.$refs.assessment_dialog.openMark(params);
        },

        _formatDate(date) {
            return changeStringFormatDate(date, 'dd-mm-yyyy');
        },

        attchForm(param){
            if (param.isQuiz){
                this.$root.$confirm(
                    'Edconnect Info',
                    'Sorry You Cant Upload Files On Quiz Task',
                    { color: 'red', useOkCancel: false, confirmPositive: 'close', })
            } else {
                this.$router.push({name: 'AssessmentAttachment', params: { id: param.assessmentId }});
            }
            
        }
    }
}
</script>